<template>
  <div id="app">
    <!-- <router-link to="/home" replace>
      <el-button round icon="el-icon-back" size="mini"></el-button>
    </router-link> -->
    <el-button type="primary" @click="dialogVisible = true">ip_set</el-button>
    <button class="btn1" id="intercomBegin">开始对讲</button>
    <button class="btn1" id="intercomEnd">关闭对讲</button>
    <!-- 紧急广播界面的分区小方块   -->
    <el-dialog
      :visible.sync="dialogVisible"
      v-dialogDrag
      :append-to-body="true"
      style="margin-top: -12vh"
      :close-on-click-modal="false"
    >
      <!-- :before-close="handleClose" -->
      <div style="text-align: left">
        <!-- 输入需要连接设备的IP -->
        <div class="a1">
          <span class="a2">输入需要连接设备的IP</span>
          <div>
            <input
              style="font-size: 18px; letter-spacing: 2px"
              v-model="check_ip"
              type="text"
            />
          </div>
          <button
            v-loading="loading"
            @click="allcheck"
            style="margin-left: 40px; height: 30px"
          >
            查看
          </button>
        </div>
        <!-- 实线 -->
        <div class="a3"></div>
        <!-- 底层版本 -->
        <div class="a1">
          <span class="a2">底层版本</span>
          <input
            style="font-size: 20px; letter-spacing: 3px"
            :value="version"
            type="text"
          />
          <!-- <button style="margin-left: 40px; height: 30px">查看</button> -->
          <button
            @click="waifang"
            style="margin-left: 80px; height: 30px; width: 100px"
          >
            外放检测
          </button>
        </div>
        <!-- 实线 -->
        <div class="a3"></div>
        <!-- ip地址 -->
        <div>
          <!-- ip地址 -->
          <div>
            <!-- ip地址 -->
            <div class="a1 height">
              <span class="a2">I P 地址</span>
              <div style="margin-left: 8.5px">
                <ul class="ipAdress">
                  <li v-for="(item, index) in ipAdress2" :key="index">
                    <input
                      type="text"
                      @input="checkIpVal(item, index)"
                      @keyup="turnIpPOS(item, index, $event)"
                      v-model:value="item.value"
                      ref="ipInput"
                      style="margin-top: 0px"
                      @blur="setDefaultVal(item)"
                    />
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 子网掩码 -->
            <div class="a1 height">
              <span class="a2">子网掩码</span>
              <div>
                <ul class="ipAdress">
                  <li v-for="(item, index) in zw" :key="index">
                    <input
                      type="text"
                      @input="checkIpVal(item, index)"
                      @keyup="turnIpPOS(item, index, $event)"
                      v-model:value="item.value"
                      ref="ipInput"
                      style="margin-top: 0px"
                      @blur="setDefaultVal(item)"
                    />
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 默认网关 -->
            <div class="a1 height">
              <span class="a2">默认网关</span>
              <div>
                <ul class="ipAdress">
                  <li v-for="(item, index) in gateway" :key="index">
                    <input
                      type="text"
                      @input="checkIpVal(item, index)"
                      @keyup="turnIpPOS(item, index, $event)"
                      v-model:value="item.value"
                      ref="ipInput"
                      style="margin-top: 0px"
                      @blur="setDefaultVal(item)"
                    />
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- DNS服务器 -->
            <div class="a1 height">
              <span class="a2" style="margin-right: 11px">DNS服务器</span>
              <div style="margin-left: -0.5px">
                <ul class="ipAdress">
                  <li v-for="(item, index) in dns" :key="index">
                    <input
                      type="text"
                      @input="checkIpVal(item, index)"
                      @keyup="turnIpPOS(item, index, $event)"
                      v-model:value="item.value"
                      ref="ipInput"
                      style="margin-top: 0px"
                      @blur="setDefaultVal(item)"
                    />
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 服务器ip -->
            <div class="a1 height">
              <span class="a2" style="margin-left: 1px">服务器IP</span>
              <div style="margin-left: 0.5px">
                <ul class="ipAdress">
                  <li v-for="(item, index) in serverip" :key="index">
                    <input
                      type="text"
                      @input="checkIpVal(item, index)"
                      @keyup="turnIpPOS(item, index, $event)"
                      v-model:value="item.value"
                      ref="ipInput"
                      style="margin-top: 0px"
                      @blur="setDefaultVal(item)"
                    />
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- ppt指向ip -->
            <div class="a1 height">
              <span class="a2">PPT指向IP</span>
              <div style="margin-left: -12px">
                <ul class="ipAdress">
                  <li v-for="(item, index) in pptip" :key="index">
                    <input
                      type="text"
                      @input="checkIpVal(item, index)"
                      @keyup="turnIpPOS(item, index, $event)"
                      v-model:value="item.value"
                      ref="ipInput"
                      style="margin-top: 0px"
                      @blur="setDefaultVal(item)"
                    />
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="a1" style="margin-left: 110px; margin-top: 8px">
              <!-- <button @click="allcheck" style="margin-right: 28px">查看</button> -->
              <button @click="putAll" style="margin-left: 110px; height: 27px">
                写入
              </button>
            </div>
            <!-- 实线 -->
            <div class="a3" style="margin-top: 15px"></div>
          </div>
          <!-- 竖线 -->
          <div class="a4"></div>
          <!-- 自检门 -->
          <div class="a5">
            <div>
              <div>
                <span style="margin-right: 30px">自检门限值</span>
                <span>报警门限值</span>
                <div style="margin-top: -5px">
                  <input v-model="selftest" type="text" class="a6" />
                  <input
                    v-model="warn"
                    disabled
                    type="text"
                    style="width: 85px; height: 23px"
                  />
                </div>
              </div>
              <div>
                <!-- <button style="margin-left: 27px; margin-right: 19px">
                  查看
                </button> -->
                <button
                  @click="selfTest"
                  style="margin-left: 125px; height: 27px"
                >
                  写入
                </button>
              </div>
              <!-- 实线 -->
              <div
                class="a3"
                style="margin-top: 12px; width: 108%; margin-left: -5px"
              ></div>
            </div>
            <div style="margin-top: 8px">
              <span>物理地址(mac)</span>
              <div class="a7">
                <input disabled :value="this.mac.one" type="text" />
                <input disabled :value="this.mac.two" type="text" />
                <input disabled :value="this.mac.three" type="text" />
                <input disabled :value="this.mac.four" type="text" />
                <input disabled :value="this.mac.five" type="text" />
                <input disabled :value="this.mac.six" type="text" />
              </div>
              <div>
                <!-- <button style="margin-left: 27px; margin-right: 19px">
                  查看
                </button> -->
                <button
                  disabled
                  style="border: 1px solid #696969; margin-left: 125px"
                >
                  写入
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="a1">
            <div>
              <!-- 设备自检音量 -->
              <div>
                <div>
                  <span class="a2">设备自检音量</span>
                  <el-select
                    v-model="self"
                    size="mini"
                    placeholder=""
                    :popper-append-to-body="false"
                    style="line-height: 53px; width: 150px"
                  >
                    <el-option
                      v-for="(item, index) in 100"
                      :key="index"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="a1" style="margin-left: 90px">
                  <!-- <button style="margin-right: 28px; margin-top: 0px">
                    查看
                  </button> -->
                  <button
                    @click="inself"
                    style="margin-top: 0px; margin-left: 110px; height: 27px"
                  >
                    写入
                  </button>
                </div>
              </div>
              <!-- 设备本地音量 -->
              <div style="margin-top: 5px">
                <div>
                  <span class="a2">设备本地音量</span>
                  <el-select
                    v-model="local"
                    size="mini"
                    placeholder=""
                    :popper-append-to-body="false"
                    style="line-height: 53px; width: 150px"
                  >
                    <el-option
                      v-for="(item, index) in 100"
                      :key="index"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="a1" style="margin-left: 90px">
                  <!-- <button style="margin-right: 28px; margin-top: 0px">
                    查看
                  </button> -->
                  <button
                    @click="inlocal"
                    style="margin-top: 0px; margin-left: 110px; height: 27px"
                  >
                    写入
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- 语音识别功能 -->
          <div class="a9">
            <div>
              <span>语音识别功能</span>
              <el-select
                v-model="voice"
                size="mini"
                placeholder=""
                :popper-append-to-body="false"
                style="line-height: 53px; margin-left: 20px; width: 87px"
              >
                <el-option
                  v-for="(item, index) in startarr"
                  :key="index"
                  :value="item"
                >
                </el-option>
              </el-select>
              <div class="a1" style="margin-left: 18px">
                <!-- <button style="margin-right: 28px; margin-top: 0px">
                  查看
                </button> -->
                <button
                  disabled
                  style="margin-top: 0px; margin-left: 110px; height: 27px"
                >
                  写入
                </button>
              </div>
              <!-- 实线 -->
              <div
                class="a3"
                style="margin-top: 12px; width: 108%; margin-left: -5px"
              ></div>
            </div>
            <div class="bigbtn">
              <button @click="restart" v-preventReClick>设备重启</button>
            </div>
          </div>
          <!-- 实线 -->
          <div class="a3" style="margin-top: 15px"></div>
          <!-- 输入地址 -->
          <div>
            <div
              class="a2"
              style="line-height: 40px; margin-left: 20px; font-size: 16px"
            >
              输入地址
            </div>
            <div style="width: 100%; text-align: center; margin: auto">
              <input class="btmipt" type="text" />
            </div>
            <div class="a1" style="margin-left: 300px">
              <!-- <button @click="waifang" style="margin-top: 15px;margin-left: -280px;height: 27px;width: 100px;">外放检测</button> -->
              <!-- <button style="margin-right: 20px; margin-top: 15px;margin-left: 250px;">查看</button> -->
              <button disabled style="margin-top: 15px; margin-left: 160px">
                写入
              </button>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <div class="block">
    <span class="demonstration">多个日期</span>
    <el-date-picker
      type="dates"
      v-model="value4"
      placeholder="选择一个或多个日期">
    </el-date-picker>
  </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../network/request";
import IPinput from "./input.vue";
export default {
  name: "app",
  data() {
    return {
      dialogVisible: false,
      self: "",
      local: "",
      value1: "停止",
      startarr: ["停止", "开启"],
      version: "",
      selftest: "",
      warn: "",
      voice: "",
      mac: {},
      selectIp: "",
      check_ip: "",
      loading: false,
      value4:'',
      // ipAdress: [
      //   {
      //     value: "",
      //   },
      //   {
      //     value: "",
      //   },
      //   {
      //     value: "",
      //   },
      //   {
      //     value: "",
      //   },
      // ],
      zw: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
      gateway: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
      dns: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
      serverip: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
      pptip: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
      checkip: {},
      ipAdress2: [
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
        {
          value: "",
        },
      ],
    };
  },
  mounted() {
    var begin = document.getElementById("intercomBegin");
    var end = document.getElementById("intercomEnd");

    var ws = null; //实现WebSocket
    var record = null; //多媒体对象，用来处理音频

    function init(rec) {
      record = rec;
    }

    //录音对象
    var Recorder = function (stream) {
      var sampleBits = 16; //输出采样数位 8, 16
      var sampleRate = 16000; //输出采样率
      var context = new AudioContext();
      var audioInput = context.createMediaStreamSource(stream);
      var recorder = context.createScriptProcessor(4096, 1, 1);
      var audioData = {
        size: 0, //录音文件长度
        buffer: [], //录音缓存
        inputSampleRate: 48000, //输入采样率
        inputSampleBits: 16, //输入采样数位 8, 16
        outputSampleRate: sampleRate, //输出采样数位
        oututSampleBits: sampleBits, //输出采样率
        clear: function () {
          this.buffer = [];
          this.size = 0;
        },
        input: function (data) {
          this.buffer.push(new Float32Array(data));
          this.size += data.length;
        },
        compress: function () {
          //合并压缩
          //合并
          var data = new Float32Array(this.size);
          var offset = 0;
          for (var i = 0; i < this.buffer.length; i++) {
            data.set(this.buffer[i], offset);
            offset += this.buffer[i].length;
          }
          //压缩
          var compression = parseInt(
            this.inputSampleRate / this.outputSampleRate
          );
          var length = data.length / compression;
          var result = new Float32Array(length);
          var index = 0,
            j = 0;
          while (index < length) {
            result[index] = data[j];
            j += compression;
            index++;
          }
          return result;
        },
        encodePCM: function () {
          //这里不对采集到的数据进行其他格式处理，如有需要均交给服务器端处理。
          var sampleRate = Math.min(
            this.inputSampleRate,
            this.outputSampleRate
          );
          var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
          var bytes = this.compress();
          var dataLength = bytes.length * (sampleBits / 8);
          var buffer = new ArrayBuffer(dataLength);
          var data = new DataView(buffer);
          var offset = 0;
          for (var i = 0; i < bytes.length; i++, offset += 2) {
            var s = Math.max(-1, Math.min(1, bytes[i]));
            data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
          }
          return new Blob([data]);
        },
      };

      var sendData = function () {
        //对以获取的数据进行处理(分包)
        var reader = new FileReader();
        reader.onload = (e) => {
          var outbuffer = e.target.result;
          var arr = new Int8Array(outbuffer);
          if (arr.length > 0) {
            var tmparr = new Int8Array(1028);
            // console.log(tmparr,666666)
            var j = 4;
            for (var i = 0; i < arr.byteLength; i++) {
              tmparr[0] = 0;
              tmparr[1] = 0;
              tmparr[2] = 4;
              tmparr[3] = 151;
              tmparr[j++] = arr[i];
              if ((i + 1) % 1024 == 0) {
                ws.send(tmparr);
                if (arr.byteLength - i - 1 >= 1024) {
                  tmparr = new Int8Array(1024);
                } else {
                  tmparr = new Int8Array(arr.byteLength - i - 1);
                }
                j = 0;
              }
              if (i + 1 == arr.byteLength && (i + 1) % 1024 != 0) {
                ws.send(tmparr);
              }
            }
            console.log(tmparr, 666666);
          }
        };
        reader.readAsArrayBuffer(audioData.encodePCM());
        audioData.clear(); //每次发送完成则清理掉旧数据
      };

      this.start = function () {
        audioInput.connect(recorder);
        recorder.connect(context.destination);
      };

      this.stop = function () {
        recorder.disconnect();
      };

      this.getBlob = function () {
        return audioData.encodePCM();
      };

      this.clear = function () {
        audioData.clear();
      };

      recorder.onaudioprocess = function (e) {
        var inputBuffer = e.inputBuffer.getChannelData(0);
        audioData.input(inputBuffer);
        sendData();
      };
    };

    /*
     * WebSocket);
     */
    function useWebSocket() {
      ws = new WebSocket("ws://192.168.2.202:9004/broadMi?userId=admin");
      ws.binaryType = "arraybuffer"; //传输的是 ArrayBuffer 类型的数据
      ws.onopen = function () {
        console.log("握手成功");
        if (ws.readyState == 1) {
          //ws进入连接状态，则每隔500毫秒发送一包数据
          record.start();
          console.log("成功");
        }
      };

      ws.onmessage = function (msg) {
        console.info(msg);
        console.log("成功");
      };

      ws.onerror = function (err) {
        console.info(err);
      };
    }

    /*
     * 开始对讲
     */
    begin.onclick = function () {
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia;
      if (!navigator.getUserMedia) {
        alert("浏览器不支持音频输入");
      } else {
        navigator.getUserMedia(
          {
            audio: true,
          },
          function (mediaStream) {
            init(new Recorder(mediaStream));
            console.log("开始对讲");
            useWebSocket();
          },
          function (error) {
            console.log(error);
            switch (error.message || error.name) {
              case "PERMISSION_DENIED":
              case "PermissionDeniedError":
                console.info("用户拒绝提供信息。");
                break;
              case "NOT_SUPPORTED_ERROR":
              case "NotSupportedError":
                console.info("浏览器不支持硬件设备。");
                break;
              case "MANDATORY_UNSATISFIED_ERROR":
              case "MandatoryUnsatisfiedError":
                console.info("无法发现指定的硬件设备。");
                break;
              default:
                console.info(
                  "无法打开麦克风。异常信息:" + (error.code || error.name)
                );
                break;
            }
          }
        );
      }
    };

    /*
     * 关闭对讲
     */
    end.onclick = function () {
      if (ws) {
        ws.close();
        record.stop();
        console.log("关闭对讲以及WebSocket");
      }
    };
    // this.getCheckip();
    // var ip = "192.168.1.113";
    // var a = ip.split("."); //首先，由split将ip以.分割数组
    // console.log(a, 99999);
  },
  components: {
    IPinput,
  },
  props: {},
  methods: {
    // 修改全部ip
    putAll() {
      // let ipAdress=this.ipAdress[0].value+'.'+this.ipAdress[1].value+'.'+this.ipAdress[2].value+'.'+this.ipAdress[3].value
      let ipAdress2 =
        this.ipAdress2[0].value +
        "." +
        this.ipAdress2[1].value +
        "." +
        this.ipAdress2[2].value +
        "." +
        this.ipAdress2[3].value;
      let zw =
        this.zw[0].value +
        "." +
        this.zw[1].value +
        "." +
        this.zw[2].value +
        "." +
        this.zw[3].value;
      let gateway =
        this.gateway[0].value +
        "." +
        this.gateway[1].value +
        "." +
        this.gateway[2].value +
        "." +
        this.gateway[3].value;
      let dns =
        this.dns[0].value +
        "." +
        this.dns[1].value +
        "." +
        this.dns[2].value +
        "." +
        this.dns[3].value;
      let serverip =
        this.serverip[0].value +
        "." +
        this.serverip[1].value +
        "." +
        this.serverip[2].value +
        "." +
        this.serverip[3].value;
      let pptip =
        this.pptip[0].value +
        "." +
        this.pptip[1].value +
        "." +
        this.pptip[2].value +
        "." +
        this.pptip[3].value;
      let list = {
        ipaddress: ipAdress2,
        zw: zw,
        gateway: gateway,
        dns: dns,
        serverip: serverip,
        pptip: pptip,
      };
      if (this.selectIp != "") {
        this.$prompt("请再次输入服务器IP", `上次输入的服务器IP：${serverip}`, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // title:`上次输入的服务器IP：${serverip}`
        })
          .then(({ value }) => {
            if (serverip == value) {
              request({
                method: "put",
                url: "/ipset/updatezw/" + this.selectIp + ".1",
                data: JSON.stringify(list),
                headers: { "Content-Type": "application/json" },
              })
                .then((res) => {
                  // this.ipAdress=this.ipAdress2
                  console.log(res, 44335566, list);
                  if (res.status == 200) {
                    this.$message({
                      message: "修改成功",
                      type: "success",
                    });
                  } else {
                    this.$message({
                      message: "修改失败",
                      type: "error",
                    });
                  }
                })
                .catch((err) => {
                  console.log("请求失败", err);
                });
              console.log(this.ipAdress, 66966, list);
            } else {
              this.$message({
                type: "error",
                message: "与上一次服务器IP不一致！",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      }
    },
    // 自检门限
    selfTest() {
      if (this.check_ip != "") {
        request({
          method: "put",
          // url: "/ipset/Threshold/192.168.2.34/" + this.selftest + "/" + 1,
          url:
            "/ipset/Threshold/" + this.selectIp + "/" + this.selftest + "/" + 1,
        })
          .then((res) => {
            console.log(res, 9666999);
            if (res.status == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
        console.log(this.selftest, 6666);
      }
    },
    // 全部查询
    allcheck() {
      if (this.check_ip != "") {
        this.loading = true;
        this.getCheckip();
      }
      console.log(this.check_ip, 889);
      console.log(this.ipAdress);
    },
    // 自检音量
    inself() {
      if (this.check_ip != "") {
        request({
          method: "put",
          url: "/ipset/localvolume/" + this.selectIp + "/" + this.self,
        })
          .then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            console.log(res, 99);
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
        console.log(this.self, 55555);
      }
    },
    // 本地音量
    inlocal() {
      if (this.check_ip != "") {
        request({
          method: "put",
          url: "/ipset/selfvolume/" + this.selectIp + "/" + this.local,
        })
          .then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
            }
            console.log(res, 99);
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
        console.log(this.locla, 33333);
      }
    },
    getCheckip() {
      //  let ip=this.ipAdress[0].value+'.'+this.ipAdress[1].value+'.'+this.ipAdress[2].value+'.'+this.ipAdress[3].value
      this.selectIp = this.check_ip;
      request({
        method: "get",
        url: "/ipset/checkip/" + this.selectIp + ".1",
      })
        .then((res) => {
          this.checkip = res.data.data;
          this.version = this.checkip.version;
          this.self = this.checkip.self;
          this.local = this.checkip.local;
          this.selftest = this.checkip.selftest;
          this.warn = this.checkip.warn;
          this.voice = this.checkip.voice;
          let test = this.checkip.mac;
          // this.ipAdress=this.ipAdress2
          this.mac = {
            one: test.slice(0, 2),
            two: test.slice(2, 4),
            three: test.slice(4, 6),
            four: test.slice(6, 8),
            five: test.slice(8, 10),
            six: test.slice(10, 12),
          };
          console.log(this.address);
          if (this.voice == "00") {
            this.voice = "停止";
          }
          if (this.voice == "01") {
            this.voice = "开启";
          }
          let ip = this.checkip.ipaddress.split(".");
          this.ipAdress = [
            {
              value: ip[0],
            },
            {
              value: ip[1],
            },
            {
              value: ip[2],
            },
            {
              value: ip[3],
            },
          ];
          let ip1 = this.checkip.ipaddress.split(".");
          this.ipAdress2 = [
            {
              value: ip1[0],
            },
            {
              value: ip1[1],
            },
            {
              value: ip1[2],
            },
            {
              value: ip1[3],
            },
          ];
          let zw = this.checkip.zw.split(".");
          this.zw = [
            {
              value: zw[0],
            },
            {
              value: zw[1],
            },
            {
              value: zw[2],
            },
            {
              value: zw[3],
            },
          ];
          let wg = this.checkip.gateway.split(".");
          this.gateway = [
            {
              value: wg[0],
            },
            {
              value: wg[1],
            },
            {
              value: wg[2],
            },
            {
              value: wg[3],
            },
          ];
          let dns = this.checkip.dns.split(".");
          this.dns = [
            {
              value: dns[0],
            },
            {
              value: dns[1],
            },
            {
              value: dns[2],
            },
            {
              value: dns[3],
            },
          ];
          let fwqip = this.checkip.serverip.split(".");
          this.serverip = [
            {
              value: fwqip[0],
            },
            {
              value: fwqip[1],
            },
            {
              value: fwqip[2],
            },
            {
              value: fwqip[3],
            },
          ];
          let pptip = this.checkip.pptip.split(".");
          this.pptip = [
            {
              value: pptip[0],
            },
            {
              value: pptip[1],
            },
            {
              value: pptip[2],
            },
            {
              value: pptip[3],
            },
          ];
          if (res.status == 200) {
            this.$message({
              message: "查询成功",
              type: "success",
            });
          }
          this.loading = false;
          console.log("获取到的ip", this.checkip, this.selectIp);
          console.log("接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("接口失败");
        });
    },
    restart() {
      if (this.check_ip != "") {
        request({
          method: "put",
          url: "/ipset/reload/" + this.selectIp + ".1",
        })
          .then((res) => {
            console.log(res, 44336521325566);
            if (res.status == 200) {
              this.$message({
                message: "重启成功",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
    waifang() {
      if (this.check_ip != "") {
        request({
          method: "put",
          url: "ipset/waifang/" + this.selectIp + ".1",
        })
          .then((res) => {
            console.log(res, 5636);
          })
          .catch((err) => {
            console.log("请求失败", err);
          });
      }
    },
    checkIpVal(item, index) {
      let self = this;
      //确保每个值都处于0-255
      let val = item.value;
      //当输入的是空格时，值赋为空
      val = val.trim();
      val = parseInt(val, 10);
      if (isNaN(val)) {
        val = "";
      } else {
        val = val < 0 ? 0 : val;
        val = val > 255 ? 255 : val;
      }
      item.value = val;
    },
    turnIpPOS(item, index, event) {
      let self = this;
      let e = event || window.event;
      // console.log(index);
      //删除键把当前数据删除完毕后会跳转到前一个input，左一不做任何处理
      // if (e.keyCode == 8) {
      //   let val = item.value;
      //   console.log(val);
      //   if (index == 0) {
      //   } else if (val == "") {
      //     self.$refs.ipInput[index - 1].focus();
      //   }
      // }
      //右箭头、回车键、空格键、冒号均向右跳转，右一不做任何措施
      if (
        e.keyCode == 39 ||
        e.keyCode == 13 ||
        e.keyCode == 32 ||
        e.keyCode == 190 ||
        e.keyCode == 110
      ) {
        if (index == 3) {
        } else {
          self.$refs.ipInput[index + 1].focus();
        }
      }
    },
    setDefaultVal(item) {
      //当input失去焦点，而ip没有赋值时，会自动赋值为0
      let self = this;
      let val = item.value;
      if (val == "") {
        item.value = "0";
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog {
  width: 569px;
}
::v-deep .el-dialog__headerbtn {
  top: 8px;
  right: 10px;
}
::v-deep .el-dialog__body {
  color: black;
  padding: 0;
  background-color: #f0f0f0;
  height: 660px;
}
.a1 {
  margin-left: 20px;
  display: flex;
}
.height {
  height: 30px;
}
.a2 {
  font-size: 17px;
  line-height: 53px;
  margin-right: 30px;
}
.a3 {
  height: 1px;
  width: 96%;
  background-color: #a0a0a0;
  border-bottom: 1px solid #ffffff;
  border-left: 1px solid #a0a0a0;
  margin: auto;
}
.a4 {
  height: 414px;
  width: 1px;
  background-color: #a0a0a0;
  border-right: 1px solid #ffffff;
  margin: auto;
  position: absolute;
  top: 144px;
  left: 320px;
}
.a5 {
  position: absolute;
  font-size: 17px;
  top: 0px;
  top: 148px;
  left: 340px;
}
.a6 {
  width: 85px;
  margin-right: 20px;
  height: 23px;
}
.a7 input {
  height: 20px;
  width: 26px;
  margin-top: 5px;
}
.a8 {
  font-size: 17px;
}
.a9 {
  position: absolute;
  font-size: 17px;
  top: 378px;
  left: 340px;
}
input:focus {
  outline: none;
}
button {
  width: 80px;
  margin-top: 12px;
  border: 1px solid #696969;
  font-size: 16px;
  border-right: 3px solid #696969;
  border-bottom: 3px solid #696969;
  border-left: 3px solid #e3e3e3;
  /* background-color: white; */
}
button:hover {
  border: 1px solid #000000;
}
button:active {
  background-color: #ffffff;
}
input {
  height: 25px;
  width: 175px;
  margin-top: 12px;
  border: 1px solid #696969;
  border-top: 2px solid #696969;
  border-left: 2px solid #696969;
  border-right: 2px solid #e3e3e3;
  border-bottom: 2px solid #e3e3e3;
}
::v-deep .el-select-dropdown__wrap {
  max-height: 200px !important;
}
::v-deep .el-popper[x-placement^="bottom"] {
  margin: 0;
}
::v-deep .el-input__inner {
  font-size: 17px;
  margin-left: -3px;
  border: 1px solid #767676;
  border-top: 2px solid #696969;
  border-left: 2px solid #696969;
  border-right: 2px solid #e3e3e3;
  border-bottom: 2px solid #e3e3e3;
  color: #000000;
  border-radius: 0px;
}
.bigbtn {
  /* background-color: blueviolet; */
  text-align: center;
  height: 91px;
  margin: auto;
  line-height: 91px;
}
.bigbtn button {
  height: 40px;
  width: 89%;
  margin-left: 17px;
  font-weight: 600;
}
.btmipt {
  margin-top: 0px;
  height: 23px;
  width: 90%;
  margin-right: 10px;
}
.ipAdress {
  display: flex;
  align-items: center;
  /* border: 1px solid #0190FE; */
  border: 1px solid #696969;
  width: 175px;
  margin-right: 10px;
  padding: 0;
}

.ipAdress li {
  position: relative;
  height: 23px;
  margin: 0;
  list-style: none;
}

ul[class="ipAdress"] input[type="text"] {
  border: none;
  width: 100%;
  height: 23px;
  text-align: center;
  background-color: white;
  color: #000;
}

.ipAdress li div {
  position: absolute;
  bottom: 2px;
  right: 0;
  border-radius: 50%;
  /* background: #0190FE; */
  background: #000000;
  width: 3px;
  height: 3px;
}

/*只需要3个div*/
.ipAdress li:last-child div {
  display: none;
}

/*取消掉默认的input focus状态*/
.ipAdress input:focus {
  outline: none;
}
.ipAdress input {
  font-size: 17px;
  font-weight: 600;
}
input {
  font-weight: 600;
}
.btn1 {
  background-color: blueviolet;
  border-right: 0;
  height: 48px;
  margin-left: 50px;
  position: relative;
}
::v-deep .el-loading-spinner .circular {
  height: 26px;
  margin-top: 8px;
  width: 30px;
}
.el-message-box__title {
  font-size: 12px;
}
</style>