<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" @click="addLine()" round
          >添加</el-button
        >
      </span>
    </div>
    <div>
      <template>
        <el-table
          :data="tableData"
          style="width: 100%; line-height: 10px"
          height="78vh"
        >
          <el-table-column width="25"></el-table-column>
          <el-table-column label="序号" align="center" type="index" width="50">
          </el-table-column>
          <el-table-column label="名称" align="center" prop="name">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                size="mini"
                icon="el-icon-plus"
                @click="handleAdd(scope.row)"
                type="success"
                >
              </el-button>
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                type="primary"
                >
              </el-button>
              <el-button
                size="mini"
                icon="el-icon-delete"
                type="danger"
                @click="handleDelete(scope.$index, scope.row.id)"
                ></el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      tableData: [
        {
          name: 123,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    addLine() {
      console.log(123);
    },
    handleAdd(){
      console.log('我要添加')
    },
    handleEdit(){
      console.log('我要修改')
    },
    handleDelete(){
      console.log('我要删除')
    }
  },
};
</script>
<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
::v-deep .el-dialog__body {
  padding-bottom: 10px;
}
.a1 {
  line-height: 30px;
  width: 400px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
.a3 {
  text-align: center;
  width: 100%;
  /* background-color: peachpuff; */
  /* line-height: 80px; */
  margin-bottom: 20px;
}
.a4 {
  width: 300px;
}
.a5 {
  margin: 20px;
  /* background-color: rgb(164, 231, 226); */
}
</style>