// 定时任务小方块（正方形）
<template>
  <div id="app">
    <div>
      <span :class="{ a3: this.is_show_deleteButton }" >
      <el-button
          type="success"
          icon="el-icon-service"
          circle
          class="a2"
          :style="listenState == true ? 'color: aqua;' : 'color: #ffffff;'"
          @click="monitorButton(task.id)"
          style="margin-left: -80px;"
        >
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          class="a2"
          @click="delectTaskButton"
          style="margin-left: 42px;"
        >
        </el-button>
      </span>

      <div>
        <router-link to="/subtaskP" replace>
          <el-button class="a1" style="color: black;" @click="updateTask">
            <div>{{ taskIndex }}</div>
            <div>{{ task.name }}</div>
          </el-button>
        </router-link>
        <!-- <div class="c1">
          <el-button size="mini" @click="start(12)">启用</el-button>
          <el-button size="mini" @click="stop">停止</el-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
export default {
  name: "app",
  data() {
    return {
      listenState:false
    };
  },
  props: ["taskIndex", "task", "is_show_deleteButton"], //当前任务索引，当前任务，是否显示删除按钮
  components: {},
  mounted() {
     if (JSON.parse(sessionStorage.getItem("listenState4")) != null) {
      var listenState = JSON.parse(sessionStorage.getItem("listenState4"));
      if (
        listenState.id == this.task.id &&
        this.listenState == false
      ) {
        this.listenState = listenState.listenState;
      }
    }
  },
  methods: {
    monitorButton(id) {
      console.log(this.task.id, 123);
      this.listenState = !this.listenState;
      if (this.listenState == true) {
        request({
          method: "put",
          url: "/atask/start/listen/" + this.task.id,
        })
          .then((res) => {
            console.log("监听任务”接口成功", res);
            let list = {
              listenState: this.listenState,
              id: id,
            };
            let jsonlist = JSON.stringify(list);
            sessionStorage.setItem("listenState4", jsonlist);
            if (res.status == 200) {
              this.$message({
                message: "开始监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务”接口失败");
          });
        console.log(this.task.id, 123);
      }
      if (this.listenState == false) {
        request({
          method: "put",
          url: "/atask/stop/listen/" + this.task.id,
        })
          .then((res) => {
            sessionStorage.removeItem("listenState4");
            console.log("监听任务停止”接口成功", res);
            if (res.status == 200) {
              this.$message({
                message: "结束监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务停止”接口失败");
          });
        console.log(this.task.id, 123);
      }
    },
    stop(){

    },
    start(index){
console.log(this.taskIndex)
    },
    // 当单击大任务按钮时-----------------------------------
    updateTask() {
      this.$router.push({
        path: "/subtaskP",
        query: {
          task: JSON.stringify(this.task),
        },
      });
    },

    // 删除按钮
    delectTaskButton() {
      // console.log(this.task.id);
      this.$emit("currentTaskId", this.task.id);
    },
  },
};
</script>

<style  scoped>
.a1 {
  /* background-color: rgb(190, 210, 228); */
  /* background-color: rgb(131, 219, 241); */
  background: linear-gradient(to right, rgb(131, 219, 241), #fff);
  border: 1px solid rgb(131, 219, 241);
  border-radius: 18px;
  width: 160px;
  height: 160px;
  box-shadow: 4px 4px 5px #353757;
}
.a2 {
  position: absolute;
  z-index: 1;
  margin-top: 10px;
  /* margin-left: -80px; */
  /* left: 20px; */
}
.a3 {
  visibility: hidden;
}
.c1 {
  display: flex;
  margin-left: 33px;
  margin-top: -5px;
}
button{
  background-color: #83DBF1;
  border: none;
}
button:focus {
  background-color: #83DBF1;
}
button:hover {
  background-color: #83DBF1;
}
</style>
