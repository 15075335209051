// 直播子任务界面
<template>
  <div id="app">
    <urgent></urgent>
    <div>
      <el-container>
        <el-header height="40px">
          <el-row>
            <!-- 返回按钮 -->
            <el-col class="a1" :span="1">
              <router-link to="/direct" replace>
                <el-button
                  round
                  type="primary"
                  icon="el-icon-back"
                  size="mini"
                  @click="decideClearPriority"
                ></el-button>
              </router-link>
            </el-col>

            <!-- 打开音量条抽屉按钮 -->
            <el-col class="a3" :span="1">
              <div id="a3">
                <el-button
                  round
                  icon="el-icon-s-data"
                  size="mini"
                  @click="drawer = true"
                  type="primary"
                ></el-button>
              </div>
            </el-col>

            <!-- 麦克风按钮 -->
            <!-- <el-col class="a3" :span="2">
              <div id="a3">
                <el-button round icon="el-icon-mic" size="mini"></el-button>
              </div>
            </el-col> -->

            <!-- 麦克风录制进度 -->
            <!-- <el-col
              class="a3"
              style="margin-right: 170px; color: #b3c0d1"
              :span="2"
              >.
              <div id="a3"></div
            ></el-col> -->

            <!-- 任务编辑按钮 -->
            <el-col class="a3" :span="2">
              <div style="width: 45px" id="a3" @click="alert">
                <el-button
                  round
                  icon="el-icon-edit"
                  size="mini"
                  :disabled="this.state"
                  @click="editTask"
                ></el-button>
              </div>
            </el-col>

            <!-- 任务名称 -->
            <el-col class="a2" :span="10">
              <div class="taskName" style="color: white; width: 50px;">
                {{ task.name }}
              </div>
            </el-col>
          </el-row>
        </el-header>

        <el-container>
          <el-main>
            <el-container>
              <el-footer height="40px" style="color: #fff;">
                <div class="a1">权限：</div>
                <div class="a1">
                  <el-rate
                    class="a7"
                    v-model="permissionValue"
                    disabled
                    :max="3"
                  ></el-rate>
                </div>

                <div class="a1">模式：</div>

                <div class="a1">
                  <el-button
                    size="mini"
                    round
                    @click="single"
                    :class="[currentRepeatMode == 2 ? 'bGcolor' : 'none']"
                  >
                    单曲循环
                  </el-button>

                  <el-button
                    size="mini"
                    round
                    @click="list"
                    :class="[currentRepeatMode == 1 ? 'bGcolor' : 'none']"
                  >
                    列表循环
                  </el-button>

                  <el-button
                    size="mini"
                    round
                    @click="random"
                    :class="[currentRepeatMode == 4 ? 'bGcolor' : 'none']"
                  >
                    随机播放
                  </el-button>

                  <el-button
                    size="mini"
                    round
                    @click="order"
                    :class="[currentRepeatMode == 3 ? 'bGcolor' : 'none']"
                  >
                    列表播放
                  </el-button>
                  <el-button
                    class="record"
                    round
                    icon="el-icon-mic"
                    size="mini"
                    :style="
                      recordstate == true
                        ? 'background-color:#409eff;margin-left: 80px;'
                        : 'background-color:#ffffff;margin-left: 80px;'
                    "
                    @click="record_on"
                  ></el-button>
                  <el-button
                    icon="el-icon-service"
                    round
                    size="mini"
                    @click="monitorButton(task.id)"
                    :style="listenState == true ? 'background-color: aqua;' : 'background-color: #ffffff;'"
                  >
                  </el-button>
                  <el-button v-show="micstate==true" style="margin-left: 100px;" size="mini" id="intercomBegin">开始喊麦</el-button>
                  <el-button v-show="micstate==false" style="margin-left: 100px;" size="mini" id="intercomEnd">结束喊麦</el-button>
                  <el-dialog
                    title="录制"
                    :visible.sync="record"
                    width="40%"
                    :append-to-body="true"
                    center
                    :close-on-click-modal="false"
                  >
                    <div class="z1">
                      <span class="z2">文件名：</span>
                      <el-input
                        class="z3"
                        placeholder="请输入录制的文件名"
                        v-model="filename"
                        clearable
                      >
                      </el-input>
                    </div>
                    <!-- <div class="z1">
                      <span class="z2">录制时间：</span>
                      <el-input
                        class="z3"
                        placeholder="请输入录制时间"
                        v-model="recordtime"
                        clearable
                      >
                      </el-input>
                    </div> -->
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="startRecord"
                        >开始录制</el-button
                      >
                    </span>
                  </el-dialog>
                </div>
                <!-- <div class="a1">{{ currentIndex }}</div> -->
                <!-- <div class="a1">是否暂停:{{ pause }}</div> -->
                <!-- <div>{{ websocketState }}</div> -->
              </el-footer>
              <el-main>
                <!-- v-for="(song, index) in currentTaskSongs" -->
                <!-- :key="index" -->
                <!-- :class="[
                        index == currentIndex && pause ? 'cont01' : '',
                        index == currentIndex && !pause ? 'cont02' : '',
                        index == currentIndex && pause ? 'cont03' : '',
                        index == currentIndex && !pause ? 'cont04' : '',
                      ]" -->

                <div class="a8" style="overflow-x: hidden">
                  <el-row :gutter="5">
                    <el-col
                      :span="4.5"
                      class="grid-content bg-purple"
                      v-for="(song, index) in currentTimeTask.songs"
                      :key="song.id"
                      :class="[
                        index == currentIndex && websocketState == 1
                          ? 'cont01'
                          : '',
                        index == currentIndex && websocketState == 3
                          ? 'cont02'
                          : '',
                        index == currentIndex && websocketState == 1
                          ? 'cont03'
                          : '',
                        index == currentIndex && websocketState == 3
                          ? 'cont04'
                          : '',
                      ]"
                    >
                      <!-- @isPause="isPause" -->
                      <sub-task
                        :index="index"
                        :song="song"
                        :task="task"
                        :changeSelect="changeSelect"
                        :currentSongIndex="currentSongIndex"
                        :progress="progress"
                        @currentClickIndex="currentClickIndex"
                        :websocketState="websocketState"
                      >
                      </sub-task>
                      <!-- {{ index }}: {{ currentIndex }}: {{ pause }}:
                      {{ index == currentIndex }}:
                      {{ index == currentIndex && pause }} -->
                    </el-col>
                  </el-row>
                </div>
                <div></div>
              </el-main>
            </el-container>
          </el-main>
        </el-container>
      </el-container>
    </div>

    <!-- 添加任务弹框 -->
    <div>
      <!-- :top="true" -->
      <!-- title="修改直播任务" -->
      <el-dialog
        v-dialogDrag
        :visible.sync="is_show_addTask"
        :destroy-on-close="true"
        :before-close="handleClose"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <add-task-d
          :createNewTask="createNewTask"
          :allZones="allZones"
          :allSongs="allSongs"
          :tableData="tableData"
          @newTask="newTask"
          @closeDialog="isShowAddTaskDialog"
        >
        </add-task-d>
      </el-dialog>
    </div>

    <!-- 调正音量界面弹框 -->
    <el-drawer
      title="我是标题,但with-header设置了false"
      :visible.sync="drawer"
      :with-header="false"
      :append-to-body="true"
      size="13%"
    >
      <div>
        <table style="margin: auto;margin-top: 50%;">
          <!-- 音量条 -->
          <tr>
            <!-- 麦克风音量 -->
            <td>
              <div class="block">
                <el-slider
                  vertical
                  height="300px"
                  v-model="miVol"
                  class="a4"
                  :step="1"
                  @change="changeMiVol"
                >
                </el-slider>
              </div>
            </td>
            <!-- 音乐音量 -->
            <td>
              <div class="block">
                <el-slider
                  vertical
                  height="300px"
                  v-model="musicVol"
                  class="a4"
                  :step="1"
                  @change="changeMusicVol"
                >
                </el-slider>
              </div>
            </td>
            <!-- 总音量 -->
            <td>
              <div class="block">
                <el-slider
                  vertical
                  height="300px"
                  v-model="taskVol"
                  class="a4"
                  :step="1"
                  @change="changeTaskVol"
                >
                </el-slider>
              </div>
            </td>
            <!-- 耳机音量 -->
            <!-- <td>
              <div class="block">
                <el-slider
                  vertical
                  height="300px"
                  v-model="headsetVol"
                  class="a4"
                  :step="1"
                  @change="changeHeadsetVol"
                >
                </el-slider>
              </div>
            </td> -->
          </tr>

          <!-- 音量值部分 -->
          <tr class="a5">
            <td>
              <span>{{ miVol }}</span>
            </td>
            <td>
              <span>{{ musicVol }}</span>
            </td>
            <td>
              <span>{{ taskVol }}</span>
            </td>
            <!-- <td>
              <span>{{ headsetVol }}</span>
            </td> -->
          </tr>

          <!--音量按钮部分 -->
          <tr class="a5">
            <td>
              <el-button
                @click="button1"
                circle
                :class="this.miVol_switch == true ? 'button1' : 'button1-1'"
              ></el-button>
            </td>
            <td>
              <el-button
                @click="button2"
                circle
                :class="this.musicVol_switch == true ? 'button2' : 'button2-2'"
              >
              </el-button>
            </td>
            <td>
              <el-button
                @click="button3"
                circle
                :class="this.taskVol_switch == true ? 'button4' : 'button4-4'"
              ></el-button>
            </td>
            <!-- <td>
              <el-button
                @click="button4"
                circle
                :class="
                  this.headsetVol_switch == true ? 'button4' : 'button4-4'
                "
              ></el-button>
            </td> -->
          </tr>

          <!-- 日期时间部分 -->
          <tr>
            <td colspan="4" class="a6">
              <!-- <keep-alive>
                <get-time style="color: #fff"></get-time>
              </keep-alive> -->
              <span class="serverDateTime">{{ dateFormat(serverDateTime) }}</span>
            </td>
          </tr>
        </table>
      </div>
    </el-drawer>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
import GetTime from "../public/getTime";
import subTask from "../public/subTask";
import addTaskD from "../public/addTaskD";

export default {
  name: "app",
  inject: ['reload'], // 引入方法
  data() {
    return {
      drawer: false,
      // 麦克风音量
      miVol: 0,
      // 音乐音量
      musicVol: 0,
      // 任务音量
      taskVol: 0,
      // 耳机音量
      headsetVol: 0,

      // 权限
      permissionValue: 0,

      // 当前任务
      task: {},
      // 当前直播任务的歌曲
      // currentTaskSongs: [],
      // 当前直播任务的子任务
      currentTimeTask: [],

      testOBJ: {},

      // 编辑任务界面是否可视
      is_show_addTask: false,

      // 新建任务(初始化)
      createNewTask: {},
      // 所有可选的分区
      allZones: [],
      // 所有可选的歌曲
      allSongs: [],
      // 编辑过后保存的任务
      updataTaskObj: {},

      // test
      // 当前索引
      currentIndex: null,

      // 是否暂停
      pause: false,

      //是否重新选择别的子组件
      changeSelect: null,

      // 正在播放歌曲的歌曲索引
      currentSongIndex: "",
      // 正在播放歌曲的歌曲位置
      progress: "",
      tableData: [],
      // 当前行的播放模式
      currentRepeatMode: null,

      websocketState: 0,
      timer: null,
      // 麦克风音量开关
      miVol_switch: false,
      // 音乐音量开关
      musicVol_switch: true,
      // 任务音量开关
      taskVol_switch: true,
      // 耳机音量开关
      headsetVol_switch: true,
      state: true,
      statetask: false,
      record: false,
      filename: "",
      recordtime: "",
      recordstate: false,
      listenState:false,
      micstate:true,
      serverDateTime:''
    };
  },
  components: {
    GetTime,
    subTask,
    addTaskD,
  },
  props: [],

  // beforeRouteEnter(to, from, next) {
  //   console.log(this, "beforeRouteLeave"); //当前组件实例
  //   console.log(to, "组件独享守卫beforeRouteLeave第一个参数");
  //   console.log(from, "组件独享守卫beforeRouteLeave第二个参数");
  //   console.log(next, "组件独享守卫beforeRouteLeave第三个参数");

  //   next((vm) => {
  //     console.log(vm);
  //   });
  // },

  watch: {
    currentIndex(newVal, oldVal) {
      // console.log("父组件旧" + oldVal);
      // console.log("父组件新" + newVal);

      // 一旦发现用户点击了不同的组件（子任务小方块（长方形）），马上告诉子组件
      //currentIndex最开始的值为null

      if (oldVal != null) {
        this.changeSelect = oldVal;
      }
    },

    // 监听websocket返回的状态，发生改变就取值
    websocketState(newVal, oldVal) {
      // console.log("父组件旧" + oldVal);
      // console.log("父组件新" + newVal);
      this.websocketState = newVal;
      //  this.state=true
      //  if(this.state==false){
      //    this.getAllZones();
      //  }
    },
  },
  mounted() {
    this.getTask();
    this.getAllSongs();
    this.getAllZones();
    this.getStars();
    this.collect();
    if(JSON.parse(sessionStorage.getItem("recordstate"))!=null){
    var recordstate = JSON.parse(sessionStorage.getItem("recordstate"));
    if (recordstate.id == this.task.timetasks[0].id) {
      this.recordstate = recordstate.recordstate;
    }}
    if (JSON.parse(sessionStorage.getItem("listenState")) != null) {
      var listenState = JSON.parse(sessionStorage.getItem("listenState"));
      if (
        listenState.id == this.task.id &&
        this.listenState == false
      ) {
        this.listenState = listenState.listenState;
      }
    }
    console.log(
      this.task,
      this.task.timetasks[0].id,
      886666888,
      recordstate,
      this.musicVol,
      this.serverDateTime
    );
    var begin = document.getElementById('intercomBegin');
        var end = document.getElementById('intercomEnd');
		
        var ws = null; //实现WebSocket 
        var record = null; //多媒体对象，用来处理音频
 
        function init(rec) {
            record = rec;
        }
		
        //录音对象
        var Recorder = function(stream) {
            var sampleBits = 16; //输出采样数位 8, 16
            var sampleRate = 16000; //输出采样率
            var context = new AudioContext();
            var audioInput = context.createMediaStreamSource(stream);
            var recorder = context.createScriptProcessor(4096, 1, 1);
            var audioData = {
                size: 0, //录音文件长度
                buffer: [], //录音缓存
                inputSampleRate: 48000, //输入采样率
                inputSampleBits: 16, //输入采样数位 8, 16
                outputSampleRate: sampleRate, //输出采样数位
                oututSampleBits: sampleBits, //输出采样率
                clear: function() {
                    this.buffer = [];
                    this.size = 0;
                },
                input: function(data) {
                    this.buffer.push(new Float32Array(data));
                    this.size += data.length;		
                },
                compress: function() { //合并压缩
                    //合并
                    var data = new Float32Array(this.size);
                    var offset = 0;
                    for (var i = 0; i < this.buffer.length; i++) {
                        data.set(this.buffer[i], offset);
                        offset += this.buffer[i].length;
                    }
                    //压缩
                    var compression = parseInt(this.inputSampleRate / this.outputSampleRate);
                    var length = data.length / compression;
                    var result = new Float32Array(length);
                    var index = 0,
                    j = 0;
                    while (index < length) {
                        result[index] = data[j];
                        j += compression;
                        index++;
                    }
                    return result;
                },
                encodePCM: function() { //这里不对采集到的数据进行其他格式处理，如有需要均交给服务器端处理。
                    var sampleRate = Math.min(this.inputSampleRate, this.outputSampleRate);
                    var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
                    var bytes = this.compress();
                    var dataLength = bytes.length * (sampleBits / 8);
                    var buffer = new ArrayBuffer(dataLength);
                    var data = new DataView(buffer);
                    var offset = 0;
                    for (var i = 0; i < bytes.length; i++, offset += 2) {
                    var s = Math.max(-1, Math.min(1, bytes[i]));
                        data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
                    }
                    return new Blob([data]);
                }
            };
 
            var sendData = function() { //对以获取的数据进行处理(分包)
                var reader = new FileReader();
                reader.onload = e => {
                    var outbuffer = e.target.result;
                    var arr = new Int8Array(outbuffer);
                    function IntToBytesBigEndian(number, length){
                    var bytes = [];
                    var i = length;
                      do {
                       bytes[--i] = number & (255);
                       number = number>>8;
                      } while (i)
                        return bytes;
                    }
                    
                      i=id
                    var  bs = IntToBytesBigEndian(i, 4)
                      console.log(bs[0],bs[1],bs[2],bs[3])
                    if (arr.length > 0) {
                        var tmparr = new Int8Array(1028);
                        // console.log(tmparr,666666)
                        var j = 4;
                        for (var i = 0; i < arr.byteLength; i++) {
                           tmparr[0]=bs[0]
                           tmparr[1]=bs[1]
                           tmparr[2]=bs[2]
                           tmparr[3]=bs[3]
                            tmparr[j++] = arr[i];
                            if (((i + 1) % 1024) == 0) {
                                ws.send(tmparr);
                                if (arr.byteLength - i - 1 >= 1024) {
                                    tmparr = new Int8Array(1024);
                                } else {
                                    tmparr = new Int8Array(arr.byteLength - i - 1);
                                }
                                j = 0;
                            }
                            if ((i + 1 == arr.byteLength) && ((i + 1) % 1024) != 0) {
                                ws.send(tmparr);
                            }
                        }
                        console.log(tmparr,666666)
                    }
                };
                reader.readAsArrayBuffer(audioData.encodePCM());
                audioData.clear();//每次发送完成则清理掉旧数据
            };
			
            this.start = function() {
                audioInput.connect(recorder);
                recorder.connect(context.destination);
            }
 
            this.stop = function() {
                recorder.disconnect();
            }
 
            this.getBlob = function() {
                return audioData.encodePCM();
            }
 
            this.clear = function() {
                audioData.clear();
            }
			
            recorder.onaudioprocess = function(e) {
                var inputBuffer = e.inputBuffer.getChannelData(0);
                audioData.input(inputBuffer);
                sendData();
            }
        }
        
		
        /*
        * WebSocket);
        */
        function useWebSocket() {
            ws = new WebSocket("wss://hcjk.sgbjxt.com/broadMi?userId=admin");
            ws.binaryType = 'arraybuffer'; //传输的是 ArrayBuffer 类型的数据
            ws.onopen = function() {
                console.log('握手成功');
                if (ws.readyState == 1) { //ws进入连接状态，则每隔500毫秒发送一包数据
                  if(that.$route.path=="/subtaskD"){
                      record.start();
                  }
                  // if(that.micstate!=true){
                  //   record.start();
                  // }
                    console.log("成功",that.$route.path,that.micstate)
                }
            };
			
            ws.onmessage = function(msg) {
                console.info(msg)
                console.log("成功")
            }
			
            ws.onerror = function(err) {
                console.info(err)
            }
        }
		
        /*
        * 开始对讲
        */
       var that=this
        var id=this.task.timetasks[0].id
        var collectType=this.task.timetasks[0].collectType
        begin.onclick = function() {
          if(JSON.parse(sessionStorage.getItem("Task"))!=null){
            var task=JSON.parse(sessionStorage.getItem("Task"))
          }else{
            var task=JSON.parse(sessionStorage.getItem("currentDirectTask"))
          }
          if(task.timetasks[0].collectType==-2){
            that.micstate=false
            that.state=true
          }
          if(task.timetasks[0].collectType==-2){
            console.log(id)
          request({
          method: "put",
          url: "/broad/start/volume/1",
          data: [id],
        })
          .then((res) => {
            that.$message({
            message: "开始喊麦",
            type: "success",
          });
            console.log(res);
            console.log("麦克风开始接口成功",id);
          })
          .catch((err) => {
            console.log(err);
            console.log("失败");
          });
            navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia;
            if (!navigator.getUserMedia) {
                // alert('浏览器不支持音频输入');
            } else {
                navigator.getUserMedia({
                audio: true
            },
            function(mediaStream) {
                init(new Recorder(mediaStream));
                console.log('开始对讲');
                useWebSocket();
            },
            function(error) {
                console.log(error);
                switch (error.message || error.name) {
                    case 'PERMISSION_DENIED':  
                    case 'PermissionDeniedError':  
                        console.info('用户拒绝提供信息。');  
                        break;  
                    case 'NOT_SUPPORTED_ERROR':  
                    case 'NotSupportedError':  
                        console.info('浏览器不支持硬件设备。');  
                        break;  
                    case 'MANDATORY_UNSATISFIED_ERROR':  
                    case 'MandatoryUnsatisfiedError':  
                        console.info('无法发现指定的硬件设备。');  
                        break;  
                        default:  
                        console.info('无法打开麦克风。异常信息:' + (error.code || error.name));  
                        break;  
                        }  
                    }
                )
            }
          }else{
            that.$message({
            message: "请配置分控端声卡！",
            type: "error",
          });
          }
          
        }
 
        /*
        * 关闭对讲
        */
        end.onclick = function() {
          if(JSON.parse(sessionStorage.getItem("Task"))!=null){
            var task=JSON.parse(sessionStorage.getItem("Task"))
          }else{
            var task=JSON.parse(sessionStorage.getItem("currentDirectTask"))
          }
          if(task.timetasks[0].collectType==-2){
            that.micstate=true
          }
          if(task.timetasks[0].collectType==-2){
            request({
          method: "put",
          url: "/broad/stop/volume/1",
          data: [id],
        })
          .then((res) => {
            ws.close();
            record.stop();
            that.$message({
            message: "结束喊麦",
            type: "success",
          });
            console.log(res);
            console.log("麦克风关闭接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("失败");
          });
            if (ws) {
                ws.close();
                record.stop();
                console.log('关闭对讲以及WebSocket');
            }
          }else{
            that.$message({
            message: "请配置分控端声卡！",
            type: "error",
          });
          }
          
        }
  },
  created() {
    console.log("创建");
    this.initWebSocket();
  },

  // beforeRouteLeave(to, from, next) {
  //   console.log("路由离开之前");

  //   if (to.path == "/direct") {
  //     //缓存
  //     from.meta.keepAlive = true;
  //     from.meta.index = this.currentIndex;
  //     console.log(from);
  //   } else {
  //     //不缓存
  //     from.meta.keepAlive = false;
  //     alert("2");
  //   }
  //   next();
  // },
  // beforeRouteEnter(to, from, next) {
  //   console.log("路由进入之前");
  //   next((vm) => {
  //     // 通过 `vm` 访问组件实例
  //     console.log(vm);
  //     vm.currentIndex = to.meta.index;
  //   });
  // },

  methods: {
    dateFormat: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
    },
    close() {
      request({
        method: "put",
        url: "/broad/stop/volume/1",
        data: [this.task.timetasks[0].id],
      })
        .then((res) => {
          console.log(res);
          console.log("页面关闭时-------麦克风关闭接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("失败");
        });
    },
    button1() {
      this.miVol_switch = !this.miVol_switch;
      if (this.miVol_switch == true &&this.task.timetasks[0].collectID!=0) {
        request({
          method: "put",
          url: "/broad/start/volume/1",
          data: [this.task.timetasks[0].id],
        })
          .then((res) => {
            // this.musicVol_switch = false
            console.log(res);
            console.log("麦克风开始接口成功", this.musicVol_switch,this.task.timetasks[0].collectID);
          })
          .catch((err) => {
            console.log(err);
            console.log("失败");
          });
      }
      if (this.miVol_switch == false &&this.task.timetasks[0].collectID!=0) {
        request({
          method: "put",
          url: "/broad/stop/volume/1",
          data: [this.task.timetasks[0].id],
        })
          .then((res) => {
            // this.musicVol_switch = true;
            console.log(res);
            console.log("麦克风关闭接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("失败");
          });
      }
      sessionStorage.setItem("miVol_switch", this.miVol_switch);
      console.log(123, "麦克风", this.miVol_switch,this.task.timetasks[0].collectID);
    },
    button2() {
      if (this.state == true) {
        this.musicVol_switch = !this.musicVol_switch;
        if (this.musicVol_switch == true) {
          request({
            method: "put",
            url: "/broad/start/volume/2",
            data: [this.task.timetasks[0].id],
          })
            .then((res) => {
              // this.musicVol_switch = false
              console.log(res);
              console.log("音乐开始接口成功", this.musicVol_switch);
            })
            .catch((err) => {
              console.log(err);
              console.log("失败");
            });
        }
        if (this.musicVol_switch == false) {
          request({
            method: "put",
            url: "/broad/stop/volume/2",
            data: [this.task.timetasks[0].id],
          })
            .then((res) => {
              // this.musicVol_switch = true;
              console.log(res);
              console.log("音乐关闭接口成功");
            })
            .catch((err) => {
              console.log(err);
              console.log("失败");
            });
        }
        console.log(99886, this.musicVol_switch);
        sessionStorage.setItem("musicVol_switch", this.musicVol_switch);
      }
      console.log(123, "音乐", this.musicVol_switch);
    },
    button3() {
      if (this.state == true) {
        this.taskVol_switch = !this.taskVol_switch;
        if (this.taskVol_switch == true) {
          request({
            method: "put",
            url: "/broad/start/volume/3",
            data: [this.task.timetasks[0].id],
          })
            .then((res) => {
              // this.musicVol_switch = false
              console.log(res);
              console.log("任务开始接口成功", this.musicVol_switch);
            })
            .catch((err) => {
              console.log(err);
              console.log("失败");
            });
        }
        if (this.taskVol_switch == false) {
          request({
            method: "put",
            url: "/broad/stop/volume/3",
            data: [this.task.timetasks[0].id],
          })
            .then((res) => {
              // this.musicVol_switch = true;
              console.log(res);
              console.log("任务关闭接口成功");
            })
            .catch((err) => {
              console.log(err);
              console.log("失败");
            });
        }
        sessionStorage.setItem("taskVol_switch", this.taskVol_switch);
      }
      console.log(123, "耳机");
    },
    button4() {
      this.headsetVol_switch = !this.headsetVol_switch;
      console.log(123, "总任务");
    },
    collect() {
      //查询采集配置
      request({
        url: "/acollect",
      })
        .then((res) => {
          console.log("获得数据库的所有分区列表:");
          console.log(res.data.data, 999);
          this.tableData = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    record_on() {
      if (this.recordstate == true) {
        // this.record = true;
        this.recordstate = false;
        request({
          method: "post",
          url: "/broad/stopSave/" + this.task.timetasks[0].id,
        })
          .then((res) => {
            if (res.data.resultCode == 200) {
              this.$message({
                message: "结束录制！",
                type: "success",
              });
            }
            console.log(res, "结束录制");
            // console.log(res.data.data);
            // console.log("get:”/aregion”接口成功");
            sessionStorage.removeItem("recordstate");
          })
          .catch((err) => {
            console.log(err);
            console.log("get:”/aregion”接口失败");
          });
      } else {
        this.record = true;
      }
    },
    startRecord() {
      console.log(
        this.task.timetasks[0].id,
        123,
        this.filename,
        this.recordtime
      );
      if (this.filename != "") {
        request({
          method: "post",
          url:
            "/broad/startSave/" +
            this.task.timetasks[0].id +
            "/" +
            this.filename,
        })
          .then((res) => {
            console.log(res, "开始录制");
            this.record = false;
            this.recordstate = true;
            let list = {
              recordstate: this.recordstate,
              id: this.task.timetasks[0].id,
            };
            let jsonlist = JSON.stringify(list);
            sessionStorage.setItem("recordstate", jsonlist);
            // console.log(res.data.data);
            // console.log("get:”/aregion”接口成功");
            if (res.data.resultCode == 200) {
              this.$message({
                message: "开启录制~",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("get:”/aregion”接口失败", list);
          });
      } else {
        this.$message({
          message: "文件名不能为空！",
          type: "warning",
        });
      }
      this.filename = "";
      this.recordtime = "";
    },
    monitorButton(id){
      this.listenState = !this.listenState;
      console.log(this.task.id,123)
      if (this.listenState == true) {
        request({
          method: "put",
          url: "/atask/start/listen/" + this.task.id,
        })
          .then((res) => {
            console.log("监听任务”接口成功", res);
            let list = {
              listenState: this.listenState,
              id: id,
            };
            let jsonlist = JSON.stringify(list);
            sessionStorage.setItem("listenState", jsonlist);
            if (res.status == 200) {
              this.$message({
                message: "开始监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务”接口失败");
          });
        console.log(this.task.id, 123);
      }
      if (this.listenState == false) {
        request({
          method: "put",
          url: "/atask/stop/listen/" + this.task.id,
        })
          .then((res) => {
            sessionStorage.removeItem("listenState");
            console.log("监听任务停止”接口成功", res);
            if (res.status == 200) {
              this.$message({
                message: "结束监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务停止”接口失败");
          });
        console.log(this.task.id, 123);
      }
    },
    // 退出子任务界面，后台判断是否清除权限++++++++++++++++++++++++++++++++++++++++++++
    decideClearPriority() {
      request({
        method: "put",
        url: "/broad/direct/return/" + this.task.timetasks[0].id,
      })
        .then((res) => {
          console.log(res);
          console.log("'put:'/broad/direct/return/{timeTaskID}'接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("'put:'/broad/direct/return/{timeTaskID}'接口失败");
        });
    },

    // 初始化音量---------------------------------------------------------
    initialVol() {
      // 麦克风音量
      this.miVol = this.task.timetasks[0].miVol;
      // 音乐音量
      this.musicVol = this.task.timetasks[0].musicVol;
      // 任务音量
      this.taskVol = this.task.timetasks[0].taskVol;
      // 耳机音量
      this.headsetVol = this.task.timetasks[0].headsetVol;
    },

    // 初始化播放模式
    initialrepeatMode() {
      this.currentRepeatMode = this.task.repeatMode;
    },

    // 当前任务该显示的星星颗数
    getStars() {
      console.log(this.task.priority);
      //permissionValue的1颗星、2颗星、3颗星、在直播任务里面的权限对应1、3、6
      let p = this.task.priority;
      if (p === 1) {
        this.permissionValue = 1;
      } else if (p === 3) {
        this.permissionValue = 2;
      } else if (p === 5) {
        this.permissionValue = 3;
      } else {
        this.$message({
          message: "获取不到权限，拍照联系前端开发查明原因！",
          type: "warning",
        });
      }
    },

    // 当前单击子任务的索引值
    currentClickIndex(index) {
      this.currentIndex = index;
    },

    // 播放按钮值
    // isPause(i) {
    //   this.pause = i;
    // },

    // 获得当前任务
    getTask() {
      if (typeof this.$route.query.task == "string") {
        //正常路由跳转过来后就把数据塞到 localStorage
        // console.log(this.$route.fullPath);
        sessionStorage.setItem("currentDirectTask", this.$route.query.task); //数据存storage，防止刷新丢失
        this.task = JSON.parse(this.$route.query.task);
      } else {
        //刷新时走这
        // 去localStorage取;
        this.statetask = JSON.parse(sessionStorage.getItem("statetask"));
        if (this.statetask == true) {
          this.task = JSON.parse(sessionStorage.getItem("Task"));
          console.log(this.task, "task");
        } else {
          this.task = JSON.parse(sessionStorage.getItem("currentDirectTask"));
          console.log(this.task, "currentDirectTask");
        }
      }
      var musicVol_switch = JSON.parse(
        sessionStorage.getItem("musicVol_switch")
      );
      var taskVol_switch = JSON.parse(sessionStorage.getItem("taskVol_switch"));
      var miVol_switch = JSON.parse(sessionStorage.getItem("miVol_switch"));
      if (musicVol_switch != null) {
        this.musicVol_switch = musicVol_switch;
      }
      if (taskVol_switch != null) {
        this.taskVol_switch = taskVol_switch;
      }
      if (miVol_switch != null) {
        this.miVol_switch = miVol_switch;
      }
      this.getSongs();
      this.initialVol();
      this.initialrepeatMode();
    },

    // 获得当前任务下的歌曲
    getSongs() {
      // console.log(this.task.timetasks[0].songs);
      // this.currentTaskSongs = this.task.timetasks[0].songs;
      this.currentTimeTask = this.task.timetasks[0];
      console.log(this.currentTimeTask, "歌曲", this.task);
    },
    alert() {
      if (this.state == true) {
        this.$message({
          message: "请停止音乐再进行修改哦！",
          type: "warning",
        });
      }
    },
    //编辑按钮
    editTask() {
      this.is_show_addTask = true;
      // 将数据库的值拿来初始化
      this.createNewTask = this.task;
      console.log(this.createNewTask,'/////////////////////')
    },

    // 添加任务的界面关闭之前
    handleClose() {
      this.is_show_addTask = false;
    },

    // 弹出界面的取消按钮
    isShowAddTaskDialog(res) {
      this.is_show_addTask = res;
    },

    // 弹出界面的取消按钮（将新任务保存到数据库）
    newTask(res) {
      this.updataTaskObj = res;
      this.updataTask();
      console.log(res, 67890);
    },

    // 更改麦克风音量
    changeMiVol() {
      this.volumeAdjustment(1, this.task.timetasks[0].id, this.miVol);
      console.log("miVol:" + this.miVol);
    },

    // 更改音乐音量
    changeMusicVol() {
      this.volumeAdjustment(2, this.task.timetasks[0].id, this.musicVol);
      console.log("musicVol:" + this.musicVol);
    },

    // 更改任务音量
    changeTaskVol() {
      this.volumeAdjustment(3, this.task.timetasks[0].id, this.taskVol);
      console.log("taskVol:" + this.taskVol);
    },

    // 更改耳机音量
    changeHeadsetVol() {
      this.volumeAdjustment(4, this.task.timetasks[0].id, this.headsetVol);
      console.log("headsetVol:" + this.headsetVol);
    },

    // 1 列表循环; 2 单曲循环;3 列表播放; 4 随机循环播
    // 单曲循环按钮
    single() {
      this.playMOde(this.task.timetasks[0].id, 2);
      // alert("2");
    },

    // 列表循环按钮
    list() {
      this.playMOde(this.task.timetasks[0].id, 1);
      // alert("1");
    },

    // 随机播放按钮
    random() {
      this.playMOde(this.task.timetasks[0].id, 4);
      // alert("4");
    },

    // 顺序播放按钮
    order() {
      this.playMOde(this.task.timetasks[0].id, 3);
      // alert("3");
    },
    getclearP() {
      request({
        method: "put",
        url: "/broad/clearPriority/" + this.task.timetasks[0].id,
      })
        .then((res) => {
          console.log("清除权限成功");
          // console.log(res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
    // 调用接口得到所有分区++++++++++++++++++++++++++++++++++++++++++++++++
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          this.allZones = res.data.data;
          // console.log("获得的分区：");
          // console.log(res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },

    // 调用接口得到所有歌曲++++++++++++++++++++++++++++++++++++++++++++++++
    getAllSongs() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          console.log("获得的歌曲：", res);
          this.allSongs = res.data.data;
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },

    // 调用接口更新任务++++++++++++++++++++++++++++++++++++++++++++++++++++
    updataTask() {
      // this.task.collectState的值是从内存中得到的，并非数据库，所以不能保存进数据库
      delete this.task.collectState;

      request({
        method: "put",
        url: "/atask",
        data: this.updataTaskObj,
      })
        .then((res) => {
          // 判断是否数据库有重复数据
          // this.statetask=true
          console.log(res.data.data,'**************************')
          this.task=res.data.data
          // this.$router.go(0)
          // window.location.reload()
          sessionStorage.setItem("statetask", true);
          // var list = JSON.stringify(res.data.data);
          sessionStorage.setItem("Task",JSON.stringify(res.data.data)); //数据存storage，防止刷新丢失
          this.reload() 
          console.log(res.data.data, 996999);
          if (res.data.data == null) {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          } else {
            // console.log("put:/atask”接口成功");
            // 更新歌曲之后要重新渲染到界面
            this.getSongs();
            // 更新界面的星星数
            this.getStars();
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("put:”/atask”接口失败");
        });
    },

    // 音量调整+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // "/broad/volume{volumeType}/{timeTaskID}/{volume}"
    volumeAdjustment(volumeType, timeTaskID, volume) {
      request({
        method: "put",
        url: "/broad/volume/" + volumeType + "/" + volume,
        data: [timeTaskID],
      })
        .then((res) => {
          console.log(res),
          this.getATimeTask()
          console.log("put:'/broad/volume/{volumeType}/{volume}'接口成功",this.task.id);
        })
        .catch((err) => {
          console.log(err),
            console.log("put:'/broad/volume/{volumeType}/{volume}'接口失败");
        });
    },
    getATimeTask() {
      request({
        method: "get",
        url: "/atask/" + this.task.id,
      })
        .then((res) => {
          // console.log("获得的任务：");
          // console.log(res);
         var task = JSON.stringify(res.data.data);
          sessionStorage.setItem("currentDirectTask", task);
          if(JSON.parse(sessionStorage.getItem("Task"))!=null){
            sessionStorage.setItem("Task", task);
          }
          console.log("get:“/atask/{id}”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:“/atask/{id}”接口失败");
        });
    },

    // 播放模式++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    playMOde(timeTaskID, repeatMode) {
      request({
        method: "put",
        url: "/broad/repeatMode/" + repeatMode,
        data: [timeTaskID],
      })
        .then((res) => {
          // 更改播放模式按钮的背景颜色
          this.currentRepeatMode = repeatMode;
          this.task.repeatMode=repeatMode
          var task = JSON.stringify(this.task);
          sessionStorage.setItem("currentDirectTask", task);
          if(JSON.parse(sessionStorage.getItem("Task"))!=null){
            sessionStorage.setItem("Task", task);
          }
          console.log(res),
            console.log("'put:'/broad/repeatMode/{repeatMode}'接口成功",this.task.repeatMode);
        })
        .catch((err) => {
          console.log(err),
            console.log("'put:'/broad/repeatMode/{repeatMode}'接口失败");
        });
    },

    // https://www.cnblogs.com/showcase/p/14000199.html
    //初始化websocket
    initWebSocket() {
      // let token = JSON.parse(sessionStorage.getItem("token"));
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
      //  let wsuri = "ws://106.15.40.26:9004/chat";
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //连接建立之后执行send方法发送数据
    websocketonopen() {
      var token = JSON.parse(sessionStorage.getItem("token"));
      // let actions = "timetaskid:" + this.task.timetasks[0].id; 旧
      let actions = {
        // token: "222",
        token: token,
        dataType: "timetaskid:",
        ts: [this.task.timetasks[0].id],
      };
      this.websocketsend(JSON.stringify(actions));
      console.log(actions);
    },

    //数据发送
    websocketsend(Data) {
      this.timer = setInterval(() => {
        this.websock.send(Data);
        console.log("进度条");
      }, 1000);
      // this.websock.send(Data);
    },

    //数据接收
    websocketonmessage(e) {
      // console.log(JSON.parse(e.data).serverDateTime, "返回的值");
      // console.log("我是索引：" + JSON.parse(e.data).ts[0].index);
      this.serverDateTime=JSON.parse(e.data).serverDateTime
      // 取得返回的值
      let d = JSON.parse(e.data).ts[0];

      // 正在执行的索引
      let i = d.index;
      // 歌曲进度(取整)
      let p = parseInt(d.position);
      // 状态
      let s = d.state;
      // 当前子任务的id
      let t = d.timetaskID;

      this.websocketState = s;
      if (s == 0) {
        // 表示停止
        this.state = false;
        // sessionStorage.setItem("state", false);
        // this.pause = false;
      } else if (s == 1) {
        // 表示播放
        // 获得歌曲索引
        this.currentSongIndex = JSON.parse(e.data).ts[0].index;
        // 获得歌曲进度值
        this.progress = p;
        // 若有音乐在执行播放，进入界面要显示红色背景
        // this.pause = true;
        this.state = true;
        // sessionStorage.setItem("state", true);
      } else if (s == 3) {
        // 表示暂停
        // 获得歌曲索引
        this.currentSongIndex = JSON.parse(e.data).ts[0].index;
        this.state = true;
        // 获得歌曲进度值
        this.progress = p;
        // sessionStorage.removeItem("state");
        // sessionStorage.setItem("state", false);
        // this.pause = false;
      } else {
        this.state=false
        // console.log("WebSocket返回的state值异常！！！");
      }
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },
  },

  //离开路由之后断开websocket连接
  destroyed() {
    this.websock.close();
    clearInterval(this.timer);
    // this.getclearP()
    this.close();
    sessionStorage.removeItem("miVol_switch");
    // this.miVol_switch=false
  },
};
</script>

<style scoped>
::v-deep .el-drawer__body {
  background-color: #353757;
}
.yinliang {
  background-color: #d4b1db;
  position: absolute;
  height: 550px;
  border-radius: 5px;
  right: 30px;
  top: 95px;
}
.el-header {
  /* background-color: #b3c0d1; */
  background: linear-gradient(to top, #353757, #fff);
  color: #333;
  text-align: center;
  line-height: 40px;
  border-bottom-style: groove;
  border-bottom-color: #fff;
  border-bottom-width: 1px;
}
.el-footer {
  background-color: #353757;
  color: #333;
  text-align: center;
  line-height: 40px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}
.el-main {
  background-color: #e9eef3;
  padding: 0px;
}

.a1 {
  float: left;
}
.a2 {
  float: right;
}
.a3 {
  float: right;
}
.a4 {
  width: 15%;
  margin-top: 50%;
  margin-left: 8px;
}
.a5 {
  line-height: 50px;
  color: white;
  text-align: center;
}
.a6 {
  height: 100px;
  line-height: 100px;
  /* color: #fff; */
}
.a7 {
  margin-top: 15%;
}

/* 测试 */
/*cont是父组件包裹子组件的类名，index是子组件中要修改样式的类名*/
/* 播放 */
.cont01 >>> .a1-2 {
  /* background-color: rgb(204, 78, 78); */
  /* 209, 64, 119 */
  background: linear-gradient(to top, rgb(204, 78, 78), #fff);
  border: 1px solid rgb(204, 78, 78);
}
/* 暂停 */
.cont02 >>> .a1-3 {
  /* background-color: rgb(132, 112, 206); */
  background: linear-gradient(to top, rgb(132, 112, 206), #fff);
  border: 1px solid rgb(132, 112, 206);
}
/* 播放 */
.cont03 >>> .a8 {
  background-image: url("../../assets/image/direct/stopPlay.png");
}
/* 暂停 */
.cont04 >>> .a9 {
  background-image: url("../../assets/image/direct/startPlay.png");
}

.a8 {
  height: 650px;
  /* background-color: brown; */
  width: 97%;
  overflow-x: hidden;
  margin: 1%;
}

.button1 {
  background-image: url("../../assets/image/volume/miVol_on.png");
  background-repeat: no-repeat;
  background-size: 50%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button1-1 {
  background-image: url("../../assets/image/volume/miVol_off.png");
  background-repeat: no-repeat;
  background-size: 50%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button2 {
  background-image: url("../../assets/image/volume/musicVol_on.png");
  background-repeat: no-repeat;
  background-size: 60%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button2-2 {
  background-image: url("../../assets/image/volume/musicVol_off.png");
  background-repeat: no-repeat;
  background-size: 60%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button3 {
  background-image: url("../../assets/image/volume/headsetVol_on.png");
  background-repeat: no-repeat;
  background-size: 68%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button3-3 {
  background-image: url("../../assets/image/volume/headsetVol_off.png");
  background-repeat: no-repeat;
  background-size: 68%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button4 {
  background-image: url("../../assets/image/volume/taskVol_on.png");
  background-repeat: no-repeat;
  background-size: 82%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button4-4 {
  background-image: url("../../assets/image/volume/taskVol_off.png");
  background-repeat: no-repeat;
  background-size: 82%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}

.bGcolor {
  background-color: #409eff;
  color: #00060c;
}
.block {
  margin-right: 8px;
}
.z1 {
  display: flex;
  line-height: 60px;
}
.z2 {
  /* flex: 1;
  text-align-last: justify; */
  width: 70px;
}
.z3 {
  width: 80%;
}
.record {
  margin-left: 100px;
}
.record:hover {
  color: black;
  /* background-color: rgba(45, 110, 251, 0.8);
  border-color: rgba(45, 110, 251, 0.8); */
}
.taskName{
  white-space: nowrap;
}
::v-deep .el-dialog{
  width: 700px;
}
.serverDateTime{
  color: #fff;
  font-size: 14px;
  margin-left: 10%;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
</style>
