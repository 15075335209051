// https://www.cnblogs.com/lyt520/p/14921989.html

// let wsurl = "ws://106.15.40.26:9004/chat";
let wsurl = "wss://hcjk.sgbjxt.com/broadChat?userId=admin";
let ws = null
let weboscket_callback = null

let initWebSocket = () => {
    //初始化 websocket
    ws = new WebSocket(wsurl)
    ws.onmessage = websocketonmessage
    ws.onopen = websocketonopen
    ws.onerror = websocketonerror
    ws.onclose = websocketclose
}

// 连接成功
let websocketonopen = () => {
    console.log('连接 websocket 成功001')
}

//获取 websocket 推送的数据
let websocketonmessage = e => {
 return weboscket_callback(e)
}


// 连接失败时重新连接
let websocketonerror = () => {
 initWebSocket()
}

// 断开链接后报错
let websocketclose = e => {
 console.log('断开连接', e)
}

// 手动关闭 websocket
let closewebsocket = () => {
 ws.close()
}


// 发送数据
let sendData = (data, callback) => {
 weboscket_callback = callback
 //  判断 data 数据类型
 if (typeof data == 'string') {
  data = data
 } else {
  data = JSON.stringify(data)
 }

 //  判断 websocket 的状态
 if (ws.readyState === ws.OPEN) {
  // 已经打开,可以直接发送
  ws.send(data)
 } else if (ws.readyState === ws.CONNECTING) { //刷新界面一般走这
  // 正在开启状态中,则 50毫秒后重新发送
  console.log('正在连接中......')
  setTimeout(() => {
   ws.send(data)
  }, 50)
 } else {
  // 未打开，则开启后重新调用
  initWebSocket()
  sendData(data, callback)
 }

}

// 导出
export { initWebSocket, sendData, closewebsocket }