// 编辑分区界面
<template>
  <div id="app">
    <div class="a1">
      <div class="a2">
        <span class="a5">分区名称 :</span>
        <el-input
          class="a3"
          placeholder="请输入分区名称"
          v-model="input"
          minlength="2"
          maxlength="30"
          show-word-limit
          clearable
          autofocus="true"
          @change="getRegionName"
        ></el-input>
      </div>
      <div class="a2">
        <span class="a5">寻呼号码 :</span>
        <el-input
          class="a3"
          placeholder="请输入寻呼号码"
          v-model="callNum"
          minlength="2"
          maxlength="10"
          show-word-limit
          clearable
          autofocus="true"
          @change="getRegionCallnum"
        ></el-input>
      </div>
      <div class="a4">
        <template>
          <!-- :filter-method="filterMethod" -->
          <el-transfer
            filterable
            filter-placeholder="请输入搜索的IP"
            v-model="value"
            :data="data"
            :titles="['可选音箱', '已选音箱']"
            @change="selectIP"
          >
          <template slot-scope="scope">
            <span>{{
                  scope.option.label
                }}</span>
          </template>
          </el-transfer>
        </template>
      </div>
      <div><el-button @click="saveRegion">保存</el-button></div>
    </div>
  </div>
</template>


<script type="text/javascript">
export default {
  name: "app",
  // 用来接收父组件传给子组件的数据
  props: [
    "sendallSpeakerIP",
    "sendRegionID",
    "sendRegionName",
    "sendRegionSpeakerID",
    "sendRegionCallnum"
  ], //[所有音箱,分区ID,分区名称，分区已选的音箱的id集合]

  data() {
    const generateData = () => {
      const data = [];

      // const cities = [
      //   { id: "1", ip: "192.168.1.1" },
      //   { id: "2", ip: "192.168.1.2" },
      // ];

      // cities.forEach((city) => {
      //   data.push({
      //     label: city.ip,
      //     key: city.id,
      //   });
      // });

      const allSpeakerIP = this.sendallSpeakerIP;
      allSpeakerIP.forEach((allSpeaker) => {
        data.push({
          // label: allSpeaker.devAddr,
          label: allSpeaker.devAddr+"("+allSpeaker.ip+")",
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: allSpeaker.id.toString(),
        });
      });

      return data;
    };

    return {
      // 可选音箱
      data: generateData(),
      // 已选音箱
      value: [],
      // 分区名称
      input: this.sendRegionName,
      callNum: this.sendRegionCallnum,
    };
  },
  components: {},
  created(){
    this.value=this.sendRegionSpeakerID
  },
  mounted () {
    console.log(this.sendRegionName,this.sendRegionCallnum,77777)
  },
  watch: {
    sendRegionSpeakerID(newValue, oldValue) {
      console.log("旧" + oldValue);
      console.log("新:" + newValue);
      this.value = newValue;
    },
    sendRegionName(newValue, oldValue) {
      console.log("旧" + oldValue);
      console.log("新:" + newValue);
      this.input = newValue;
    },
    sendRegionCallnum(newValue, oldValue) {
      console.log("旧" + oldValue);
      console.log("新:" + newValue);
      this.callNum = newValue;
    },
  },
  methods: {
    // 选中的IP---------------------------------------------------------------------
    selectIP() {
      let speakers = this.getSpeakers();
      this.$emit("selectIP", speakers); //（父组件接收的方法，父组件接收的数据）
      console.log(speakers,99999);

      this.$message({
        message: "已修改，但未保存",
        type: "warning",
      });
    },

    //通过ID的值找到iD对应的对象，即终端集合------------------------------------------
    getSpeakers() {
      let data = [];
      for (let i = 0; i < this.value.length; i++) {
        for (let j = 0; j < this.sendallSpeakerIP.length; j++) {
          if (this.value[i] == this.sendallSpeakerIP[j].id) {
            console.log(this.sendallSpeakerIP[j]);
            data.push(this.sendallSpeakerIP[j]);
          }
        }
        console.log(data,99689);
      }
      return data;
    },

    //获得分区名称----------------------------------------------------------------------
    getRegionName() {
      console.log(this.input);
      this.$emit("getRegionName", this.input);

      this.$message({
        message: "已修改，但未保存",
        type: "warning",
      });
    },
    getRegionCallnum(){
      console.log(this.callNum);
      this.$emit("getRegionCallnum", this.callNum);

      this.$message({
        message: "已修改，但未保存",
        type: "warning",
      });
    },
    // 保存按钮--------------------------------------------------------------------------
    saveRegion() {
      // console.log(this.selectIP);
      // console.log(this.getRegionName);

      if (this.input != "") {
        this.$emit("saved", "saved");
      }

      // 保存之后清空
      this.input = "";
      this.callNum="";
      this.speakers = [];
    },
  },
};
</script>

<style scoped>
#app {
  /* 穿梭框内容左对齐 */
  text-align: left;
}
.a1 {
  /* background-color: palevioletred; */
  text-align: center;
}
.a2 {
  text-align: center;
  width: 100%;
  /* background-color: peachpuff; */
  /* line-height: 80px; */
  margin-bottom: 20px;
}
.a3 {
  width: 300px;
}
.a4 {
  text-align: left;
  /* background-color: rgb(164, 231, 226); */
}
.a5 {
  margin: 20px;
  /* background-color: rgb(164, 231, 226); */
}
</style>
