// 系统配置模块的9个按钮组件
<template>
  <div id="app">
    <div class="a1">
      <table>
        <tr>
          <td>
            <div><slot name="item-icon"></slot></div>
          </td>
          <td>
            <tr class="a2">
              <div>
                <b><slot name="item-title-text"></slot></b>
              </div>
            </tr>
            <tr class="a3">
              <div><slot name="item-details-text"></slot></div>
            </tr>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
.a1 {
  flex: 1;
  text-align: center;
  height: 155px;
  line-height: 20px;
  float: left;
  /* background-color: plum; */
  column-width: 300px;
}
.a2 {
  text-align: left;
  /* background-color: peru; */
}
.a3 {
  text-align: left;
  /* background-color: rgb(142, 214, 170); */
}
</style>
