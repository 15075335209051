// 添加定时子任务界面（弹框）
<template>
  <div id="app">
    <!-- <div>{{ songValues }}</div> -->
    <div class="a1">
      <!-- 任务名称 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务名称：" :label-width="labelWidth">
            <el-input
              size="mini"
              v-model="taskName"
              show-word-limit
              minlength="1"
              maxlength="10"
              @blur="taskNameBlur"
              placeholder="任务名称由“中文、英文、数字”组成"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 任务分区 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务分区：" :label-width="labelWidth">
            <!-- collapse-tags -->
            <el-select
              v-model="regionValue"
              multiple
              placeholder="请选择分区"
              size="mini"
              class="a5"
            >
              <el-option
                v-for="region in allRegions"
                :key="region.id"
                :label="region.name"
                :value="region.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 开始日期 -->
      <!-- <el-row class="a4">
        <el-form>
          <el-form-item label="开始日期：" :label-width="labelWidth">
            <el-date-picker
              v-model="startDate"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              size="mini"
              class="a5"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-row> -->
      <!-- 时间段 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="时间段：" :label-width="labelWidth">
            <el-time-picker
              is-range
              v-model="timeValue"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              format="HH:mm:ss"
              value-format="HH:mm:ss"
              size="mini"
              class="a5"
              @blur="timeValueBlur"
              :default-value="defaultValue"
              :clearable="false"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 任务周期 -->
      <!-- collapse-tags -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务周期：" :label-width="labelWidth">
            <el-select
              v-model="periodValue"
              placeholder="请选择周期"
              size="mini"
              class="a5"
            >
              <el-option
                v-for="period in allperiods"
                :key="period.id"
                :label="period.label"
                :value="period.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 自定义任务周期复选框 -->
      <el-row class="a4">
        <div>
          <el-checkbox-group v-model="checkList" class="a8">
            <el-checkbox label="1" :disabled="isDisabled">周日</el-checkbox>
            <el-checkbox label="2" :disabled="isDisabled">周一</el-checkbox>
            <el-checkbox label="3" :disabled="isDisabled">周二</el-checkbox>
            <el-checkbox label="4" :disabled="isDisabled">周三</el-checkbox>
            <el-checkbox label="5" :disabled="isDisabled">周四</el-checkbox>
            <el-checkbox label="6" :disabled="isDisabled">周五</el-checkbox>
            <el-checkbox label="7" :disabled="isDisabled">周六</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-row>

      <!-- 选择歌曲 -->
      <el-row>
        <div class="a6">
          <el-transfer
            filterable
            filter-placeholder="请输入歌曲关键字"
            v-model="songValues"
            :data="formatAllSongs"
            target-order="push"
            :titles="['可选歌曲', '已选歌曲']"
          >
          </el-transfer>
        </div>
      </el-row>
    </div>
    <el-row>
      <div class="a7">
        <el-button @click="cancelButton">取消</el-button>
        <el-button type="primary" @click="saveButton">保存</el-button>
      </div>
    </el-row>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    const generateData = () => {
      const data = [];
      const s = this.songs;
      s.forEach((song) => {
        data.push({
          label: song.name,
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: song.id.toString(),
        });
        // console.log(song);
      });

      return data;
    };

    return {
      //标签的宽度--
      labelWidth: "120px",
      dayDate:'',
      // 当前的子任务
      currentTimeTask: {},

      // 任务名称
      taskName: "",

      // 所有的分区
      allRegions: [],
      // 已选的分区
      regionValue: [],

      // 已选的任务周期（一次0，每天1，周一到周五2，自定义3）
      periodValue: "",
      // 任务周期数组
      allperiods: [
        {
          id: 1,
          label: "一次",
          value: "0",
        },
        {
          id: 2,
          label: "每天",
          value: "1",
        },
        {
          id: 3,
          label: "周一到周五",
          value: "2",
        },
        {
          id: 4,
          label: "自定义",
          value: "3",
        },
      ],
      // 自定义任务周期复选框的值
      checkList: [],
      // 是否禁用复选框
      isDisabled: true,

      // 任务周期最终的值
      // taskPeriodValue: [], //是否有用？
      // 任务周期
      // taskPeriod: "", //是否有用？

      // 开始日期
      startDate: "",
      // 时间段
      timeValue: "",
      // 选择器打开时默认显示的时间
      defaultValue: [
        new Date().getTime() + 60000,
        new Date().getTime() + 300000,
      ],

      // 全部歌曲
      allSongs: [],
      // 已选的歌曲
      songValues: [],
      // 经过格式化的全部歌曲
      formatAllSongs: generateData(),
    };
  },
  props: ["initTimetask", "regions", "songs", "startAndEndDate"], //当前大任务对象;所有的分区；所有的歌曲;大定时任务的开始和结束日期
  components: {},
  // created: {},
  watch: {
    periodValue(newVal) {
      // 实时获得当前的“任务周期”
      this.currentTimeTask.repeatType = newVal;

      if (newVal == "3") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
        // 清空选中的复选框
        this.checkList = [];
      }
    },
    // 监听“开始日期”的值变化
    startDate(newVal) {
      this.startDate = newVal;
      console.log(newVal);
    },
    // 监听用户是否在编辑(界面的信息不能为空)
    initTimetask(newVal) {
      // alert("编辑中");
      console.log(newVal,9999999999);

      // 当前子任务ID
      this.currentTimeTask.id = newVal.id;

      // 任务名称
      this.taskName = newVal.name;

      // 任务分区
      newVal.regions.forEach((r) => {
        this.regionValue.push(r.id);
      });

      // 所选日期
      // this.startDate = newVal.startDate;旧的
      this.startDate = newVal.planTime.startDate;

      //时间段
      this.timeValue = [
        newVal.planTime.startTimeOfDay,
        newVal.planTime.endTimeOfDay,
      ];

      //任务周期
      this.periodValue = newVal.planTime.repeatType.toString();
      //判断任务周期为"自定义"时候
      if (this.periodValue == "3") {
        this.checkList = newVal.planTime.daysOfWeek.split(",");
      }

      // 所选歌曲
      // this.songValues = newVal.songs;
      newVal.songs.forEach((s) => {
        this.songValues.push(s.id.toString());
      });
    },
  },
  created() {},
  mounted() {
    this.initializeValue();
    this.day()
  },

  methods: {
    // 初始化
    initializeValue() {
      this.currentTimeTask = this.initTimetask;
      this.allRegions = this.regions;
      this.allSongs = this.songs;
    },
    // 通过歌曲ID，从歌曲数组中取出相应的歌曲对象
    getSongs() {
      // const data = [];
      // this.songs.forEach((a) => {
      //   this.songValues.forEach((b) => {
      //     if (a.id == b) {
      //       data.push(a);
      //       console.log(data);
      //     }
      //   });
      // });
      // return data;

      const data = [];
      for (let i = 0; i < this.songValues.length; i++) {
        for (let j = 0; j < this.songs.length; j++) {
          if (this.songValues[i] == this.songs[j].id) {
            this.songs[j].index = i + 1;
            data.push(this.songs[j]);
          }
        }
      }
      return data;
    },

    // 通过分区ID，从分区数组中取出相应的分区对象
    getRegion() {
      const data = [];
      this.allRegions.forEach((a) => {
        this.regionValue.forEach((b) => {
          if (a.id == b) {
            data.push(a);
          }
        });
      });
      return data;
    },

    // 获得并处理任务周期的值
    getPeriodValue() {
      // 通过“重复类型”选择具体星期几
      if (this.periodValue == "1") {
        this.currentTimeTask.planTime.daysOfWeek = "1,2,3,4,5,6,7";
      } else if (this.periodValue == "2") {
        this.currentTimeTask.planTime.daysOfWeek = "2,3,4,5,6";
      } else if (this.periodValue == "3") {
        this.currentTimeTask.planTime.daysOfWeek = this.checkList.toString();
      } else {
        this.currentTimeTask.planTime.daysOfWeek = "";
      }
      //重复类型（一次,每天，周一到周五，自定义）
      this.currentTimeTask.planTime.repeatType = this.periodValue;
      // console.log(this.currentTimeTask.planTime.daysOfWeek);
    },

    // 任务名称输入框监听
    taskNameBlur() {
      // alert("失去焦点！");
      //用户名正则，中文、英文、数字但不包括下划线等符号
      var r = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (!r.test(this.taskName)) {
        this.$message({
          message: '用户名称由"中文、英文、数字"组成',
          type: "warning",
        });
        this.taskName = "";
      }
    },

    // 时间段监听
    timeValueBlur() {
      // 开始时间和结束时间不能相同
      if (this.timeValue[0] == this.timeValue[1]) {
        this.timeValue = [];
      }
    },
    day() {
      var nowDate = new Date();
      var date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        day: nowDate.getDate(),
      }
      this.dayDate = date.year + '-' + (date.month >= 10 ? date.month : '0' + date.month) + '-' + (date.day >= 10 ? date.day : '0' + date.day)
      // console.log(dayDate);
    },
    // 单击保存按钮之前先赋值
    addValue() {
      // 任务名称
      this.currentTimeTask.name = this.taskName;

      // 任务分区
      let b = this.getRegion();
      this.currentTimeTask.regions = b;

      // 所选日期
      // this.currentTimeTask.startDate = this.startDate;旧的
      this.currentTimeTask.planTime.startDate = this.dayDate;

      //时间段
      // if (this.timeValue != null) {
      // } else {
      //   this.$message.error("错了哦！时间段还没有填写");
      // }
      this.currentTimeTask.planTime.startTimeOfDay = this.timeValue[0];
      this.currentTimeTask.planTime.endTimeOfDay = this.timeValue[1];

      //任务周期
      this.getPeriodValue();

      // 所选歌曲
      let a = this.getSongs();
      this.currentTimeTask.songs = a;
    },

    // 保存之前先确认是不是“一次”任务，是一次任务则校验“开始日期”是否在大定时任务的开始日期和结束日期之前
    isOneTask() {
      if (this.periodValue == "0") {
        let a = new Date(this.startAndEndDate[0]).getTime();
        let b = new Date(this.startDate).getTime();
        let c = new Date(this.startAndEndDate[1]).getTime();
        
        if (!(a <= b && b <= c)) {
          this.startDate = "";
          // alert("“开始日期”需要在上级“开始日期”和“结束日期”之间");
        }
      }

      // console.log("大任务开始日期---------：" + this.startAndEndDate[0]);
      // console.log("当前子任务开始时间-----：" + this.startDate);
      // console.log("大任务结束日期---------：" + this.startAndEndDate[1]);
    },

    // 保存按钮
    saveButton() {
      // 判断“一次”任务时的“开始日期”
      this.isOneTask();
      // 判断还有哪些框没有输入或选择值
      if (
        this.taskName != "" &&
        this.regionValue.length != 0 &&
        // this.startDate != "" &&
        this.timeValue != "" &&
        this.periodValue != "" &&
        // this.checkList.length != 0 &&
        this.songValues.length != 0
      ) {
        // 当选择了“一次”，那么“开始日期”也必须选上
        // if (
        //   (this.periodValue == "0" && this.startDate != "") ||
        //   (this.periodValue == "0" && this.startDate != null)
        // ) {
        // }
        // 当选择了“自定义”，那么复选框也必须要选上
        if (
          (this.periodValue == "3" && this.checkList.length != 0) ||
          this.periodValue != "3"
        ) {
          // 给currentTimeTask赋值
          this.addValue();
          // 提交给父组件
          this.$emit("currentTimeTask", this.currentTimeTask);

          // 关闭界面
          this.$emit("closeDialog", false);
        } else {
          this.$message({
            message: "还有选项未选，请继续选择！001",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "还有选项未选，请继续选择！",
          type: "warning",
        });
      }
    },

    // 取消按钮
    cancelButton() {
      // 关闭界面
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style  scoped>
.a1 {
  width: 90%;
  height: 100%;
  width: 600px;
}
.a4 {
  height: 40px;
}
.a5 {
  width: 100%;
}
.a6 {
  margin: 0 auto;
}
.a6 >>> .el-transfer-panel {
  width: 200px;
  text-align: left;
}
.a7 {
  margin-top: 15px;
  float: right;
}
.a8 {
  float: right;
  margin-right: 0px;
}

.el-transfer {
  font-size: 14px;
  height: 200px;
  width: 590px;
  /* 搜索框的位置 */
  line-height: 0px;
  margin: auto;
}

/* 穿梭框中的搜索框边距 */
.el-transfer >>> .el-transfer-panel__filter {
  margin: 5px;
}
/* 穿梭框中的搜索框高度 */
.el-transfer >>> .el-transfer-panel__filter .el-input__inner {
  height: 24px;
}
/* 穿梭框中的头部 */
.el-transfer >>> .el-transfer-panel .el-transfer-panel__header {
  height: 30px;
  line-height: 30px;
}
/* 穿梭框中的可选内容高度 */
.el-transfer >>> .el-transfer-panel__list.is-filterable {
  height: 130px;
}
::v-deep .el-date-editor.el-input{
  width: 100%;
}
::v-deep .el-range-editor--mini.el-input__inner{
  width: 100%;
}
</style>
