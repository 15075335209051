<template>
    <div id="app">
      <urgent></urgent>
      <div class="a1">
        <span class="a2">
          <router-link to="/wulian" replace>
            <el-button size="mini" icon="el-icon-back" round>返回</el-button>
          </router-link>
        </span>
        <span class="a2">
          <el-select
              v-model="zone"
              placeholder="请选择分区"
              class="z3"
              style="width: 65%;"
              size="mini"
              @change="select"
            >
            <el-option
            v-for="zone in region"
                :key="zone.id"
                :label="zone.name"
                :value="zone.id"
            >
            </el-option>
            </el-select>
        </span>
        <!-- <span class="a2">
          <el-select
              v-model="io"
              placeholder="请选择通道"
              class="z3"
              style="width: 65%;"
              size="mini"
              @change="select"
            >
            <el-option
                v-for="(item, index) in 9"
                :key="index"
                :value="item"
            >
            </el-option>
            </el-select>
        </span> -->
        <span class="a2">
          <el-select
              v-model="mic"
              placeholder="请选择歌曲"
              class="z3"
              style="width: 65%;"
              size="mini"
            >
            <el-option
            v-for="zone in allSongs"
                :key="zone.id"
                :label="zone.name"
                :value="zone.name"
            >
            </el-option>
            </el-select>
        </span>
        <span class="a2" style="margin-left: 40px;">
          <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                @click="issue"
              >
              确认下发
              </el-button>
        </span>
        <!-- <span class="a2" style="margin-left: 40px;">
          <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                @click="a"
              >
              1
              </el-button>
        </span>
        <span class="a2" style="margin-left: 40px;">
          <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                @click="b"
              >
              2
              </el-button>
        </span> -->
      </div>
      <div>
        <template>
          <el-table
            :data="task"
            style="width: 100%; line-height: 10px"
            height="78vh"
            border
          >
          <el-table-column
            label="音箱"
            align="center"
            prop="ip"
            show-overflow-tooltip
          >
          
          </el-table-column>
          <!-- <el-table-column
            label="I/O"
            align="center"
            prop="io"
            show-overflow-tooltip
          >
          
          </el-table-column> -->
          <el-table-column
            label="关联歌曲"
            align="center"
            prop="musName"
            show-overflow-tooltip
          >
          
          </el-table-column>
          <el-table-column
            label="下载进度"
            align="center"
            prop="loadState"
          >
          <template slot-scope="scope">
               <span v-if="scope.row.loadState == 0">
                <el-progress :percentage="100"></el-progress>
               </span>
               <span v-else-if="scope.row.loadState == 1">
                <el-progress :percentage="50"></el-progress>
               </span>
               <span v-else>
                <el-progress :percentage="0"></el-progress>
               </span>
              </template>
          </el-table-column>
          <el-table-column
            label="下载状态"
            align="center"
            prop="loadState"
            show-overflow-tooltip
          >
          <template slot-scope="scope">
               <span v-if="scope.row.loadState == 0" style="color: #13CE66;">
                下载完成
               </span>
               <span v-else-if="scope.row.loadState == 1" style="color: #20A0FF;">
                正在下载
               </span>
               <span v-else style="color: #FF4949;">
                下载失败
               </span>
              </template>
          </el-table-column>
          <!-- <el-table-column
            label="I/O状态"
            align="center"
            prop="ioState"
            show-overflow-tooltip
          >
          <template slot-scope="scope">
               <span v-if="scope.row.ioState == 0" style="color: #13CE66;">
                I/O设置完成
               </span>
               <span v-else-if="scope.row.ioState == 1" style="color: #20A0FF;">
                I/O正在设置
               </span>
               <span v-else style="color: #FF4949;">
                I/O设置失败
               </span>
              </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <span v-if="scope.row.ioState == 2 ||scope.row.loadState==2" style="color: #20A0FF;">
                <el-button
                size="mini"
                @click="handleEdit(scope.row)"
                type="danger"
              >
              重新下载
              </el-button>
              </span>
            </template>
          </el-table-column> -->
          <el-table-column align="center" label="音响歌曲">
            <template slot-scope="scope">
                <el-button
                size="mini"
                @click="checkmusic(scope.row)"
                type="primary"
              >
              点击查看
              </el-button>
            </template>
          </el-table-column>
          </el-table>
        </template>
        <el-dialog :append-to-body="true" title="音箱歌曲" :visible.sync="dialogTableVisible">
  <el-table :data="musicData">
    <el-table-column property="name" align="center" label="歌曲名称"></el-table-column>
    <el-table-column  label="操作" align="center">
      <template slot-scope="scope">
                <el-button
                size="mini"
                @click="delmusic(scope.$index,scope.row)"
                type="danger"
              >
              删除歌曲
              </el-button>
            </template>
    </el-table-column>
  </el-table>
</el-dialog>
      </div>
     
    </div>
  </template>
    <script type="text/javascript">
  import { request } from "../../network/request";
  
  export default {
    name: "app",
    data() {
      return {
        task:[],
        // task:[
        //   {
        //     name:'127.0.0.0',
        //     io:'9',
        //     music:'嘉宾',
        //     jindu:'0'
        //   },
        //   {
        //     name:'127.0.0.2',
        //     io:'0',
        //     music:'嘉宾',
        //     jindu:'1'
        //   },
        //   {
        //     name:'127.0.0.3',
        //     io:'4',
        //     music:'嘉宾',
        //     jindu:'2'
        //   }
        // ],
        region:[],
        is_show:false,
        zone:'',
        mic:'',
        io:'',
        allSongs:[],
        dialogTableVisible:false,
        musicData:[],
        mac:''
      };
    },
    mounted() {
      this.getRegion()
      this.getAllSongs()
      this.timer = setTimeout(() => {
        this.initWebSocket()
        console.log("1");
      }, 1000);
    },
    methods: {
      delmusic(index,row){
        let list={
          "mac":this.mac,
          "musicName":row.name
        }
        request({
        method: "post",
        url: "/speakerMusic/delete",
        // data: "", //+++++++++++++++++++++++++++++++++++++++++++++++++++++
        // data: rSpeakers,
        data:JSON.stringify(list)
      })
        .then((res) => {
          console.log(res, 2222222222222);
          // 删除一行数据
          if (res.data.resultCode == 200) {
            this.musicData.splice(index, 1);
            this.$message.success("歌曲删除成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
        console.log(index,row,this.mac,list)
      },
      checkmusic(row){
        this.dialogTableVisible=true
        console.log(row.mac)
        this.mac=row.mac
        let list={
          mac:row.mac
        }
         request({
        method: "post",
        url: "/speakerMusic/selectMsg",
        data: JSON.stringify(list)
      })
        .then((res) => {
          console.log("查询音乐成功",res,list);
        })
        .catch((err) => {
          console.log(err);
          console.log("IO接口失败");
        });
        
      },
      // a(){
      //   request({
      //   url: "/speakerMusic/textIO",
      // })
      //   .then((res) => {

      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // },
      // b(){
      //   request({
      //   url: "/speakerMusic/textmusic",
      // })
      //   .then((res) => {

      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // },
      getRegion() {
      request({
        url: "/aregion",
      })
        .then((res) => {
          console.log("获得数据库的所有分区列表:");
          console.log(res.data.data, 999);
          this.region=res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAllSongs() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          this.allSongs = res.data.data;
          console.log("全部歌曲",res.data.data);
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
      handleEdit(row){
        var list={
          "mac":row.mac,
          "musicName":row.musName,
          "io":row.io
        }
        request({
        method: "post",
        url: "/speakerMusic/setOneMusic",
        data: JSON.stringify(list)
      })
        .then((res) => {
          console.log("文件重新下发成功",res,list);
          if (res.data.resultCode == 200) {
            this.$message.success("文件重新下发成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("文件重新下发失败");
        });
        console.log(row,list)
      },
      issue(){
        // &&this.io!=''
        if(this.zone!=''&&this.mic!=''){
        var list={
          "regionId":this.zone,
          // "io":this.io,
          "musicName":this.mic
        }
        request({
        method: "post",
        url: "/speakerMusic/setIoMusic",
        data: JSON.stringify(list)
      })
        .then((res) => {
          this.select()
          console.log("文件下发成功",res,list);
          if (res.data.resultCode == 200) {
            this.$message.success("文件开始下发");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("文件下发失败");
        });
        }
        // var list=[
        //   {a:'1',s:'6'},
        //   {b:'2'},
        //   {c:'3'},
        // ]
        // var list2={D:'4',s:'6'}
        // for(let i=0;i<list.length;i++){
        //     if (list[i]['s'] === list2.s) {
        //       list[i]=list2;
        //         console.log(list)
        //     }
        // }
        console.log(this.zone,this.io,this.mic)
      },
      select(){
        // &&this.io!=''
        if(this.zone!=''){
          var list={
          "regionId":this.zone,
          // "io":this.io
        }
        request({
        method: "post",
        url: "/speakerMusic/getSpeakerLowerState",
        data: JSON.stringify(list)
      })
        .then((res) => {
          this.task = res.data.data;
          console.log("查询IO成功",res,list);
        })
        .catch((err) => {
          console.log(err);
          console.log("IO接口失败");
        });
        }
      },
      initWebSocket() {
      // const wsuri = "ws://106.15.40.26:9004/chat";
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //连接建立之后执行send方法发送数据
    websocketonopen() {
      console.log(13456)
    },

    //数据发送
    websocketsend(Data) {
    },

    //数据接收
    websocketonmessage(e) {
      console.log(JSON.parse(e.data).dateSoues,'----------------')
      var io =JSON.parse(e.data).dateSoues;
      if(io.command=='list_sound'){
        this.musicData=io.file_name
        console.log(io.file_name)
      }
      // console.log( io.id,'----------------')
      // 歌曲
      for(let i=0;i<this.task.length;i++){
        // console.log( this.task[i]['id'],'///////////')
            if (this.task[i]['id'] == io.id&&io.command=='insert_finish')  {
                // this.task[i]["ip"] =io.ip;
                // this.task[i]["musName"] =io.musicName;
                this.task[i]["loadState"] =io.type;
                // alert(123)
          
          }
            // if (this.task[i]['id'] == io.id&&io.command=='set_iochufa_file')  {
            //     // this.task[i]["io"] =io.io;
            //     this.task[i]["ioState"] =io.type;
            //     this.task[i]["loadState"]=io.loadState
            //     // alert(123)
            // }
        }
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },
    },
    destroyed() {
    this.websock.close();
  },

  };
  </script>
    <style scoped>
  body {
    text-align: center;
  }
  table {
    margin: auto;
  }
  
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  .a1 {
    line-height: 30px;
    /* width: 400px; */
    width: 100%;
    margin-bottom: 10px;
    float: left;
  }
  .a2 {
    /* margin-right: 10px; */
    float: left;
  }
  .a3 {
    text-align: center;
    width: 100%;
    /* background-color: peachpuff; */
    /* line-height: 80px; */
    margin-bottom: 20px;
  }
  .a4 {
    width: 300px;
  }
  .a5 {
    margin: 20px;
    /* background-color: rgb(164, 231, 226); */
  }
  ::v-deep .el-dialog__header {
    padding: 20px 20px 0px;
  }
  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 0px 20px;
  }
  ::v-deep .el-dialog__body {
    height: 540px;
  }
  /* ::v-deep .el-dialog__wrapper{
      margin-top: 8vh !important;
  } */
  .tabs {
    /* background-color: rgb(141, 228, 250); */
    width: 70px;
    /* line-height:30px; */
    margin-left: 0px !important;
    border: 0px;
  }
  .tabs:hover {
    background-color: #ffffff;
    color: #606266;
  }
  .tabs:focus {
    background-color: #ffffff;
  }
  .bodys {
    /* background-color: rgb(141, 228, 250); */
    width: 480px;
    line-height: 30px;
    margin: auto;
    margin-top: 30px;
  }
  .z1 {
    display: flex;
    line-height: 45px;
    /* margin-left: 40px; */
    /* margin-top: 10px; */
  }
  .z2 {
    /* flex: 1;
      text-align-last: justify; */
    width: 80px;
    text-align: left;
    /* margin-right: 20px; */
  }
  .z3 {
    width: 50%;
  }
  .btn {
    position: absolute;
    right: 50px;
    bottom: 20px;
  }
  /* ::v-deep .el-select__tags {
    top: 28%;
  } */
  .el-transfer {
    font-size: 14px;
    height: 200px;
    width: 590px;
    /* 搜索框的位置 */
    line-height: 0px;
    margin: auto;
  }
  ::v-deep .el-transfer__buttons {
    padding: 0 10px;
  }
  ::v-deep .el-transfer {
    height: 250px !important;
  }
  .el-dropdown {
    vertical-align: top;
  }
  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  ::v-deep .el-input__inner{
      height: 28px;
  }
  ::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
    overflow:visible
  }
  .el-transfer >>> .el-transfer-panel__filter {
    margin: 5px;
  }
  /* 穿梭框中的搜索框高度 */
  .el-transfer >>> .el-transfer-panel__filter .el-input__inner {
    height: 24px;
  }
  /* 穿梭框中的头部 */
  .el-transfer >>> .el-transfer-panel .el-transfer-panel__header {
    height: 30px;
    line-height: 30px;
  }
  /* 穿梭框中的可选内容高度 */
  /* .el-transfer >>> .el-transfer-panel__list.is-filterable {
    height: 130px;
  } */
 ::v-deep .el-form-item__label{
    line-height: 68px;
  }
  .bb{
    margin-top: 14px;
    margin-left: 5px;
  }
  </style>