// 子任务小方块（长方形）
<template>
  <div id="app" class="body">
    <!-- <div>index : {{ index }}</div>
    <div>$store.state.cIndex : {{ $store.state.cIndex }}</div>
    <div>isForbidden : {{ isForbidden }}</div>
    <div>currentSongIndex : {{ currentSongIndex }}</div>
    <div>progress值 ：{{ progress }}</div>
    <div>value值 ：{{ value1 }}</div> -->
    <div
      class="a1-1"
      :class="{
        'a1-1': isStop,
        'a1-2': isPlay,
        'a1-3': isPause,
      }"
    >
      <el-row class="a3">
        {{ index+1 }}
      </el-row>
      <el-row class="a6">
        <el-tooltip class="item" effect="dark" :content="song.name" placement="top">
          <el-col :span="12" class="a7">{{ song.name }}</el-col>
    </el-tooltip>
        
        <el-col :span="6">
          <el-button
            circle
            @click="startPlayButton()"
            class="a8"
            :class="{ a9: buttonBG }"
          >
          </el-button>
        </el-col>
        <el-col :span="6">
          <el-button 
            circle
            @click="stopPlayButton()"
            :disabled="isForbidden ? true : false"
            class="a10"
            >
          </el-button>
        </el-col>
      </el-row>
      <!-- :step="1" -->
      <el-row>
        <el-slider v-model="value1" @change="sliderChange" :max="songTimeLong" :show-tooltip="false" class="a2" ></el-slider>
      </el-row>
      <el-row>
        <!-- <span class="a4">00:00:00</span> -->
        <span class="a4">
          {{ parseLen(parseInt(value1 / 60 / 60)) }}:
          {{ parseLen(parseInt((value1 / 60) % 60)) }}:
          {{ parseLen(parseInt(value1 % 60)) }}
        </span>
        <span class="a5">
          {{ parseLen(parseInt(song.timeLong / 60 / 60)) }}:
          {{ parseLen(parseInt((song.timeLong / 60) % 60)) }}:
          {{ parseLen(parseInt(song.timeLong % 60)) }}
        </span>
      </el-row>
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      // 进度条的值
      value1: 0,
      // 歌曲序号
      songIndex: "",
      // 当前播放歌曲的音乐总长度
      songTimeLong: 0,

      // 是否停止
      isStop: true,
      // 是否播放
      isPlay: false,
      // 是否暂停
      isPause: false,
      //按钮的背景(默认小三角)
      buttonBG: false,
      //当前单击的歌曲索引
      // currentSongIndex: "",
      // 前一个索引值
      indexed: "",

      // 是否不禁用停止按钮
      isForbidden: true,
    };
  },
  //当前歌曲索引;当前歌曲;是否重新选择了别的子组件;当前的大任务;当前正在播放歌曲的索引，当前正在播放歌曲的进度条进度值,websocket返回的状态
  props: [
    "index",
    "song",
    "changeSelect",
    "task",
    "currentSongIndex",
    "progress",
    "websocketState",
  ],
  watch: {
    // 监听websocket返回的状态
    websocketState(newVal, oldVal) {
      if (newVal == 1) {
        this.isStop = false;
        this.isPlay = true;
        this.isPause = false;
      } else if (newVal == 3) {
        this.isStop = false;
        this.isPlay = false;
        this.isPause = true;
      } else {
        this.isStop = true;
        this.isPlay = false;
        this.isPause = false;
      }
    },
    // 监听是否重新选择子组件
    changeSelect() {
      // console.log("子组件旧" + oldVal);
      // console.log("子组件新" + newVal);

      if (this.changeSelect == this.indexed) {
        this.buttonBG = false;
        // console.log("我已经重新选择" + this.$store.state.cIndex);

        // 将上一个子组件的停止按钮禁用
        if (this.index != this.$store.state.cIndex) {
          this.isForbidden = true;
        }
      }
    },

    // 通过websoket推送的数据可知道是否换另一首歌曲播放
    currentSongIndex(newVal, oldVal) {
      // console.log("旧索引：" + oldVal);
      // console.log("新索引：" + newVal);

      // 将当前播放歌曲的索引提交给vuex
      this.$store.commit("updateCIndex", newVal);
      // 提交给父组件
      this.$emit("currentClickIndex", newVal);

      if (this.index == newVal) {
        // 获得当前正在歌曲的总长度
        this.songTimeLong = parseInt(this.song.timeLong);
        // 当前正在播放的歌曲相对应的停止按钮不禁用
        this.isForbidden = false;
      this.buttonBG=JSON.parse(sessionStorage.getItem("buttonBG"))
        // 当前正在播放的歌曲相对应的背景按钮显示红色
        // this.isPlay = true;
        // this.isPause = true;
      } else {
        // 非正在播放的歌曲相对应的停止按钮全部禁用
        this.isForbidden = true;
        this.buttonBG = false;
        // 当进度条到达最大值之后回到初始状态
        this.value1 = 0;
      }
    },
    progress(newVal, oldVal) {
      if (this.index == this.currentSongIndex) {
        this.value1 = newVal;
      }
    },
  },
  components: {},
  // destroyed () {
  //     window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  //   window.removeEventListener('unload', e => this.unloadHandler(e))
  // },
  created() {
     this.songTimeLong = parseInt(this.song.timeLong);
     console.log(this.songTimeLong,this.currentSongIndex)
  },
  mounted() {
    this.getindex();
    console.log(this.task,66666666,this.buttonBG)
    //   window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    // window.addEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    // 关闭浏览器停止播放
    //  async beforeunloadHandler(e) {
    //   await this.stopPlay(); // 退出登录接口
    //   // this._beforeUnload_time = new Date().getTime()
    //   // console.log('this._beforeUnload_time：', this._beforeUnload_time)
    //   window.close()
    // },
    sliderChange(){
      if (this.index == this.currentSongIndex) {
         request({
        method: "put",
         url: "/broad/setPosition/" + this.task.timetasks[0].id + "/" + this.value1,
      })
        .then((res) => {
          console.log(res),
          console.log("接口成功");
          console.log(this.value1,66666666)
        })
        .catch((err) => {
          console.log(err),
            console.log("接口失败");
        });
      }else{
        this.value1=0
      }
    },
    //时间格式转换补0---------------------------------------------------------------------
    parseLen(v) {
      return v > 9 ? v : "0" + v;
    },

    // 对歌曲索引进行处理显示---------------------------------------------------------------
    getindex() {
      this.songIndex = 1;
      // this.buttonBG=JSON.parse(sessionStorage.getItem("buttonBG"))
    },

    // 播放按钮-----------------------------------------------------------------------------
    startPlayButton() {
      // 调用播放方法
      // this.startPlay();

      // 更改按钮背景颜色
      this.buttonBG = !this.buttonBG;
      // 更改背景颜色
      sessionStorage.setItem("buttonBG", this.buttonBG);
      // console.log(this.buttonBG,00332)
      if (this.buttonBG == true) {
        this.isPlay = true;
        this.isPause = false;
        this.isStop = false;
        this.startPlay();
      } else {
        this.isPlay = false;
        this.isPause = true;
        this.isStop = false;
        this.pausePlayButton();
      }

      this.$store.commit("updateCIndex", this.index);
      if (this.index == this.$store.state.cIndex) {
        this.isForbidden = false;
      }

      // 提交给父组件之前保存一个值作为旧值（即上一个值）
      this.indexed = this.index;
      // 索引值
      this.$emit("currentClickIndex", this.index);
      // 播放按钮值
      // this.$emit("isPause", this.buttonBG);

      // document.querySelector("a1").setAttribute("style", "background:#000000");
    },

    // 暂停按钮-----------------------------------------------------------------------------
    pausePlayButton() {
      this.pausePlay();
    },

    // 停止按钮-------------------------------------------------------------------------------
    stopPlayButton() {
      // 调用停止方法
      this.stopPlay();

      // 更改按钮的背景颜色
      this.buttonBG = false;

      // 更改背景颜色
      this.isStop = true;
      this.isPlay = false;
      this.isPause = false;
      this.buttonBG = false;

      // 当进度条回到初始状态
      this.value1 = 0;
      // 播放按钮变成小三角形的背景图
      this.$emit("isPause", false);
      this.isForbidden=true
      console.log(this.buttonBG,789)
    },

    // 调用播放歌曲按钮+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    startPlay() {
      console.log(this.task);
      console.log("播放" + this.task.timetasks[0].id,this.task.id);
      console.log("播放" + this.index);
      request({
        method: "post",
        url: "/broad/start/" + this.task.timetasks[0].id + "/" + this.index,
      })
        .then(() => {
          console.log("post:”/broad/start/{timeTaskID}/{index}”接口成功",this.task,this.index);
        })
        .catch((err) => {
          console.log(err);
          console.log("post:”/broad/start/{timeTaskID}/{index}”接口失败");
        });
    },
    // 调用暂停歌曲按钮+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    pausePlay() {
      // console.log("暂停" + this.task.timetasks[0].id);

      request({
        method: "post",
        url: "/broad/pause/" + this.task.timetasks[0].id,
      })
        .then(() => {
          console.log("post:”/broad/pause/{timeTaskID}”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("post:”/broad/pause/{timeTaskID}”接口失败");
        });
    },
    // 调用停止歌曲按钮+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    stopPlay() {
      // console.log("停止" + this.task.timetasks[0].id);
      request({
        method: "post",
        url: "/broad/stop/" + this.task.timetasks[0].id,
      })
        .then(() => {
          console.log("post:”/broad/stop/{timeTaskID}”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("post:”/broad/stop/{timeTaskID}”接口失败");
        });
    },
  },
  updated() {
    // this.isForbidden = true;
    // console.log("更新");
  },
};
</script>

<style  scoped>
.body {
  width: 225px;
  height: 135px;
  /* background-color: cadetblue; */
}
/* 停止（默认设置） */
.a1-1 {
  width: 220px;
  height: 124px;
  /* background-color: rgb(165, 157, 159); */
  /* 125, 208, 241 */
  background: linear-gradient(to top, rgb(165, 157, 159), #fff);
  border: 1px solid rgb(165, 157, 159);
  border-radius: 10px;
  /* 基础投影 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 0 6px rgba(0, 0, 0, 0.04);
}
/* 播放 */
.a1-2 {
  width: 220px;
  height: 124px;
  /* background-color: rgb(165, 157, 159); */
  background: linear-gradient(to top, rgb(165, 157, 159), #fff);
  border: 1px solid rgb(165, 157, 159);
  border-radius: 10px;
  /* 基础投影 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 0 6px rgba(0, 0, 0, 0.04);
}
/* 暂停 */
.a1-3 {
  width: 220px;
  height: 124px;
  /* background-color: rgb(165, 157, 159); */
  background: linear-gradient(to top, rgb(165, 157, 159), #fff);
  border: 1px solid rgb(165, 157, 159);
  border-radius: 10px;
  /* 基础投影 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22), 0 0 6px rgba(0, 0, 0, 0.04);
}

.a2 {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}
.a3 {
  float: left;
  margin-left: 5%;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  line-height: 20px;
  background-color: rgb(13, 218, 218);
}
.a4 {
  float: left;
  margin-left: 5%;
}
.a5 {
  float: right;
  margin-right: 5%;
}
.a6 {
  /* 清除浮动 */
  clear: both;
  margin-left: 10px;
}
.a7 {
  margin: 10px 0px;
  text-align: left;
  /* 字体大小 */
  font-size: 12px;
  /*要显示文字的宽度*/
  width: 95px;
  /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
  text-overflow: ellipsis;
  /*让文字不换行*/
  white-space: nowrap;
  /*超出要隐藏*/
  overflow: hidden;
}

/* 播放按钮背景图片 */
.a8 {
  background-image: url("../../assets/image/direct/startPlay.png");
  width: 43px;
  height: 43px;
}
/* 暂停按钮背景图片 */
.a9 {
  background-image: url("../../assets/image/direct/stopPlay.png");
}

.a10 {
  background-image: url("../../assets/image/direct/32.jpg");
  width: 44px;
  height: 45px;
}
.el-button.is-disabled{
  background-image: url("../../assets/image/direct/32-1.jpg");
  width: 44px;
  height: 45px;
}
::v-deep .el-icon-switch-button{
  font-size: 25px;
  font-weight: 800;
  margin-left: -4px;
  margin-top: -4px;
}
::v-deep .el-button[disabled]:hover{
  background-image: url("../../assets/image/direct/32-1.jpg");
  width: 44px;
  height: 45px;
}
</style>
