// 任务小方块（正方形）
<template>
  <div id="app">
    <div>
      <span :class="{ a3: this.is_show_deleteButton }">
        <el-button
          type="success"
          icon="el-icon-service"
          circle
          class="a2"
          :style="listenState == true ? 'color: aqua;background-color: #92CADC;' : 'color: #ffffff;background-color: #92CADC;'"
          @click="monitorButton(task.id)"
        >
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          circle
          class="a2"
          @click="delectTaskButton"
          style="margin-left: 122px;background-color: #92CADC;"
        >
        </el-button>
      </span>

      <router-link to="/subtaskD" replace>
        <el-button
          class="a1"
          :class="{ a4: this.findActivicy(task.id) >0 }"
          @click="updateTask"
        >
          <div>{{ taskIndex }}</div>
          <div style="color: black;">{{ task.name }}</div>
          <!-- <div>{{ task.id }}</div> -->
        </el-button>
      </router-link>
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
export default {
  name: "app",
  data() {
    return {
      aTask: "",
      isActivicy: [],
      isbroadState: "",
      listenState: false,
    };
  },
  props: [
    "taskIndex",
    "task",
    "is_show_deleteButton",
    "activityTaskIds",
    "broadState",
  ], //当前任务索引，当前任务,是否显示任务右上角的删除按钮,当前播放或暂停的大任务ID集合
  components: {},
  watch: {
    activityTaskIds() {
      this.getActivicyTaskId();
      this.getbroadState()
    },
  },
  created() {
    this.getActivicyTaskId();
    this.getbroadState()
  },
  mounted() {
    // console.log(this.isActivicy)
    this.getATimeTask();
    if (JSON.parse(sessionStorage.getItem("listenState")) != null) {
      var listenState = JSON.parse(sessionStorage.getItem("listenState"));
      if (
        listenState.id == this.task.id &&
        this.listenState == false
      ) {
        this.listenState = listenState.listenState;
      }
    }

    console.log(
      this.activityTaskIds,
      99888888321,
      this.task.id,
      this.broadState,
      this.isActivicy
    );
  },
  methods: {
    // 获得所有正在播放或暂停的大任务ID与当前大任务ID进行比对  .length
    getbroadState() {
      this.isActivicy =[]
      for (let i = 0; i < this.broadState.length; i++) {
        if (this.broadState[i] == 1) {
          if (-1!=this.findActivicy(this.activityTaskIds[i]))
            console.log(this.isActivicy.length, "++++++"+this.activityTaskIds[i]);
            this.isActivicy[this.isActivicy.length] =this.activityTaskIds[i];
            this.isActivicy.length++;
          //this.isActivicy = this.activityTaskIds[i];
         console.log(this.isActivicy, "jslfjksjmjsfmasl;"+this.isActivicy[i]);
        }
      }
    },
    

    findActivicy(activicy){
      var tem =-1;
      for (let i = 0; i < this.isActivicy.length; i++) {
       // console.log("sasasas///"+activicy,this.isActivicy[i]);
        if (activicy ==this.isActivicy[i])
           tem= this.isActivicy[i]
      }
     // console.log("sasasas///"+tem);
      return tem;  
    },
    getActivicyTaskId() {
      for (let i = 0; i < this.activityTaskIds; i++) {
        if (this.activityTaskIds[i] == this.task.id) {
          this.isActivicy = this.activityTaskIds[i];
          console.log(this.isActivicy, ";;;;;;;;;;;;;;;;;;");
        }
      }

    },
    // 当单击大任务按钮时-----------------------------------
    updateTask() {
      this.$router.push({
        // name: "subtaskD",
        path: "/subtaskD",
        query: {
          task: JSON.stringify(this.aTask),

          // task: encodeURIComponent(JSON.stringify(this.aTask)),
        },
      });
    },

    //通过大任务的id去数据库取该任务的所有数据（因为“/atask”接口查询全部任务，得不到大任务下的子任务）
    getATimeTask() {
      request({
        method: "get",
        url: "/atask/" + this.task.id,
      })
        .then((res) => {
          // console.log("获得的任务：");
          // console.log(res);
          this.aTask = res.data.data;
          // console.log("get:“/atask/{id}”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:“/atask/{id}”接口失败");
        });
    },
    // 删除按钮
    delectTaskButton() {
      console.log(this.task.id);

      this.$emit("currentTaskId", this.task.id);
    },
    monitorButton(id) {
      console.log(id, 123);
      this.listenState = !this.listenState;
      if (this.listenState == true) {
        request({
          method: "put",
          url: "/atask/start/listen/" + this.task.id,
        })
          .then((res) => {
            console.log("监听任务”接口成功", res);
            let list = {
              listenState: this.listenState,
              id: id,
            };
            let jsonlist = JSON.stringify(list);
            sessionStorage.setItem("listenState", jsonlist);
            if (res.status == 200) {
              this.$message({
                message: "开始监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务”接口失败");
          });
        console.log(this.task.id, 123);
      }
      if (this.listenState == false) {
        request({
          method: "put",
          url: "/atask/stop/listen/" + this.task.id,
        })
          .then((res) => {
            sessionStorage.removeItem("listenState");
            console.log("监听任务停止”接口成功", res);
            if (res.status == 200) {
              this.$message({
                message: "结束监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务停止”接口失败");
          });
        console.log(this.task.id, 123);
      }
    },
  },
};
</script>

<style  scoped>
.a1_1 {
  background-color: red;
}
.a1 {
  /* background-color: rgb(162, 202, 180); */
  /* background-color: rgb(81, 170, 199); */
  background: linear-gradient(to right, rgb(81, 170, 199), #fff);
  color: white;
  width: 160px;
  height: 160px;
  border: 1px solid rgb(81, 170, 199);
  border-radius: 18px;
  box-shadow: 4px 4px 5px #353757;
  /* position: relative;
  float: left; */
  /* border-top-color: saddlebrown;
  margin-top: -40px;
  margin-right: -15px; */
}
.a2 {
  position: absolute;
  z-index: 1;
  margin-top: 10px;
  /* background-color: purple;
  position: relative;
  float: right; */
}
.a3 {
  visibility: hidden;
}
.a4 {
  /* background-color: rgb(184, 68, 68); */
  background: linear-gradient(to right, rgb(212, 95, 140), #fff);
  border: 1px solid rgb(212, 95, 140);
  /* background-color: rgb(212, 95, 140); */
}
.a4:hover {
  background-color: rgb(212, 95, 140);
  /* background-color: rgb(184, 68, 68); */
}
button {
  background-color: rgb(81, 170, 199);
  border: none;
}
button:focus {
  background-color: rgb(81, 170, 199);
}
button:hover {
  background-color: rgb(81, 170, 199);
  color: #fff;
}
</style>
