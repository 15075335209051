// 定时任务界面
<template>
  <div id="app">
    <urgent></urgent>
    <div>
      <el-container>
        <el-header>
          <div class="a1">定时</div>
          <div class="a2">
            <el-button size="mini" @click="addTaskButton" round>添加</el-button>
            <el-button size="mini" @click="editTaskButton" round>
              编辑
            </el-button>
          </div>
        </el-header>
        <!-- <el-main style="height: 390px;"> -->
        <el-main style="height: 550px;">
          <div>
            <el-row :gutter="5">
              <el-col
                :span="4"
                class="grid-content bg-purple"
                v-for="(task, key, index) in planTasks"
                :key="key"
              >
                <task-p
                  :taskIndex="index"
                  :task="task"
                  :is_show_deleteButton="is_show_deleteButton"
                  @currentTaskId="deleteCurrentTask"
                ></task-p>
                <div class="c1">
                  <!-- style="background: linear-gradient(to right, #409EFF, #fff);" -->
                  <el-button v-show="task.deleted==1" type="primary" size="mini" @click="start(task.id)"
                    >启用</el-button
                  >
                  <!-- style="background: linear-gradient(to right, #F56C6C, #fff);" -->
                  <el-button style="margin-right: 10px;" v-show="task.deleted==0" type="danger" size="mini" @click="stop(task.id)"
                    >停用</el-button
                  >
                </div>
                <!-- <div>{{ task.id }}</div> -->
              </el-col>
            </el-row>
          </div>
        </el-main>
        <!-- <el-header>
          <div class="a1">临时定时</div>
        </el-header>
        <el-main style="height: 190px;">
          <div>
            <el-row :gutter="5">
              <el-col
                :span="4"
                class="grid-content bg-purple"
                v-for="(task, key, index) in panlTasksTemp"
                :key="key"
              >
                <task-p
                  :taskIndex="index"
                  :task="task"
                  :is_show_deleteButton="is_show_deleteButton"
                  @currentTaskId="deleteCurrentTask"
                ></task-p>
              </el-col>
            </el-row>
          </div>
        </el-main> -->
      </el-container>
    </div>

    <!-- 添加任务弹框 -->
    <!-- :top="true" -->
    <!-- title="新建直播任务" -->
    <el-dialog
      v-dialogDrag
      class="a3"
      title="新建或编辑定时任务"
      :visible.sync="is_show_addTask"
      :destroy-on-close="true"
      :before-close="handleClose"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <add-task-p
        :createNewTask="createNewTask"
        @closeDialog="isShowAddTaskDialog"
        @newTask="newTask"
      >
      </add-task-p>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import taskP from "../public/taskP";
import { request } from "../../network/request";
import addTaskP from "../public/addTaskP";

export default {
  name: "app",
  data() {
    return {
      // 定时任务
      planTasks: [],
      // 临时定时
      panlTasksTemp: [],

      // 是否显示任务右上角的删除按钮
      is_show_deleteButton: true,
      // 添加任务界面是否可视
      is_show_addTask: false,

      createNewTask: {
        // 任务ID
        id: "",
        // 任务名称
        name: "",
        // 权限
        priority: null,
        // 任务类型
        type: null,
      },
      // 新创建任务
      newTaskObj: {},
    };
  },
  components: {
    taskP,
    addTaskP,
  },
  mounted() {
    this.getPlanTask();
    this.getPlanTaskTemp();
  },
  methods: {
    start(taskID) {
      request({
        method: "post",
        url: "/plan/taskStart/"+taskID,
      })
        .then((res) => {
        console.log(res,"启用定时任务成功",this.task);
        if(res.data.resultCode==200){
          // this.planTasks.deleted=0
          this.getPlanTask()
          this.$message({
              message: '定时任务已开启',
              type: "success",
            });
        }else {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(taskID);
    },
    stop(taskID) {
      request({
        method: "post",
        url: "/plan/taskStop/"+taskID,
      })
        .then((res) => {
        console.log(res,"停止定时任务成功");
         if(res.data.resultCode==200){
          // this.planTasks.deleted=1
          this.getPlanTask()
          this.$message({
              message: '定时任务已停用',
              type: "success",
            });
        }else {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log(taskID);
    },
    // 添加按钮--------------------------------------------------------------
    addTaskButton() {
      this.is_show_addTask = true;
    },

    // 编辑按钮
    editTaskButton() {
      this.is_show_deleteButton = !this.is_show_deleteButton;
    },

    // 删除任务按钮
    deleteCurrentTask(taskId) {
      this.delectTask(taskId);
    },

    // 添加任务的界面关闭之前
    handleClose() {
      this.is_show_addTask = false;
    },

    // 关闭界面清空输入框的内容
    isShowAddTaskDialog(res) {
      this.is_show_addTask = res;

      // 关闭界面之前清空输入框的值
      // 名称
      this.createNewTask.name = "";
      // 权限
      this.createNewTask.priority = null;
      // 类型
      this.createNewTask.type = null;
    },

    // 将新任务保存到数据库
    newTask(res) {
      this.newTaskObj = res;
      this.addTask();
    },

    // 调用接口得到所有的(定时)任务++++++++++++++++++++++++++++++++++++++++++++++++
    getPlanTask() {
      request({
        method: "get",
        url: "/atask/type/3",
      })
        .then((res) => {
          this.planTasks = res.data.data;
          console.log(res.data.data, 888888);
          // console.log("get:”/atask/type/类型”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask/type/类型”接口失败");
        });
    },
    // 调用接口得到所有的(临时定时)任务++++++++++++++++++++++++++++++++++++++++++++++++
    getPlanTaskTemp() {
      request({
        method: "get",
        url: "/atask/type/4",
      })
        .then((res) => {
          this.panlTasksTemp = res.data.data;
          // console.log(res.data.data);
          // console.log("get:”/atask/type/类型”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask/type/类型”接口失败");
        });
    },

    // 调用接口创建新任务++++++++++++++++++++++++++++++++++++++++++++++++
    addTask() {
      request({
        method: "post",
        url: "/atask",
        data: this.newTaskObj,
      })
        .then((res) => {
          // 判断是否数据库有重复数据
          if (res.data.data == null) {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          } else {
            // this.getAllTask();
            this.getPlanTask();
            this.getPlanTaskTemp();
            console.log("get:”/atask”接口成功");
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask”接口失败");
        });
    },

    // 删除任务接口++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    delectTask(taskId) {
      request({
        method: "delete",
        url: "/atask/" + taskId,
      })
        .then((res) => {
          console.log(res);
          if(res.data.resultCode==200){
             this.$message({
              message: '删除成功',
              type: "success",
            });
          }else{
            this.$message({
              message: res.data.resultMessage,
              type: "error",
          })
          }
          console.log("delete:”/atask/{id}”接口成功");
          // this.getAllTask();
          this.getPlanTask();
          this.getPlanTaskTemp();
        })
        .catch((err) => {
          console.log(err);
          console.log("delete:”/atask/{id}”接口失败");
        });
    },
  },
};
</script>

<style scoped>
.el-header {
  /* background-color: #b3c0d1; */
   background-color: #333555;

  color: #fff;
  text-align: center;
  line-height: 30px;
  height: 30px !important;
  border-radius: 5px;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
  margin: 0px !important;
  padding: 0px;
  height: 290px;
}

.el-row {
  line-height: 180px;
  width: 100%;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  /* background: #d3dce6; */
  background: rgb(233 238 243);
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.a1 {
  float: left;
  border-radius: 5px;
}
.a2 {
  float: right;
}

.a3 >>> .el-dialog__header {
  background-color: #deebee;
  line-height: 35px;
  text-align: left;
  /* height: initial; */
  /* margin: 0px; */
  padding: 0px;
}
.c1 {
  display: flex;
  /* margin-left: 36px; */
  margin-top: -45px;
   justify-content:center
}
::v-deep .grid-content{
  margin-bottom: 15px;
}
::v-deep .el-dialog__title{
  font-size: 15px;
  padding: 5px;
}
::v-deep .el-dialog__headerbtn {
   margin-top: -10px;
}
</style>
