// 歌曲管理界面
<template>
  <div id="app">
    <urgent></urgent>
    <!-- 按钮部分 -->
    <div class="a1">
      <!-- 返回按钮 -->
      <span class="a2">
        <!-- <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link> -->
      </span>

      <!-- 导入歌曲按钮 -->
      <!-- :before-upload="beforeAvatarUpload" -->
      <span class="a2">
        <el-upload
          class="upload-demo"
          action=""
          multiple
          :http-request="httpRequest"
          :show-file-list="false"
          accept=".MP3,.mp3,.WAV,.WMA,.Flac,.MIDI,.MOV,.aac,.ape,.txt"
        >
          <el-button size="mini" icon="el-icon-plus" @click="upload" round>导入歌曲 </el-button>
        </el-upload>
      </span>

      <!-- 导入歌库按钮 -->
      <!-- <span class="a2">
        <el-button size="mini" icon="el-icon-plus" round>导入歌库</el-button>
      </span> -->

      <!-- 删除歌曲按钮 -->
      <span class="a2">
        <el-button :disabled="state" size="mini" icon="el-icon-close" @click="deleteSongs" round>
          删除歌曲
        </el-button>
      </span>

      <!-- 保存音量按钮 -->
      <!-- <span class="a2">
        <el-button size="mini" icon="el-icon-document" round>
          保存音量
        </el-button>
      </span> -->
    </div>

    <!-- 表格部分 -->
    <div class="a3">
      <template>
        <el-table
          border
          :data="tableData"
          style="line-height: 10px"
          stripe
          @selection-change="handleSelectionChange"
          height="78vh"
        >
          <!-- 选择框列 -->
          <el-table-column align="center" type="selection" width="50"> </el-table-column>

          <!-- 序号列 -->
          <el-table-column
            type="index"
            :index="handleIndex"
            label="序号"
            align="center"
            width="50"
          >
          </el-table-column>

          <!-- 歌曲名称列 -->
          <el-table-column prop="name" show-overflow-tooltip align="center" label="歌曲名称"  width="250px">
          </el-table-column>

          <!-- 占用情况 -->
          <el-table-column
            prop="relationID"
            label="占用情况"
            width="130px"
            align="center"
            :formatter="formatSongRelationID"
          >
            <!-- <template slot-scope="scope">
              <span v-if="scope.row.relationID != 0" style="color: red"></span>
            </template> -->
          </el-table-column>

          <!-- 文件状态 -->
          <el-table-column
            prop="fileExiste"
            label="文件状态"
            width="130"
            align="center"
            :formatter="formSongFileExiste"
          >
          </el-table-column>

          <!-- 播放进度列 -->
          <!-- <el-table-column label="播放进度" width="210px" prop="">
            <template slot-scope="scope">
              <el-slider
                :v-model="scope.row.volume"
                style="width: 150px; margin: auto; float: left"
              >
              </el-slider>
              <span class="a4">
                {{ 100 }}
              </span>
            </template>
          </el-table-column> -->

          <!-- 歌曲长度列 -->
          <el-table-column label="歌曲长度" align="center" width="130px" prop="">
            <template slot-scope="scope">
              <span style="width: 50px; margin: 15px 0">
                {{
                  parseLen(parseInt(scope.row.timeLong.toString() / 60 / 60))
                }}:
                {{
                  parseLen(parseInt((scope.row.timeLong.toString() / 60) % 60))
                }}:
                {{ parseLen(parseInt(scope.row.timeLong.toString() % 60)) }}
              </span>
            </template>
          </el-table-column>

          <!-- 操作列 -->
          <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                :disabled="state"
                round
                @click="handleEdit(scope.$index, scope.row)"
              >
                播放
              </el-button>
              <el-button
                size="mini"
                round
                type="danger"
                :disabled="index!=-1?scope.$index!=index:false"
                @click="handleDelete(scope.$index, scope.row)"
                >停止</el-button
              >
              <el-button
              size="mini"
              type="success"
              round
              @click="handleedit(scope.row, scope.row.id)"
            >修改</el-button>
              <!-- :disabled="scope.$index!=index" -->
            </template>
          </el-table-column>
          <el-dialog
                    title="修改"
                    :visible.sync="record"
                    width="40%"
                    :append-to-body="true"
                    center
                    :close-on-click-modal="false"
                  >
                    <div class="z1">
                      <span class="z2">歌曲名称：</span>
                      <el-input
                        class="z3"
                        placeholder="请输入需要修改的歌曲名称"
                        v-model="filename"
                        clearable
                      >
                      </el-input>
                    </div>
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="startRecord"
                        >确认修改</el-button
                      >
                    </span>
                  </el-dialog>
          <!-- 音量列 -->
          <el-table-column prop="volume" label="音量" width="200">
            <template slot-scope="scope">
              <el-slider
                v-model="scope.row.volume"
                style="width: 130px; margin: auto 5px; float: left"
                @change="changeVolume(scope.$index, scope.row)"
              >
              </el-slider>
              <span style="width: 60px; margin: 20px 5px;">
                {{ scope.row.volume }}
              </span>
            </template>
          </el-table-column>

          <!-- 文件状态 -->
          <!-- <el-table-column prop="fileState" label="文件状态" width="80">
          </el-table-column> -->

          <!-- 工作状态 -->
          <!-- <el-table-column prop="workState" label="工作状态" width="80">
          </el-table-column> -->
        </el-table>
      </template>
    </div>

    <div>
      <!-- :headers="{ 'Authorization': token }" // 在el-upload标签上定义此属性即可 -->
      <!-- token: localStorage.getItem('token') // data里边定义，这是事先将token存在了Storage里边，现在可以直接获取/ -->
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
export default {
  name: "app",
  data() {
    return {
      suffix:'',
      musicid:'',
      musicname:'',
      filename:'',
      record:false,
      tableData: [],
      // 选中复选框的歌曲
      selectMusics: [],
      // 选中复选框的歌曲id
      selectMusicsId: [],

      // 当前歌曲Id
      currentSongId: "",
      // 当前歌曲音量
      currentSongVolume: "",
      timeTaskId:'',
      // 歌曲音量值
      // songValue: 0,

      // 新的文件清单
      // nuwFileList: [],
      index:-1,
      state:false,
      stopstate:false
    };
  },
  components: {},
  mounted() {
    this.getAllSong();
     this.state = JSON.parse(sessionStorage.getItem("musicstate"));
      this.timeTaskId = JSON.parse(sessionStorage.getItem("timeTaskId"));
       this.index = JSON.parse(sessionStorage.getItem("index"));
      if(this.index==null){
        this.index=-1
      }
      window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
  },
  watch: {},
  methods: {
    // 处理表格中的序号格式
    handleIndex(index) {
      return index + 1;
    },
    // 处理时间格式
    parseLen(v) {
      return v > 9 ? v : "0" + v;
    },

    //处理歌曲的占用情况
    formatSongRelationID(row) {
      let r = row.relationID;
      if (r != 0) {
        r = "被占用";
      } else if (r == 0) {
        r = "未使用";
      } else {
        r = "error";
      }
      return r;
    },

    // 处理歌曲文件是否存在的显示情况
    formSongFileExiste(row) {
      let f = row.fileExiste;
      if (f == 0) {
        f = "文件损坏";
      } else if (f == 1) {
        f = "文件正常";
      } else {
        f = "error";
      }
      return f;
    },

    // 选择框---------------------------------------------------------------
    handleSelectionChange(val) {
      // 选中复选框的歌曲
      this.selectMusics = val;
      this.getSelectSongIds();
      // console.log(this.selectMusicsId);
    },

    // 获得选中复选框的歌曲ID-----------------------------------------------------
    getSelectSongIds() {
      this.selectMusicsId = [];
      // 选中复选框的歌曲id
      for (let i in this.selectMusics) {
        this.selectMusicsId.push(this.selectMusics[i].id);
      }
      return;
    },
    upload(){
      console.log(123)
    },
    // 编辑按钮-------------------------------------------------------------------
    handleEdit(index, row) {
      console.log(index, row.id);
       request({
          method: "post",
          url: "/broad/testStart/"+row.id
        })
          .then((res) => {
            this.index=index
            this.timeTaskId=res.data.data
            console.log("试播开始:","index:",index, res, 8868,);
            this.state=true
            sessionStorage.setItem("musicstate", this.state);
            sessionStorage.setItem("timeTaskId", this.timeTaskId);
            sessionStorage.setItem("index",this.index);
      //       this.stopstate=true
      //       if(this.index==6){
      //   this.stopstate=false
      //   console.log(987)
      // }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    startRecord(){
      if (this.filename != "") {
        var list={
        "id":this.musicid,
        "name":this.musicname,
       "updateName":this.filename+this.suffix
        }
        request({
          method: "put",
          url:'updateNmae',
          data:list
        })
          .then((res) => {
            console.log(res, "修改歌名",list);
            if (res.data.resultCode == 200) {
              this.getAllSong();
              this.$message({
                message: "修改成功~",
                type: "success",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("get:”/aregion”接口失败", list);
          });
      } else {
        this.$message({
          message: "歌曲名称不能为空！",
          type: "warning",
        });
      }
      this.filename = "";
      this.record=false
      console.log("stop")
    },
    handleedit(row,id){
      this.record=true
      this.musicname=row.name
      this.suffix=row.name.substring(row.name.lastIndexOf('.'))
      if(row.name==this.suffix){
        this.suffix=''
      }
      this.musicid=id
      console.log(row.name,id,this.suffix)
    },
    // 删除按钮(歌曲列表上的删除按钮)------------------------------------------------
    handleDelete(index, row) {
      console.log(this.timeTaskId)
      
      if(this.index==index){
        request({
          method: "post",
          url: "/broad/testStop/"+this.timeTaskId
        })
          .then((res) => {
            this.state=false
            this.index=-1
            console.log("试播停止:","index:",index, res, 6686);
            sessionStorage.removeItem("musicstate")
            sessionStorage.removeItem("timeTaskId")
            sessionStorage.removeItem("index")
          })
          .catch((err) => {
            console.log(err);
          });
      }
    
      console.log(index, row);
    },

    // 删除歌曲按钮（复选框）--------------------------------------------------------
    deleteSongs() {
      this.delectSongs();
    },

    //限制上传格式-----------------------------------------------------------------
    // beforeAvatarUpload(file) {
    //   console.log(file);
    //   let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
    //   const extension = testmsg === "mp3";
    //   if (!extension) {
    //     this.$message({
    //       message: "上传文件只能是mp3格式！",
    //       type: "error",
    //     });
    //   }
    //   return extension;
    // },

    // 覆盖默认的上传行为，可以自定义上传的实现----------------------------------------
    httpRequest(param) {
      // console.log(param.file);
      // const fileName = param.file.name;
      // 转换格式
      let formData = new FormData();
      formData.append("file", param.file);

      request({
        method: "post",
        url: "/asong/upload",
        data: formData,
      })
        .then((res) => {
          // console.log(res.data.resultMessage);
          // console.log("post:”/asong/upload”接口成功");

          // 新添加的歌曲同步到界面
          this.getAllSong();
          this.hintMessage("上传成功", "success");
        })
        .catch((err) => {
          console.log(err);
          console.log("post:”/asong/upload”接口失败");
          this.$message({
            message: "错了哦，上传失败！",
            type: "error",
          });
        });

      // 处理异步带来的问题
      // new Promise((resolve, reject) => {}).then(() => {});
    },

    // 监听音量的改变----------------------------------------------------------------
    changeVolume(index, row) {
      console.log("当前歌曲的索引：" + index);
      console.log("当前歌曲的ID：" + row.id);
      console.log("当前歌曲的音量：" + row.volume);

      // 获得当前编辑的歌曲Id
      this.currentSongId = row.id;
      // 获得当前编辑的歌曲音量
      this.currentSongVolume = row.volume;

      // 当进度条发生改变，提交到数据库
      this.updateSongVolume();
    },

    // 调用获得全部歌曲接口+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    getAllSong() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          console.log(res);
          this.tableData = res.data.data;
          // console.log("get:”/asong”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/asong”接口失败");
        });
    },
    //调用新增歌曲接口

    //调用批量删除歌曲接口+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    delectSongs() {
      // 判断用户是否已经勾选了复选框
      if (this.selectMusicsId == "") {
        alert("请选择删除要的歌曲！");
      } else {
        request({
          method: "delete",
          url: "/asong/batch",
          data: this.selectMusicsId,
        })
          .then((res) => {
            console.log(res);
            console.log("delete:”/asongs”接口成功");

            if (res.data.resultCode != 200) {
              this.hintMessage(res.data.resultMessage, "error");
            } else {
              // 同步数据库的数据到界面
              this.getAllSong();
              this.hintMessage("删除成功", "success");
            }
          })
          .catch((err) => {
            console.log(err);
            console.log("delete:”/asongs”接口失败");
            this.hintMessage("错了哦！删除不成功", "error");
          });
      }
    },

    //调用更新歌曲音量接口++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    updateSongVolume() {
      //当前歌曲ID，当前歌曲音量
      let sOBJ = { id: this.currentSongId, volume: this.currentSongVolume };
      request({
        method: "put",
        url: "/asong",
        data: sOBJ,
      })
        .then((res) => {
          console.log(res);
          console.log("put:”/asong”接口成功");
          // 同步数据库的数据到界面
          this.getAllSong();
          this.$message({
            message: "成功更改歌曲音量",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("put:”/asong”接口失败");
          this.$message({
            message: "错了哦！更改歌曲音量失败！",
            type: "error",
          });
        });
    },

    // 提示信息--------------------------------------------------------------------------------
    hintMessage(message, type) {
      this.$message({
        message: message,
        type: type,
      });
    },
    beforeunloadHandler() {
            this.beforeUnload_time = new Date().getTime();
        },
        unloadHandler() {
            this._gap_time = new Date().getTime() - this.beforeUnload_time;
            console.log(this._gap_time)
            //判断是窗口关闭还是刷新
            if (this._gap_time <= 5) {
              request({
          method: "post",
          url: "/broad/testStop/"+this.timeTaskId
        })
          .then((res) => {
            // this.state=false
            // console.log("试播停止:","index:",index, res, 6686);
            // sessionStorage.removeItem("musicstate")
            // sessionStorage.removeItem("timeTaskId")
            // sessionStorage.removeItem("index")
          })
          .catch((err) => {
            console.log(err);
          });
                console.log('执行关闭方法')
            }
        }
  },
  destroyed () {
    request({
          method: "post",
          url: "/broad/testStop/"+this.timeTaskId
        })
          .then((res) => {
            // this.state=false
            // console.log("试播停止:","index:",index, res, 6686);
            // sessionStorage.removeItem("musicstate")
            // sessionStorage.removeItem("timeTaskId")
            // sessionStorage.removeItem("index")
          })
          .catch((err) => {
            console.log(err);
          });
    // if(this.state==false){
    //    sessionStorage.removeItem("musicstate")
    //    sessionStorage.removeItem("timeTaskId")
    //    sessionStorage.removeItem("index")
    // }
    sessionStorage.removeItem("musicstate")
    sessionStorage.removeItem("timeTaskId")
    sessionStorage.removeItem("index")
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.removeEventListener('unload', e => this.unloadHandler(e))
  }
};
</script>

<style scoped>
.a1 {
  line-height: 30px;
  width: 600px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}

.a3 {
  line-height: 30px;
}
.a4 {
  display: inline-block;
  color: #945126;
  margin: 7px;
}
.z1 {
  display: flex;
  line-height: 60px;
}
.z2 {
  /* flex: 1;
  text-align-last: justify; */
  width: 70px;
}
.z3 {
  width: 80%;
}
/* .el-main{
  height: 100vh;
} */
</style>
