import Vue from 'vue'
import App from './App.vue'

// import VueRouter from 'vue-router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'
import store from './store/index'
import './utils/dialog'
import { preventReClick }from './preventReClick/preventReClick'
import urgent from "./components/urgent.vue";
import * as echarts from 'echarts'

Vue.prototype.$echarts = echarts

Vue.component('urgent', urgent);

Vue.prototype.$axios = axios;

Vue.use(ElementUI)
Vue.use(VueAxios,axios);
// Vue.use(VueRouter); //挂载属性
// Vue.use(router)

// step2：把axios挂载到vue的原型中，在vue中每个组件都可以使用axios发送请求,
// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = axios
// step3：使每次请求都会带一个 /api 前缀 
// axios.defaults.baseURL = '/api'
Vue.prototype.$preventReClick = preventReClick;

Vue.config.productionTip = false


new Vue({
  render: h => h(App),
  // 在这里挂载等于等于Vue.prototype.$router = router
  router,
  store,
}).$mount('#app')
