<template>
  <div id="app">
    <urgent></urgent>
    <!-- 按钮部分 -->
    <div class="a1">
      <!-- 返回按钮 -->
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <!-- 添加按钮 -->
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" round @click="addUser">
          添加
        </el-button>
      </span>
      <!-- 删除按钮 -->
      <span class="a2">
        <!-- <el-button size="mini" icon="el-icon-close" round @click="deleteUser">
          删除
        </el-button> -->
      </span>
      <!-- 编辑角色权限 -->
      <!-- <span class="a2">
        <el-button size="mini" icon="el-icon-s-tools" round>
          编辑角色权限
        </el-button>
      </span> -->
    </div>

    <!-- 表格部分 -->
    <div class="a3">
      <el-table height="78vh" :data="tableData">
        <!-- 选择框列 -->
        <el-table-column width="25"></el-table-column>
        <!-- 序号列 -->
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="100"
        ></el-table-column>
        <!-- 登录名称 -->
        <el-table-column
          prop="name"
          align="center"
          label="账号"
        ></el-table-column>
        <!-- <el-table-column prop="petName" label="用户名"></el-table-column> -->
        <!-- 角色 -->
        <el-table-column prop="" align="center" label="操作">
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button
              icon="el-icon-edit"
              size="mini"
              @click="handleEdit(scope.row)"
              type="primary"
            >
            </el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row.id)"
            ></el-button>
            <el-button
              size="mini"
              type="success"
              icon="el-icon-view"
              @click="handlecheck(scope.row, scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
        <!-- 权限 -->
        <!-- <el-table-column prop="" label="权限"></el-table-column> -->
      </el-table>
    </div>

    <!-- 弹出的添加用户界面 -->

    <el-dialog
      :title="add == true ? '添加界面' : '修改密码'"
      :visible.sync="isShowAddUserInterface"
      :append-to-body="true"
      width="40%"
      :close-on-click-modal="false"
    >
      <div class="z1">
        <span class="z2">账 号：</span>
        <el-input
          class="z3"
          placeholder="请输入账号"
          v-model="userName"
          :disabled="add == false ? true : false"
          suffix-icon="el-icon-user-solid"
          clearable
          @blur="verifyUserName"
        >
        </el-input>
      </div>

      <!-- <div class="z1">
        <span class="z2"> 用户名：</span>
        <el-input
          class="z3"
          placeholder="请输入用户名"
          v-model="userName"
          clearable
        >
        </el-input>
      </div> -->

      <div class="z1">
        <span class="z2"> 密 码 ：</span>
        <el-input
          class="z3"
          placeholder="请输入修改的密码"
          v-model="passWord"
          show-password
          clearable
        >
        </el-input>
      </div>

      <div style="margin-top: 50px">
        <el-button size="small" @click="cancel" style="margin-right: 10px"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="createUser"
          >保存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="分配音箱"
      :visible.sync="is_show"
      center
      :append-to-body="true"
      :lock-scroll="false"
      width="634px"
      destroy-on-close
      :close-on-click-modal="false"
    >
      <div style="text-align: center">
        <div class="a4">
          <template>
            <!-- :filter-method="filterMethod" -->
            <el-transfer
              filterable
              filter-placeholder="请输入搜索的IP"
              v-model="value"
              :data="sdata"
              :titles="['可选音箱', '已选音箱']"
            >
              <template slot-scope="scope">
                <span>{{ scope.option.label }}</span>
              </template>
            </el-transfer>
          </template>
        </div>
        <div><el-button @click="saveRegion">保存</el-button></div>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
import AddUser from "../../components/system/userManagement/addUser.vue";

export default {
  name: "app",
  data() {
    return {
      userid:'',
      // 表的数据集
      tableData: [],
      // 是否显示添加用户弹窗
      isShowAddUserInterface: false,
      // 用户名
      userName: "",
      // 密码
      passWord: "",
      // 确认密码
      // userId:"",
      addlist: {},
      editlist: {},
      add: "",
      is_show: false,
      allSpeaker: [],
      value: [],
      sdata: [],
      userId: "",
    };
  },
  components: { AddUser },
  mounted() {
    this.getAllUser();
    this.speaker();
  },
  methods: {
    verifyUserName() {
      // 用户名称正则，中文、英文、数字但不包括下划线等符号
      let r = /^[u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (!r.test(this.userName)) {
        this.$message({
          message: "用户名称由“中文”、“英文”、数字组成",
          type: "warning",
        });
        this.userName = "";
      }
    },
    // 添加按钮
    addUser() {
      this.userName = "" 
      this.passWord = ""
      this.isShowAddUserInterface = true;
      this.add = true;
      // this.postUser(newUser);
    },
    // 获得数据库中的所有用户
    getAllUser() {
      request({
        method: "get",
        url: "/auser2",
      })
        .then((res) => {
          this.tableData = res.data.data;
          console.log(res.data.data);
          console.log("get:”/auser”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/auser”接口失败");
        });
    },
    cancel() {
      this.isShowAddUserInterface = false;
    },
    createUser() {
      // this.userName="",
      // this.passWord=""
      if (this.add == true && this.userName != "" && this.passWord != "") {
        let list = {
          name: this.userName,
          password: this.passWord,
          roles: [{ id: 2 }],
        };
        this.addlist = JSON.stringify(list);
        request({
          method: "post",
          url: "/auser2",
          data: this.addlist,
        }).then((res) => {
          console.log("添加成功",res);
          this.isShowAddUserInterface = false;
          console.log(res);
          // this.getAllUser();
          // this.tableData.push(list);
          if (res.data.resultCode == 200) {
            (this.userName = ""), (this.passWord = "");
            // this.tableData.push(list);
            this.getAllUser();
            this.$message({
              message: "添加成功！",
              type: "success",
            });
          }else{
            this.$message({
              message: "添加失败！没有权限！",
              type: "error",
            });
          }
        });
      }
      if (this.add == true && this.userName == "" && this.passWord == "") {
        this.$message({
          message: "选项不能为空！",
          type: "warning",
        });
      }
      if (this.add == false) {
        if (this.userName == "admin") {
          var role = 1;
        } else {
          var role = 2;
        }
        let list = {
          name: this.userName,
          password: this.passWord,
          roles: [{ id: role }],
          id:this.userid
        };
        this.editlist = JSON.stringify(list);
        request({
          method: "put",
          url: "/auser2",
          data: this.editlist,
        }).then((res) => {
          console.log("修改成功");
          this.isShowAddUserInterface = false;
          this.getAllUser();
          console.log(res, list, 4555);
          if (res.data.resultCode == 200) {
            (this.userName = ""), (this.passWord = "");
            this.$message({
              message: "修改成功！",
              type: "success",
            });
          }
        });
        this.userName = "";
      }
    },
    handleDelete(index, id) {
      // console.log(row);
      this.$confirm('是否确认删除该用户？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          request({
        method: "delete",
        url: "/auser2/" + id,
      })
        .then((res) => {
          console.log(res, 2222222222222);
          // 删除一行数据
          if (res.data.resultCode == 200) {
            this.tableData.splice(index, 1);
            this.$message.success("数据删除成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          });          
        });
    },
    handleEdit(row) {
      this.add = false;
      this.isShowAddUserInterface = true;
      this.userName = row.name;
      this.userid=row.id
      console.log(row);
    },
    handlecheck(row, id) {
      this.value=[]
      console.log(row, id,"--------------------------------------------------------------");
      this.userId = id;
      request({
        method: "get",
        url: "/aspeaker/distribute/" + this.userId,
      })
        .then((res) => {
          console.log(res, 456);
          id= res.data.data.map((v) => v.id.toString());
          console.log("查询分配音箱接口成功", this.userId, id);
          this.value=id
          this.is_show = true;
        })
        .catch((err) => {
          console.log(err);
          console.log("查询分配音箱接口失败");
        });
      console.log(123, this.value);
    },
    speaker() {
      request({
        url: "/aspeaker",
      }).then((res) => {
        this.allSpeaker = res.data.data;
        const allSpeakerIP = res.data.data;
        allSpeakerIP.forEach((allSpeaker) => {
          this.sdata.push({
            // label: allSpeaker.devAddr,
            label: allSpeaker.devAddr + "(" + allSpeaker.ip + ")",
            //axios实例已经解决精度损失，这里还要转换成字符串
            key: allSpeaker.id.toString(),
          });
        });
        console.log("所有音箱", this.allSpeaker, this.sdata);
      });
    },
    saveRegion() {
      // let data = [];
      // for (let i = 0; i < this.value.length; i++) {
      //   for (let j = 0; j < this.allSpeaker.length; j++) {
      //     if (this.value[i] == this.allSpeaker[j].id) {
      //       console.log(this.allSpeaker[j]);
      //       data.push(this.allSpeaker[j]);
      //     }
      //   }
      //   console.log(data,99689);
      // }
      let arr = [];
      for (let i = 0; i < this.value.length; i++) {
        var obj = {};
        obj.id = this.value[i];
        arr.push(obj);
      }
      console.log(arr, 8888);
      request({
        method: "put",
        url: "/aspeaker/distribute/" + this.userId,
        data: arr,
      })
        .then((res) => { 
          console.log(res.data.data);
          console.log("分配音箱接口成功", this.userId);
          if (res.data.resultCode == 200) {
            this.$message({
              message: "音箱分配成功！",
              type: "success",
            });
          }
          this.is_show = false;
          this.value=[]
        })
        .catch((err) => {
          console.log(err);
          console.log("分配音箱接口失败");
        });
      console.log(123, this.value);
    },
  },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
  text-align: center;
}
::v-deep .el-dialog__body {
  text-align: center;
}
.a1 {
  line-height: 30px;
  width: 600px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}

.a3 {
  line-height: 30px;
  /* float: left; */
}
.a4 {
  text-align: left;
  /* background-color: rgb(164, 231, 226); */
}
.z1 {
  display: flex;
  line-height: 60px;
}
.z2 {
  /* flex: 1;
  text-align-last: justify; */
  width: 70px;
}
.z3 {
  width: 80%;
}
::v-deep .el-transfer__buttons button:nth-child(1) {
  display: none;
}
::v-deep .el-transfer {
  margin-left: 30px;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
</style>
