<template>
  <div id="app">
    <div class="a1">
      <span class="a2">账 号：</span>
      <el-input
        class="a3"
        placeholder="请输入账号"
        v-model="userName"
        suffix-icon="el-icon-user-solid"
        clearable
        @blur="verifyUserName"
      >
      </el-input>
    </div>

    <div class="a1">
      <span class="a2"> 密 码：</span>
      <el-input
        class="a3"
        placeholder="请输入密码"
        v-model="passWord"
        show-password
        clearable
      >
      </el-input>
    </div>

    <div class="a1">
      <span class="a2"> 确认密码：</span>
      <el-input
        class="a3"
        placeholder="请再次输入密码"
        v-model="verifyPassword"
        show-password
        clearable
      >
      </el-input>
    </div>

    <div style="margin-top: 50px;margin-left: 400px;">
      <el-button size="small" @click="cancel" style="margin-right: 10px;">取消</el-button>
      <el-button size="small" type="primary" @click="createUser">保存</el-button>
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../../network/request";
export default {
  name: "app",
  data() {
    return {
      // 用户名
      userName: "",
      // 密码
      passWord: "",
      // 确认密码
      verifyPassword: "",
    };
  },
  // components: { Input },
  watch: {
    // 监听密码框的值是否合法
    // 监听确认密码框的值是否合法
  },
  methods: {
    // 判断用户名称是否合法(失去焦点时)
    verifyUserName() {
      // 用户名称正则，中文、英文、数字但不包括下划线等符号
      let r = /^[u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (!r.test(this.userName)) {
        this.$message({
          message: "用户名称由“中文”、“英文”、数字组成",
          type: "warning",
        });
        this.userName = "";
      }
    },

    // 判断密码是否合法
    // 确定按钮
    createUser() {
      let data = { id: this.userName, password: this.passWord };

      // 点击确定按钮之前判断密码和确认密码是否一致
      // 用户名称正则，英文、数字、下划线等符号
      // let r = /^[A-Za-z0-9_]+$/;
      // if (!r.test(this.passWord)) {
      //   this.$message({
      //     message: "密码由“英文”、“数字”和“下划线”组成",
      //     type: "warning",
      //   });
      //   this.passWord = "";
      // }

      if (this.passWord == this.verifyPassword) {
        alert("一致");
      } else {
        alert("不一致");
      }

      console.log(this.passWord);
      console.log(typeof this.passWord);

      // request({
      //   method: "post",
      //   url: "/auser",
      //   data: data,
      // })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    },
    cancel(){
       this.$emit("closeDialog", false);
    }
  },
};
</script>

<style scoped>
.a1 {
  display: flex;
  line-height: 60px;
}
.a2 {
  flex: 1;
  text-align-last: justify;
}
.a3 {
  width: 80%;
}

/* 确定按钮 */
/* .el-button--small {
  padding: 12px 40%;
} */
</style>
