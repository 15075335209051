<template>
  <div id="app">
    <ul class="ipAdress">
      <li v-for="(item, index) in ipAdress" :key="index">
        <input
          type="text"
          @input="checkIpVal(item, index)"
          @keyup="turnIpPOS(item, index, $event)"
          v-model:value="item.value"
          ref="ipInput"
          @blur="setDefaultVal(item)"
        />
        <div></div>
      </li>
    </ul>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "IPinput",
  data() {
    return {
      ipAdress: [
        {
          value: "192",
        },
        {
          value: "168",
        },
        {
          value: "2",
        },
        {
          value: "34",
        },
      ],
    };
  },
  methods: {
    checkIpVal(item, index) {
      let self = this;
      //确保每个值都处于0-255
      let val = item.value;
      //当输入的是空格时，值赋为空
      val = val.trim();
      val = parseInt(val, 10);
      if (isNaN(val)) {
        val = "";
      } else {
        val = val < 0 ? 0 : val;
        val = val > 255 ? 255 : val;
      }
      item.value = val;
    },
    turnIpPOS(item, index, event) {
      let self = this;
      let e = event || window.event;
      // console.log(index);
      //删除键把当前数据删除完毕后会跳转到前一个input，左一不做任何处理
      if (e.keyCode == 8) {
        let val = item.value;
        console.log(val);
        if (index == 0) {
        } else if (val == "") {
          self.$refs.ipInput[index - 1].focus();
        }
      }
      //右箭头、回车键、空格键、冒号均向右跳转，右一不做任何措施
      if (
        e.keyCode == 39 ||
        e.keyCode == 13 ||
        e.keyCode == 32 ||
        e.keyCode == 190 ||
        e.keyCode == 110
      ) {
        if (index == 3) {
        } else {
          self.$refs.ipInput[index + 1].focus();
        }
      }
    },
    setDefaultVal(item) {
      //当input失去焦点，而ip没有赋值时，会自动赋值为0
      let self = this;
      let val = item.value;
      if (val == "") {
        item.value = "0";
      }
    },
  },
};
</script>

<style scoped>
.ipAdress {
  display: flex;
  align-items: center;
  /* border: 1px solid #0190FE; */
  border: 1px solid #696969;
  width: 175px;
  margin-right: 10px;
  padding: 0;
}

.ipAdress li {
  position: relative;
  height: 23px;
  margin: 0;
  list-style: none;
}

ul[class="ipAdress"] input[type="text"] {
  border: none;
  width: 100%;
  height: 23px;
  text-align: center;
  background-color: white;
  color: #000;
}

.ipAdress li div {
  position: absolute;
  bottom: 2px;
  right: 0;
  border-radius: 50%;
  /* background: #0190FE; */
  background: #000000;
  width: 3px;
  height: 3px;
}

/*只需要3个div*/
.ipAdress li:last-child div {
  display: none;
}

/*取消掉默认的input focus状态*/
.ipAdress input:focus {
  outline: none;
}
.ipAdress input {
  font-size: 17px;
  font-weight: 600;
}
</style>