//vuex共享组件

import Vue from 'vue'
import Vuex from 'vuex'

// 1.安装插件
Vue.use(Vuex)

// 2.创建对象
const store = new Vuex.Store({
    state:{
        // 当前播放或暂停的音乐索引
        cIndex:-1,
        // 当前播放或暂停的音乐的子任务ID
        cTimeTask:"2",

        // 测试
        aaa:"123"
    },
    mutations:{
        // 改变当前播放或暂停的音乐索引
        updateCIndex(state,index){
            return state.cIndex = index;

        },
        // 改变当前播放或暂停的音乐的子任务ID
        updateCTimeTask(state,timeTaskId){
            return state.cTimeTask = timeTaskId;
        },


        // 测试
        test(state,payload){
            return state.aaa =payload          
        }
    },
    action:{},
    getters:{},
    modules:{},
})

// 3.导出store独享
export default store