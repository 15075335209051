// 系统配置模块
<template>
  <div id="app">
    <urgent></urgent>
    <!-- <HelloWorld msg="Welcome" /> -->
    <div>
      <table class="a1">
        <tr>
          <td>
            <router-link to="/systemConfig" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/SystemConfig1.png"
                  alt=""
                />
                <div slot="item-title-text">系统配置</div>
                <div slot="item-details-text">单位、界面、播放、键盘</div>
              </system-config-botton-item>
            </router-link>
            <!-- <a>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/SystemConfig.png"
                  alt=""
                />
                <div slot="item-title-text">系统配置</div>
                <div slot="item-details-text">单位、界面、播放、键盘</div>
              </system-config-botton-item>
            </a> -->
          </td>
          <td>
            <router-link to="/speakerConfig" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/TerminalConfig.png"
                  alt=""
                />
                <div slot="item-title-text">终端配置</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td>
          <td>
            <router-link to="/RegionConfig" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/PartitionConfig.png"
                  alt=""
                />
                <div slot="item-title-text">分区配置</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td>
        </tr>
        <tr>
          <!-- <td>
            <a>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/ExpansionConfig.png"
                  alt=""
                />
                <div slot="item-title-text">扩容配置</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </a>
          </td> -->
          <td>
            <router-link to="/userManagement" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/UserConfig1.png"
                  alt=""
                />
                <div slot="item-title-text">用户管理</div>
                <div slot="item-details-text">注册、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td>
          <!-- <td>
            <router-link to="/organization" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/zuzhi.png"
                  alt=""
                />
                <div slot="item-title-text">组织管理</div>
                <div slot="item-details-text">注册、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td> -->
          <!-- <td>
            <a>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/CloudUpload.png"
                  alt=""
                />
                <div slot="item-title-text">云端配置</div>
                <div slot="item-details-text">信息上传、修改</div>
              </system-config-botton-item>
            </a>
          </td> -->
          <!-- </tr>
        <tr> -->
          <!-- <td>
            <a>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/FireEquipment.png"
                  alt=""
                />
                <div slot="item-title-text">消防设备</div>
                <div slot="item-details-text">控制、信息查看</div>
              </system-config-botton-item>
            </a>
          </td> -->
          <!-- <td @click="radio" style="cursor: pointer">
            <system-config-botton-item>
              <img
                slot="item-icon"
                src="../../assets/image/system/RadioEquipment1.png"
                alt=""
              />
              <div slot="item-title-text">收音机</div>
              <div slot="item-details-text">控制、信息查看</div>
            </system-config-botton-item>
          </td> -->
          <el-dialog
            title="收音机"
            :visible.sync="dialogVisible"
            width="30%"
            :append-to-body="true"
            :close-on-click-modal="false"
          >
            <!-- <span>
              <el-button round class="btn" style="margin-right: 45px;">开始收音机</el-button>
              <el-button round class="btn">关闭收音机</el-button>
            </span> -->
            <div class="radio">
              <div style="text-align: left" @click="start">
                <div class="start"></div>
                <span>开启收音机</span>
              </div>
              <div style="text-align: right" @click="stop">
                <div class="stop"></div>
                <span>关闭收音机</span>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
          <td>
            <router-link to="/collect" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/InfoBox.png"
                  alt=""
                />
                <div slot="item-title-text">采集配置</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td>
          <!-- <td>
            <router-link to="/wulian" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/wulian.png"
                  alt=""
                />
                <div slot="item-title-text">物联管控</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td> -->
          <!-- <td>
            <router-link to="/port" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/port.png"
                  alt=""
                />
                <div slot="item-title-text">串口透传</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td> -->
          <td>
            <router-link to="/talk" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/talk.png"
                  alt=""
                />
                <div slot="item-title-text">对讲划分</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td>
        </tr>
        <tr>
          <!-- <td>
            <router-link to="/talk" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/talk.png"
                  alt=""
                />
                <div slot="item-title-text">对讲划分</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td> -->
          <td>
            <router-link to="/wulian" replace>
              <system-config-botton-item>
                <img
                  slot="item-icon"
                  src="../../assets/image/system/wulian.png"
                  alt=""
                />
                <div slot="item-title-text">物联管控</div>
                <div slot="item-details-text">添加、修改、删除</div>
              </system-config-botton-item>
            </router-link>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
import SystemConfigBottonItem from "./SystemConfigBottonItem.vue";
export default {
  name: "app",
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {
    SystemConfigBottonItem,
  },
  methods: {
    radio() {
      this.dialogVisible = true;
      console.log(123);
    },
    start(){
      console.log(123)
       request({
        method: "post",
        url: "/exe/start/FM_User",
      })
        .then((res) => {
          console.log("开启收音机");
          console.log(res);
          if (res.data.resultCode == 200) {
            this.$message({
              message: "开启收音机成功！",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    stop(){
      console.log(345)
      request({
        method: "post",
        url: "/exe/stop/FM_User",
      })
        .then((res) => {
          console.log("关闭收音机");
          if (res.data.resultCode == 200) {
            this.$message({
              message: "关闭收音机成功！",
              type: "success",
            });
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}
.a1 {
  /* display: flex; */
  /* background-color: palevioletred; */
  text-align: center;
  /* position: fixed; */
  /* 九宫格 */
  padding: 80px 0px 50px 0px;
  height: 500px;
  /* 六宫格 */
  /* padding: 80px 0px 50px 0px;
  height: 600px; */
}
.btn {
  background-color: #353757;
  color: #fff;
}
::v-deep .el-dialog__header {
  text-align: center;
}
::v-deep .el-dialog__body {
  text-align: center;
}
.radio {
  height: 80px;
  display: flex;
  justify-content: center;
}
.radio span{
  font-size: 13px;
}
.start {
  background-image: url("../../assets/image/start_radio.png");
  background-size: 65px;
  height: 55px;
  width: 65px;
  margin-right: 80px;
  /* border: 3px solid red; */
}
.stop {
  background-image: url("../../assets/image/stop_radio.png");
  background-size: 65px;
  height: 55px;
  width: 65px;
}
</style>
