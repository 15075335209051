// 分区配置按钮界面
<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" @click="addLine" round
          >添加</el-button
        >
      </span>
      <span class="a2">
        <!-- <el-button size="mini" icon="el-icon-close" round>全部删除</el-button> -->
      </span>
      <span class="a2">
        <!-- <el-button size="mini" icon="el-icon-document" round
          >终端详情</el-button
        > -->
      </span>
    </div>
    <div>
      <template>
        <el-table
          :data="tableData"
          style="width: 100%; line-height: 10px"
          height="78vh"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column width="55"> </el-table-column>
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="分区ID：">
                  <span>{{ props.row.id }}</span>
                </el-form-item>
                <el-form-item label="分区名称：">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="该分区下的终端："><span></span>
                  <span v-for="(item, index) in props.row.speakers" :key="index">
                    {{item.devAddr}}({{ item.ip }})、
                  </span>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column label="分区名称" prop="name"> </el-table-column>
          <el-table-column label="寻呼号码" prop="callnum"> </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">

              <!-- 全局控制的编辑 -->
              <div
                v-if="is_edit && scope.row.add == undefined"
                style="display: inline-block"
              >
                <!-- 编辑 -->
                <!-- v-if="!scope.row.edit" -->
                <el-button
                  size="mini"
                  
                  @click="handleEdit(scope.$index, scope.row)"
                  type="primary"
                  >编辑
                </el-button>

                <!-- 保存 -->
                <!-- <el-button
                  size="mini"
                  v-if="scope.row.edit"
                  @click="handleSave(scope.$index, scope.row)"
                  type="success"
                  :plain="true"
                  >保存
                </el-button> -->
              </div>
              <!-- <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              > -->
              <!-- 添加控制 -->
              <div
                v-if="scope.row.add != undefined && scope.row.add"
                style="display: inline-block"
              >
                <!-- 编辑 -->
                <el-button
                  size="mini"
                  v-if="!scope.row.edit"
                  @click="handleEdit(scope.$index, scope.row)"
                  type="primary"
                  >编辑
                </el-button>

                <!-- 保存 -->
                <el-button
                  size="mini"
                  type="success"
                  :plain="true"
                  v-if="scope.row.edit"
                  @click="handleSave(scope.$index, scope.row)"
                  >保存</el-button
                >
              </div>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <div>
      <el-dialog
        v-dialogDrag
        title="编辑界面"
        :visible.sync="is_show"
        center
        :append-to-body="true"
        :lock-scroll="false"
        width="634px"
        :before-close="handleClose"
        destroy-on-close
        :close-on-click-modal="false"
      >
        <add-speaker
          @saved="saved"
          @selectIP="getSelectIP"
          @getRegionName="getRegionName"
          @getRegionCallnum="getRegionCallnum"
          :sendallSpeakerIP="allSpeaker"
          :sendRegionID="regionID"
          :sendRegionName="regionName"
          :sendRegionCallnum="regionCallnum"
          :sendRegionSpeakerID="regionSpeakerID"
        ></add-speaker>
      </el-dialog>
    </div>
    <div></div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
import addSpeaker from "../system/RegionConfigAddSpeaker";

export default {
  name: "app",
  data() {
    return {
      tableData: [],
      //数据结构
      new_date_json: {},
      //是否可编辑
      is_edit: true,
      //是否可删除
      is_delete: true,
      // 是否显示添加音箱的界面
      is_show: false,

      //保存当前编辑的行的索引
      currentIndex: "",
      //当前编辑的分区的id
      regionID: "",
      //当前编辑的分区的名称
      regionName: "",
      //当前编辑的分区的寻呼号码
      regionCallnum:"",
      //当前编辑的分区的已选音箱
      regionSpeakerID: [],
      //从数据库获得的所有音箱
      allSpeaker: [],
      // 当前编辑的分区
      currentRegion: {},

      //当前编辑的行
      editCurrentRow: {},

      createNewLine: "",
      createNewLineIndex: "",
      edit:''
    };
  },
  components: {
    addSpeaker,
  },
  mounted() {
    this.initEditAttribute();
    console.log(this.tableData,78999)
  },
  methods: {
    //初始化编辑属性--------------------------------------
    initEditAttribute() {
      //查询数据库的分区
      request({
        url: "/aregion",
      })
        .then((res) => {
          console.log("获得数据库的所有分区列表:");
          console.log(res.data.data);
          const dataArray = res.data.data;

          if (dataArray.length > 0) {
            //添加编辑事件
            for (const index in dataArray) {
              //axios实例已经解决精度损失，这里还要转换成字符串
              dataArray[index].id = dataArray[index].id.toString();

              dataArray[index]["edit"] = false;
              this.tableData.push(dataArray[index]);
            }
            if (Object.keys(this.new_date_json).length === 0) {
              //新增时，初始化数据结构
              this.initAddDataJson(dataArray[0]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //查询数据库的所有音箱
      request({
        url: "/aspeaker",
      }).then((res) => {
        this.allSpeaker = res.data.data;
        // console.log(this.allSpeaker);
      });
    },

    //新增时，初始化数据结构--------------------------------------------
    initAddDataJson(dataArray) {
      const dataJson = dataArray;
      const newDateJson = {};
      for (const key in dataJson) {
        // console.log(key);
        if (key === "edit") {
          newDateJson[key] = "true";
        } else {
          newDateJson[key] = "";
        }
      }
      newDateJson["add"] = true;
      this.new_date_json = newDateJson;
    },

    //编辑按钮--------------------------------------------------
    handleEdit(index, row) {
      console.log(index, row.callnum,row.edit,row.add,9999999);
      this.edit=row.edit
      // 当前编辑的行
      this.editCurrentRow = row;
      // row.edit = true;
      // console.log("当前索引：" + index);

      // 当前编辑分区的的名称
      this.regionName = row.name;
      this.regionCallnum=row.callnum.slice(1)
      // 获得当前编辑的分区的id
      this.regionID = row.id;

      // 编辑行只能有一行
      // for (let i = 0; i < this.tableData.length; i++) {
      //   if (this.tableData[i].edit) {
      //     this.$message.error("错了哦，先保存其它分区再编辑");
      //     return;
      //   }
      // }
      // 设置是否可编辑（即显示"保存"按钮）
      //弹出编辑分区界面
  

      //获得当前分区的终端
      //获得当前编辑的分区

        request({
          url: "/aregion/" + row.id,
        }).then((res) => {
          // 获得当前分区对应的音箱集合
          const regionSpeakers = res.data.data.speakers;
          this.is_show = true;
          row.edit = true;
          // 获得当前分区对应的音箱的id集合
          this.regionSpeakerID = regionSpeakers.map((v) => v.id.toString());
          console.log("音箱的id集合:",res,this.regionSpeakerID);

          //获得当前编辑的分区
          this.currentRegion = res.data.data;
          console.log(this.currentRegion,996);
        });
      
    },

    //保存按钮-----------------------------------------
    handleSave(index, row) {
      row.edit = false;

      // // 提交到数据库
      // if (this.regionID) {
      //   // 调用更新接口
      //   this.updateARegion();
      // } else {
      //   // 调用添加接口
      //   this.addARegion(index);
      // }
    },

    //保存按钮---------------------------------------------
    saved(data) {
      // alert(data);
      // this.handleSave();
      console.log(data); //不能删

      //关闭弹框界面
      this.is_show = false;

      // console.log(this.tableData[this.tableData.length - 1].edit);
      //设置当前行的编辑状态
      // this.editCurrentRow.edit = false;

      //设置当前行的编辑状态
      if (this.editCurrentRow.edit) {
        this.editCurrentRow.edit = false;
      } else if (
        this.editCurrentRow.edit == false ||
        this.editCurrentRow.edit == undefined
      ) {
        // 如果当前行是undefined，那么，是新添加的行 将表格最后一行赋给当前行
        this.editCurrentRow = this.tableData[this.tableData.length - 1];
        this.editCurrentRow.edit = false;
      } else {
        alert("注意");
      }

      // 提交到数据库
      if (this.regionID) {
        // 调用更新接口
        this.updateARegion();
      } else {
        // 调用添加接口
        this.addARegion();
      }

      // 初始化RegionConfigAddSpeaker.vue组件
      this.regionID = ""; //当前编辑的分区的id
      this.regionName = ""; //当前编辑的分区的名称
      this.regionCallnum="";
      this.regionSpeakerID = []; //当前编辑的分区的已选音箱
    },

    //删除按钮-----------------------------------------
    handleDelete(index, row) {
      console.log(index, row,999999999);
      console.log(row.id,6666666666);

      // 分区ID
      const regionId = row.id;

      // 判断该分区是否音箱
      // let rSpeakers = "";
      // if (row.speakers !== undefined) {
      //   this.rSpeakers = row.speakers;
      // } else {
      //   this.rSpeakers = "";
      // }

      request({
        method: "delete",
        url: "/aregion/" + regionId,
        // data: "", //+++++++++++++++++++++++++++++++++++++++++++++++++++++
        // data: rSpeakers,
      })
        .then((res) => {
          console.log(res.data.resultCode,122222222222222222222222222222222222222222222222222222222);
          // 删除一行数据
          if(res.data.resultCode==200){
               this.tableData.splice(index, 1);
               this.$message.success('数据删除成功')
          }else{
            this.$message.error(res.data.resultMessage)
          }
   
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRegionCallnum(data){
      this.currentRegion.callnum=data;
        for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == this.currentRegion.id) {
          var callnum=this.currentRegion.callnum
          // if(this.currentRegion.callnum!=''&&callnum.substring(0,1)!=1){
          if(this.currentRegion.callnum!=''){
            this.currentRegion.callnum=1+this.currentRegion.callnum}
          this.tableData[i].callnum = this.currentRegion.callnum;
          break;
        }
        //新增的分区
        if (this.tableData[i].id == "") {
          this.tableData[i].callnum = this.currentRegion.callnum;
        }
      }
    },
    //获得子组件编辑的分区名称---------------------------------
    getRegionName(data) {
      // 更改当前分区的名称
      this.currentRegion.name = data;
      // 同步表格的分区名称
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == this.currentRegion.id) {
          this.tableData[i].name = this.currentRegion.name;
          break;
        }
        //新增的分区
        if (this.tableData[i].id == "") {
          this.tableData[i].name = this.currentRegion.name;
        }
      }
      // console.log(this.tableData);
    },

    //获得子组件选中的终端IP---------------------------------
    getSelectIP(data) {
      this.currentRegion.speakers = data;
      // 同步表格的终端集合
      // for (let i = 0; i < this.tableData.length; i++) {
      //   if (this.tableData[i].id == this.currentRegion.id) {
      //     this.tableData[i].speakers = this.currentRegion.speakers;

      //     break;
      //   }
      //   //新增的分区
      //   if (this.tableData[i].id == "") {
      //     this.tableData[i].speakers = this.currentRegion.speakers;
      //   }
      // }
    },

    // 调用put方法更改当前分区--------------------------------------------
    updateARegion() {
      // 通过子组件获得选中终端的IP，提交到数据库
      request({
        method: "put",
        url: "/aregion/speakers",
        data: this.currentRegion,
      })
        .then((res) => {
          console.log("put:”/aregion”接口成功");
          console.log(res,66996);
          for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].id == this.currentRegion.id) {
          this.tableData[i].speakers = this.currentRegion.speakers;

          break;
        }
        //新增的分区
        if (this.tableData[i].id == "") {
          this.tableData[i].speakers = this.currentRegion.speakers;
        }
      }
          if (res.data.resultCode == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
            });
          }
          else{
            this.$message({
            message: res.data.resultMessage,
            type: "error",
          });
          }
        })
        .catch((err) => {
          console.log("put:”/aregion”接口失败！：");
          console.log(err);
          // alert("调用接口失败，编辑不成功，请刷新");
        });
    },
    //调用post方法添加新的分区
    addARegion() {
      console.log(this.currentRegion);
      // let that = this;
      request({
        method: "post",
        url: "/aregion/speakers",
        data: this.currentRegion,
      })
        .then((res) => {
          console.log("post:”/aregion”接口成功",this.tableData,this.currentRegion);
          console.log(res);
          // 新添加的分区，从返回值中获取分区id
          // this.regionID = res.data.data.id;
          // this.tableData[index].id = res.data.data.id;

          // 刷新页面，首次添加分区，this.currentRegion对象中没有"id"属性
          if (this.currentRegion.id == undefined) {
            this.$set(this.currentRegion, "id", res.data.data.id);
          } else {
            this.currentRegion.id = res.data.data.id;
          }

          if (res.data.resultCode == 200) {
            this.$message({
              message: "添加成功！",
              type: "success",
            });
          }else{
            this.$message({
            message: res.data.resultMessage,
            type: "error",
          });
          }
        })
        .catch((err) => {
          console.log("posy:”/aregion”接口失败！：");
          console.log(err);

          this.$message({
            message: "调用接口失败，不能添加分区",
            type: "error",
          });

          // 添加分区不成功，则删除最后一行数据
          // this.tableData.splice(this.tableData.length - 1, 1);
          if(!this.edit){
        this.tableData.splice(this.tableData.length - 1, 1);
      }
        });
    },

    // 关闭编辑分区界面之前做判断-------------------------------------------
    handleClose(done) {
      console.log(done);
      // if (
      //   this.currentRegion.name == "" ||
      //   this.currentRegion.name == undefined
      // ) {
      //   this.is_show = true;
      // } else {
      //   this.is_show = false;
      // }
      //  关闭对话框
      this.is_show = false
      // 添加分区不成功，则删除最后一行数据
      if (
        this.editCurrentRow.edit == false ||
        this.editCurrentRow.edit == undefined
      ){
        this.tableData.splice(this.tableData.length - 1, 1);
      }
  // ----------------------------------------------------------------------------------------------
    //  原
     // if(this.edit){
      //   this.tableData.splice(this.tableData.length - 1, 1);
      // }
      // this.tableData.splice(this.tableData.length - 1, 1);
      // console.log(this.currentRegion.name);
      // console.log(this.currentRegion.id);
    },

    // 添加新分区-------------------------------------------
    addLine() {
      this.editCurrentRow.edit=false
      this.regionName = ""; //当前编辑的分区的名称
      this.regionCallnum="";
      this.regionSpeakerID=[]
      const addDataJson = {};
      for (const key in this.new_date_json) {
        // console.log(key);
        if (key === "edit") {
          delete addDataJson[key];
        } else if (key === "add") {
          delete addDataJson[key];
        } else {
          addDataJson[key] = "";
        }
      }

      addDataJson.edit = true;
      addDataJson.add = true;

      this.is_show = true;

      // 将新添加的分区赋值给当前编辑的分区
      this.currentRegion = addDataJson;

      this.tableData.push(addDataJson);

      console.log(addDataJson);
      console.log(this.tableData,998);

      // 保存行只能有一行
      // for (let i = 0; i < this.tableData.length; i++) {
      //   if (this.tableData[i].add) {
      //     this.$message.error("错了哦，先保存其它分区再添加");
      //     return;
      //   }
      // }
    },
  },
};
</script>

<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

.a1 {
  line-height: 30px;
  width: 400px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
</style>
