<template>
  <div id="app" class="a1">
    <div class="a2">
      <!-- <el-button>Login</el-button> -->
      <div class="a3">账号登录</div>

      <!-- 账号 -->
      <div class="a4">
        <el-input
          v-model="userName"
          placeholder="请输入用户账号"
          clearable
          prefix-icon="el-icon-user"
          maxlength="9"
          show-word-limit
        ></el-input>
      </div>
      <!-- 密码 -->
      <div class="a4">
        <el-input
          v-model="password"
          placeholder="请输入用户密码"
          show-password
          clearable
          prefix-icon="el-icon-lock"
        ></el-input>
      </div>
      <!-- 登录 -->
      <div class="a5">
        <el-button class="a5-1" @click="login">登录</el-button>
      </div>

      <!-- 取消 -->
      <!-- <div class="a6">
        <button class="a6-1">取消</button>
      </div> -->
    </div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
export default {
  name: "app",
  data() {
    return {
      // 账号
      userName: "",
      // 密码
      password: "",
    };
  },
  components: {},
  created() {

    var t = this;

    document.onkeydown = function (e) {

      if (window.event == undefined) {

        var key = e.keyCode;

      } else {

        var key = window.event.keyCode;

      }

      //enter的ASCII码是13

      if (key == 13) {

        t.login(); //t.登录方法

      }

    };

  },
  methods: {
    login() {
      const data = { name: this.userName, password: this.password };
      request({
        method: "post",
        url: "/login2",
        data: data,
      })
        .then((res) => {
          // console.log(res.data.data.role.id,'============');
          if (res.data.resultCode == 200) {
            var id=res.data.data.role.id
          sessionStorage.setItem("webket", id);
            // 获得token
            console.log(res.data.data.token);
            // 存token到sessionStorage
            sessionStorage.setItem(
              "token",
              JSON.stringify(res.data.data.token)
            );
            //  this.$message({
            //   message: '登陆成功',
            //   type: "success",
            // });
            // 跳转页面
            // this.$router.replace("/");
            // 成功
            this.$router.push({ path: "/system" });
            // 失败
            // this.$router.push({ path: "/login" });
          } else {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
/* 背景 */
.a1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: -10;
  zoom: 1;
  background-color: rgb(32, 30, 30);
  background-color: rgba(rgb(180, 156, 156), green, blue, 0.5);
  background: url(../../assets/image/login/background.png) no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  /* 透明度 */
  /* opacity: 0.9; */
}

/* 账号登录模块 */
.a2 {
  /* 透明度 */
  /* opacity: 1; */

  width: 485px;
  height: 300px;

  /* background-color: #64718d; */
  /* 改变背景透明度而不改变文字的透明度，必须要用CSS3的rgba */
  background-color: rgba(104, 105, 103, 0.15);

  /* 绝对定位 */
  position: relative;

  /* 居中 */
  text-align: center;
  margin: 200px auto;

  /* 圆角 */
  border-radius: 10px;
  /* 基础投影 */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0 6px rgba(0, 0, 0, 0.3);
}

/* “账号登录”标题 */
.a3 {
  font-size: 30px;
  line-height: 60px;
  /* 字体颜色 */
  color: rgb(255, 255, 255);
}

/* 账号/密码 */
.a4 {
  margin: 20px 80px;
  /* 基础投影 */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0 6px rgba(0, 0, 0, 0.3);
  color: #000;
}

/* 登录按钮 */
.a5 {
  margin: 20px 80px;
  background-color: rgb(143, 133, 120);
  /* 基础投影 */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0 6px rgba(0, 0, 0, 0.3);
}
.a5-1 {
  width: 100% !important;
  /* 渐变效果 */
  background-image: linear-gradient(to right, #6060fc, #5a8ff7);
  color: #fff;
  /* font-size: 18px; */
  /* 字体间隔 */
  letter-spacing: 10px;
}

/* 取消 */
.a6 {
  /* background-color: burlywood; */
  float: right;
  margin-right: 20px;
}
.a6-1 {
  font-size: 12px;
  background: #ffffff00 !important;
  color: #fff;
  border: none;
}

/* 输入框 */
.el-input >>> .el-input__inner {
  /* 方法一： */
  background: transparent !important;

  /* 方法二：  */
  background: #ffffff00 !important;
  /* 方法三：  */
  background-color: rgba(104, 105, 103, 0.01) !important;
  /* 输入的字体颜色 */
  color: #fff;
}

/* 输入长度限制 */
.el-input >>> .el-input__count .el-input__count-inner {
  background: transparent !important;
}
</style>
