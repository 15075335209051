// 时钟模板

<template>
  <div id="app">
    <span id="cg"></span>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      clearId: "",
    };
  },
  // mounted() {
  //   this.clearId = setInterval(
  //     "cg.innerHTML=new Date().toLocaleString()",
  //     1000
  //   );
  // },
  method: {},

  // beforeRouteLeave(to, from, next) {
  //   clearInterval(this.clearId);
  //   next();
  // },
  activated() {
    this.clearId = setInterval(
      "cg.innerHTML=new Date().toLocaleString()",
      1000
    );
  },
  deactivated() {
    clearInterval(this.clearId);
  },

  // 在离开组件的时候，清除定时器
  // destroyed() {
  //   clearInterval(this.clearId);
  // },
};
</script>

<style>
</style>