// 配置路由相关的信息
import VueRouter from 'vue-router'
import Vue from 'vue'

import System from '../components/system/System.vue'
import Home from '../components/home/Home.vue'
import Check from '../components/check/Check.vue'
import Test from '../components/test.vue'
import Test001 from '../components/test001.vue'
import Music from '../components/music/Music.vue'
import Direct from '../components/direct/direct.vue'
import Plan from '../components/plan/Plan.vue'
import SystemConfig from '../components/system/SystemConfig.vue'
import SpeakerConfig from '../components/system/SpeakerConfig.vue'
import RegionConfig from '../components/system/RegionConfig.vue'
import SubtaskD from '../components/direct/subtaskD.vue'
import RegionConfigAddSpeaker from '../components/system/RegionConfigAddSpeaker.vue'
import SubtaskP from '../components/plan/subtaskP.vue'
import transcribe from'../components/transcribe/transcribe.vue'
import Login from '../components/login/login.vue'
import UserManagement from '../components/system/userManagement.vue'
import Collect from '../components/system/collect.vue'
import Organization from '../components/system/organization.vue'
import Port from '../components/system/port.vue'
import Talk from '../components/system/talk.vue'
import Wulian from '../components/system/wulian.vue'
import Tf from '../components/system/tf.vue'



// 懒加载的写法
// const RegionConfigAddSpeaker  =() => import('../components/system/RegionConfigAddSpeaker.vue') 

// 通过Vue.use(插件)，来安装插件
Vue.use(VueRouter)
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

// 创建VueRouter对象
const routes =[
     //一个个对象
        // { path: './components/index', component:index }

        //路由的默认路径
        {
            path:'/',
            redirect:'/login'
        },
        {
            path:'/home',
            component:Home,
            children:[
                {
                    path:'/direct',
                    component:Direct
                },
                {
                    path:'/plan',
                    component:Plan
                },
                {
                    path:'/system',
                    component:System,
                },
                {
                    path:'/systemConfig',
                    component:SystemConfig
                },
                {
                    path:'/check',
                    component:Check
                },
                {
                    path:'/music',
                    component:Music
                },
                {
                    path:'/speakerConfig',
                    component:SpeakerConfig

                },
                {
                    path:'/regionConfig',
                    component:RegionConfig
                },
                {
                    path:'/transcribe',
                    component:transcribe

                },
                {
                    path:'/userManagement',
                    component:UserManagement
                },
                {
                    path:'/collect',
                    component:Collect
                },
                {
                    path:'/organization',
                    component:Organization
                },
                {
                    path:'/port',
                    component:Port
                },
                {
                    path:'/talk',
                    component:Talk
                },
                {
                    path:'/wulian',
                    component:Wulian
                },
                {
                    path:'/tf',
                    component:Tf
                },
            ]
        },
        {
            path:'/subtaskD',
            name:"subtaskD",
            component:SubtaskD
            
        },
        {
            path:'/subtaskP',
            component:SubtaskP
        },
        // 分区添加终端的弹框界面
        {
            path:'/addSpeakers',
            component:RegionConfigAddSpeaker
        },
        // 登录
        {
            path:"/login",
            component:Login
        },
        // 测试
        {
            path:'/test',
            component:Test
        },
        {
            path:'/test001',
            component:Test001
        }
  
]

const router=new VueRouter({
    // 配置路由和组件之间的关系
    // history模式，默认是哈希模式#
    routes,
    mode:'history',
    // base: '/dist/'	//这个必配，是index.html所在的路径地址 打包必配，不打包要注释掉
    // mode:"hash" 
})

// 将router对象传入到Vue实例
export default router