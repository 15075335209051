// 分区配置按钮界面
<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" @click="addLine()" round
          >添加</el-button
        >
        <el-button size="mini" icon="el-icon-plus" @click="addUrgent()" round
          >紧急采集</el-button
        >
      </span>
      <!-- <el-tooltip placement="top">
        <div slot="content">多行信息<br/>第二行信息</div>
        <i class="el-icon-question"></i>
    </el-tooltip> -->
    </div>
    <div>
      <template>
        <el-table
          :data="tableData"
          style="width: 100%; line-height: 10px"
          height="78vh"
          border
        >
          <el-table-column label="选择" align="center" width="65">
            <template scope="scope">
               <el-radio :label="scope.$index" v-model="radio" @change.native="getRow(scope.row,scope.$index)">
                  <i></i>
               </el-radio>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" type="index" width="100">
          </el-table-column>
          <el-table-column label="IP地址" align="center" prop="ip" width="200">
          </el-table-column>
          <el-table-column label="名称" align="center" prop="name" width="200">
          </el-table-column>
          <el-table-column
            label="端口号"
            align="center"
            prop="port"
            width="200"
          >
          <!-- <template slot-scope="scope">
              <span v-if="scope.row.port == '666'">本机声卡端口</span>
              <span v-if="scope.row.port == '0'">服务器声卡端口</span>
              <span v-if="scope.row.port == '-1'">混音端口</span>
              <span v-if="scope.row.port == '13007'">全天候信息盒端口</span>
            </template> -->
          </el-table-column>
          <el-table-column label="类型" align="center" prop="cType" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.cType == '-2'">分控端声卡</span>
              <span v-if="scope.row.cType == '-1'">服务器声卡</span>
              <span v-if="scope.row.cType == '0'">无音源</span>
              <span v-if="scope.row.cType == '2'">前级</span>
              <span v-if="scope.row.cType == '3'"> 全天候信息盒</span>
              <span v-if="scope.row.cType == '4'"> 多路编码</span>
              <span v-if="scope.row.cType == '8'"> 音箱</span>
              <span v-if="scope.row.cType == '9'"> 功放机</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="200" label="操作">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                size="mini"
                @click="handleEdit(scope.row)"
                type="primary"
                >编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>

    <div>
      <el-dialog
        v-dialogDrag
        :title="add == true ? '添加界面' : '编辑界面'"
        :visible.sync="is_show"
        center
        :append-to-body="true"
        :lock-scroll="false"
        width="540px"
        :before-close="handleClose"
        :close-on-click-modal="false"
      >
        <div class="a3">
          <span class="a5">名称 :</span>
          <el-input
            class="a4"
            style="margin-left: 13px"
            placeholder="请输入名称"
            v-model="input"
            minlength="2"
            maxlength="10"
            show-word-limit
            v-focus
            clearable
          ></el-input>
        </div>
        <div class="a3">
          <span class="a5">ip地址 :</span>
          <el-input
            class="a4"
            placeholder="请输入ip地址"
            v-model="input1"
            clearable
          ></el-input>
        </div>
        <div class="a3">
          <span class="a5">类型 :</span>
          <el-select
            v-model="value"
            class="a4"
            style="margin-left: 13px"
            placeholder="请选择"
            @change="typeport"
          >
            <el-option label="分控端声卡" value="-2"></el-option>
            <el-option label="服务器声卡" value="-1"></el-option>
            <el-option label="无音源" value="0"></el-option>
            <el-option label="前级" value="2"></el-option>
            <el-option label="全天候信息盒" value="3"></el-option>
            <el-option label="多路编码" value="4"></el-option>
            <!-- <el-option label=" 音箱" value="8"></el-option> -->
            <!-- <el-option label="功放机" value="9"></el-option> -->
          </el-select>
        </div>
        <div class="a3">
          <span class="a5">端口号 :</span>
          <el-input
            class="a4"
            placeholder="请输入端口号"
            v-model="input2"
            clearable
          ></el-input>
          <!-- <el-select
            v-model="input2"
            class="a4"
            placeholder="请选择端口"
          >
            <el-option label="本机声卡端口" value="666"></el-option>
            <el-option label="服务器声卡端口" value="0"></el-option>
            <el-option label="混音端口" value="-1"></el-option>
            <el-option label="全天候信息盒端口" value="13007"></el-option>
          </el-select> -->
        </div>
        <div class="a3" style="text-align: center; margin-top: 30px">
          <el-button
            style="margin-left: 280px; margin-right: 20px"
            @click="cancel"
            round
            >取消</el-button
          >
          <el-button type="primary" @click="saved" round>确认</el-button>
        </div>
      </el-dialog>
    </div>
    <div></div>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      tableData: [],
      is_show: false,
      input: "",
      input1: "",
      input2: "",
      value: "",
      typeArr: [
        {
          value: "-2",
          label: " 分控端声卡",
        },
        {
          value: "-1",
          label: "服务器声卡",
        },
        {
          value: "0",
          label: "无音源",
        },
        {
          value: "2",
          label: "前级",
        },
        {
          value: "3",
          label: "全天候信息盒",
        },
        {
          value: "4",
          label: "多路编码",
        },{
          value: "8",
          label: "音箱",
        },{
          value: "9",
          label: "功放机",
        },
      ],
      add: "",
      addlist: {},
      edit: {},
      editId: "",
      editlist: {},
      cType: "",
      radio:'',
      urgentID:''
    };
  },
  directives: {
    //注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  mounted() {
    this.initEditAttribute();
     this.radio = JSON.parse(sessionStorage.getItem("radio"));
     if (sessionStorage.getItem("urgentID") != null){
      this.urgentID=sessionStorage.getItem("urgentID")
     }
  },
  watch: {
    tableData(newVal,oldVal){
      console.log(newVal,oldVal,'||||||',newVal.length)
      if(newVal.length==0){
        sessionStorage.removeItem("radio")
        sessionStorage.removeItem("urgentID")
        this.radio=''
        this.urgentID=''
      }
    }
  },
  methods: {
    typeport(){
      if(this.value==-2){
        this.input2=138
      }else if(this.value==-1){
        this.input2=0
      }else if(this.value==0){
        this.input2=0
      }else if(this.value==2){
        this.input2=13007
      }else if(this.value==3){
        this.input2=13007
      }else if(this.value==4){
        this.input2=-1
      }
      // console.log(123)
    },
    collect() {
      //查询采集配置
      request({
        url: "/acollect",
      })
        .then((res) => {
          console.log("获得数据库的所有分区列表:");
          console.log(res.data.data, 999);
          (this.tableData = res.data.data), 999;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //初始化编辑属性--------------------------------------
    initEditAttribute() {
      //查询采集配置
      request({
        url: "/acollect",
      })
        .then((res) => {
          console.log("获得数据库的所有分区列表:");
          console.log(res.data.data);
          const dataArray = res.data.data;

          if (dataArray.length > 0) {
            //添加编辑事件
            for (const index in dataArray) {
              //axios实例已经解决精度损失，这里还要转换成字符串
              dataArray[index].id = dataArray[index].id.toString();

              dataArray[index]["edit"] = false;
              this.tableData.push(dataArray[index]);
            }
            if (Object.keys(this.new_date_json).length === 0) {
              //新增时，初始化数据结构
              this.initAddDataJson(dataArray[0]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addLine() {
      this.is_show = true;
      this.add = true;
    },
    addUrgent(){
      if(this.urgentID!=''){
        request({
          method: "put",
        url: "/config/emergency/"+this.urgentID,
      })
        .then((res) => {
          if (res.data.resultCode == 200) {
            this.$message.success("成功添加为紧急采集");
          } else {
            this.$message.error(res.data.resultMessage);
          }
          console.log("紧急广播id:",res,8868);
        })
        .catch((err) => {
          console.log(err);
        });
      }
      console.log(this.urgentID,8868)
    },
    handleClose() {
      this.is_show = false;
      this.input1 = "";
      this.input2 = "";
      this.value = "";
      this.input = "";
    },
    getRow(row,index){
      console.log(row,index,8868)
      this.urgentID=row.id
      this.radio=index
      sessionStorage.setItem("radio", index)
      sessionStorage.setItem("urgentID", this.urgentID)
    },
    handleDelete(index, id) {
      console.log(index, id);
      request({
        method: "delete",
        url: "/acollect/remove/" + id,
        // data: "", //+++++++++++++++++++++++++++++++++++++++++++++++++++++
        // data: rSpeakers,
      })
        .then((res) => {
          console.log(res.data.resultCode, 2222222222222);
          // 删除一行数据
          if (res.data.resultCode == 200) {
            this.tableData.splice(index, 1);
            this.$message.success("数据删除成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleEdit(row) {
      this.add = false;
      this.is_show = true;
      if (this.add == false) {
        this.input1 = row.ip;
        this.input2 = row.port;
        // if(row.port==0){
        //   this.input2="服务器声卡端口"
        // }else if(row.port==666){
        //   this.input2="本机声卡端口"
        // }else if(row.port=='-1'){
        //   this.input2="混音端口"
        // }else if(row.port=='13007'){
        //   this.input2="全天候信息盒端口"
        // }
        if (row.cType == -2) {
          this.value = "分控端声卡";
        } else if (row.cType == -1) {
          this.value = "服务器声卡";
        } else if (row.cType == 0) {
          this.value = "无音源";
        } else if (row.cType == 2) {
          this.value = "前级";
        } else if (row.cType == 3) {
          this.value = "全天候信息盒";
        }else if (row.cType == 4) {
          this.value = "多路编码";
        }else if (row.cType == 8) {
          this.value = "音箱";
        }else if (row.cType == 9) {
          this.value = "功放机";
        }
        this.input = row.name;
        this.editId = row.id;
        this.cType = row.cType;
      }
      console.log(row, 6696);
    },
    saved() {
      if (this.add == true) {
        var list = {
          ip: this.input1,
          port: this.input2,
          cType: this.value,
          name: this.input,
        };
        // this.selectlistRow=list
        this.addlist = JSON.stringify(list);
        console.log(
          this.addlist,
          999999999999999999999999999999999999999999999999
        );
        //  this.tableData[this.tableData.length -1]=list;
        request({
          method: "post",
          url: "/acollect/insert",
          data: this.addlist,
        }).then((res) => {
          console.log("添加成功");
          console.log(res);
          this.tableData.push(res.data.data);
          if (res.data.resultCode == 200) {
            this.$message({
              message: "添加成功！",
              type: "success",
            });
          }
        });
        console.log(list);
        this.is_show = false;
        this.input1 = "";
        this.input2 = "";
        this.value = "";
        this.input = "";
      }
      if (this.add == false) {
        if(this.input2=="服务器声卡端口"){
          this.input2=0
        }else if(this.input2=="本机声卡端口"){
          this.input2=138
        }else if(this.input2=="混音端口"){
          this.input2='-1'
        }else if(this.input2=="全天候信息盒端口"){
          this.input2='13007'
        }
         if (this.value == "分控端声卡") {
          this.value = -2;
        } else if (this.value == "服务器声卡") {
          this.value = -1;
        } else if (this.value == "无音源") {
          this.value = 0;
        } else if (this.value == "前级") {
          this.value = 2;
        } else if (this.value == "全天候信息盒") {
          this.value = 3;
        }else if (this.value == "多路编码") {
          this.value = 4;
        }else if (this.value == "音箱") {
          this.value = 8;
        }else if (this.value == "功放机") {
          this.value = 9;
        }
        var list = {
          id: this.editId,
          ip: this.input1,
          port: this.input2,
          cType: this.value,
          name: this.input,
        };
        // this.selectlistRow=list
        this.editlist = JSON.stringify(list);
        console.log(this.editlist, this.value, 99995);
        //  this.tableData[this.tableData.length -1]=list;
        request({
          method: "put",
          url: "/acollect/updateOne",
          data: this.editlist,
        }).then((res) => {
          console.log("修改成功", this.editlist, this.typeArr.value);
          console.log(res);
          this.collect();
          this.input1 = "";
          this.input2 = "";
          this.value = "";
          this.input = "";
          // this.tableData.push(list);
          if (res.data.resultCode == 200) {
            this.$message({
              message: "修改成功！",
              type: "success",
            });
          }
        });
        this.is_show = false;
      }
    },
    cancel() {
      this.is_show = false;
    },
  },
};
</script>

<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
::v-deep .el-dialog__body {
  padding-bottom: 10px;
}
.a1 {
  line-height: 30px;
  width: 400px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
.a3 {
  text-align: center;
  width: 100%;
  /* background-color: peachpuff; */
  /* line-height: 80px; */
  margin-bottom: 20px;
}
.a4 {
  width: 300px;
}
.a5 {
  margin: 20px;
  /* background-color: rgb(164, 231, 226); */
}
.el-icon-question{
  position: absolute;
  margin-top: 5px;
  right: 30px;
}
</style>
