<template>
    <div>
      <el-dialog
      title="紧急广播"
      :visible.sync="dialogVisible"
      :append-to-body="true"
      width="85%"
      style="margin-top: -8vh;"
      :showClose="false"
      :close-on-click-modal="false"
    >
      <div class="b1" style="margin-top: -20px">
        <!-- #c27efa; -->
        <!-- @click="allselect(1)" -->
        <!-- <div
          class="b2"
          :class="active == -1 ? 'b3' : 'b2'"
          :id="allSelect == true ? 'b4' : ''"
          v-for="item in this.allzone"
          :key="item.id"
        >
          {{ item.name }}
        </div> -->
        <div
          class="b2"
          :class="index == active ? 'b3' : 'b2'"
          :id="gather.indexOf(item.id) > -1 ? 'b4' : ''"
          v-for="(item, index) in this.allZones"
          :key="index"
          @click.stop="a(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div>
                <el-slider
                  height="300px"
                  v-model="miVol"
                  class="a4"
                  :step="1"
                  @change="changeMiVol"
                >
                </el-slider>
              </div>
    </el-dialog> 
    <el-dialog
    :showClose="false"
    :close-on-click-modal="false"
    :append-to-body="true"
  title="触发弹窗"
  :visible.sync="centerDialogVisible"
  width="30%"
  center>
  <div style="text-align: center;font-size: 20px;">
    <span>{{showInfo}}</span>
  </div>
  <span slot="footer" class="dialog-footer">
    <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
    <el-button size="mini" type="primary" @click="centerDialogVisible = false">关闭</el-button>
  </span>
</el-dialog>
    </div>  
</template>

<script>
import { request } from "@/network/request";
export default {
  name: "app",
  data() {
    return {
      miVol:50,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      dialogVisible: false,
      centerDialogVisible:false,
      showInfo:'',
      active: "-1",
      allZones: [],
      length: "",
      scrollTop: "",
      timer: null,
      index: "",
      isColor: false,
      index: "",
      isOpen: "-1",
      gather: [], //选中集合数组
      stringList: null, //选中集合数组转换成字符串
      allSelect: false,
      right: "",
      left: "",
      comData: "",
      all: "",
      clickId: "",
      taskID: "-1",
      come: false,
      timer: "",
      timer1:'',
      timer2:'',
      timer3:'',
      timer4:'',
      liststop:{},
      allzone: [
        {
          name: "全部分区",
          id: -1,
        },
      ],
    };
  },
  components: {
     name: "Urgent"
  },
  mounted() {
    // if(this.dialogVisible!=true){
    //   this.taskstop();
    // }
    if(JSON.parse(sessionStorage.getItem("token"))!=null){
    this.timer2=setInterval(()=>{
      // this.websock.send(Data);
      // this.getAllZones();
      // console.log('紧急广播')
      },1000)
      this.timer3=setInterval(()=>{
      // this.websock.send(Data);
      this.initWebSocket();
      // console.log('紧急广播')
      },1000)
    }
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
      
  },
  created() {
   this.timer4=setInterval(()=>{
      // this.websock.send(Data);
      this.initWebSocket();
      // console.log('紧急广播')
      },1000)
      
    this.getAllZones();
  },
  methods: { 
    initWebSocket() {
      // const wsuri = "ws://106.15.40.26:9004/chat";
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    // 更改麦克风音量
    changeMiVol() {
      this.volumeAdjustment(1, this.taskID, this.miVol);
      console.log("miVol:" + this.miVol);
    },
    volumeAdjustment(volumeType, timeTaskID, volume) {
      request({
        method: "put",
        url: "/broad/volume/" + volumeType + "/" + volume,
        data: [timeTaskID],
      })
        .then((res) => {
          console.log(res),
          console.log("put:'/broad/volume/{volumeType}/{volume}'接口成功");
        })
        .catch((err) => {
          console.log(err),
            console.log("put:'/broad/volume/{volumeType}/{volume}'接口失败");
        });
    },
    //连接建立之后执行send方法发送数据
    websocketonopen() {
      // let token = JSON.parse(sessionStorage.getItem("token"));
      // let actions = "timetaskid:" + this.task.timetasks[0].id; 旧
      let actions = {
        // token: "222",
        // token: token,
        dataType: "left:",
        ts: [],
      };
      this.websocketsend(JSON.stringify(actions));
      
      console.log('紧急广播连接成功');
      clearInterval(this.timer3)
      clearInterval(this.timer4)
    },

    //数据发送
    websocketsend(Data) {
      // this.timer=setInterval(()=>{
      // this.websock.send(Data);
      // // console.log('紧急广播')
      // },1000)
      // this.websock.send(Data);
    },
    taskstart() {
      console.log(this.taskID, 9966);
      // clearInterval(this.timer1);
      request({
        method: "put",
        url: "broad/start/volume/1",
        data: [this.taskID],
      })
        .then((res) => {
          // this.musicVol_switch = false
          // clearInterval(this.timer1);
          console.log(res);
          console.log("麦克风开始接口成功", this.taskID);
        })
        .catch((err) => {
          console.log(err);
          console.log("失败");
        });
    },
    taskstop() {
      request({
        method: "put",
        url: "/broad/stop/volume/1",
        data: [this.taskID],
      })
        .then((res) => {
          // this.musicVol_switch = true;
          console.log(res);
          console.log("麦克风关闭接口成功",this.taskID);
        })
        .catch((err) => {
          console.log(err);
          console.log("失败");
        });
    },
    //数据接收
    websocketonmessage(e) {
      // console.log(e,'紧急广播')
      let d = JSON.parse(e.data).dataType;
      let s =JSON.parse(e.data).showInfo;
      if(d==9){
        this.centerDialogVisible=true
        this.showInfo=s
      }
      this.comData = d;
      if (this.comData == "Short" && this.dialogVisible == true) {
        // this.a(this.clickId)
        if (this.active >= 0) {
          let d = this.allZones[this.active].id;
          // this.gather.push(d)
          let subscript = this.gather.indexOf(d); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
          if (subscript > -1) {
            this.gather.splice(subscript, 1); //splice:用于添加或删除数组中的元素
            request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: list,
            })
              .then((res) => {
                this.taskstop();
                this.taskID='-1'
                console.log("删除任务id", res);
                console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            // this.taskstop();
          } else {
            if(this.taskID!=''){
              this.taskstop();
            request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: list,
            })
              .then((res) => {
                console.log("删除任务id", res);
                console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            }
            this.gather=[]
            this.gather.push(d); //通过push方法将选中id添加到数组中
            // 获取任务id
            this.stringList = this.gather.join(",");
            var list = {
              priority: 7,
              collectID:-1,
              taskVol:100,
              miVol:this.miVol,
              name:'紧急广播',
              regions: [{ id: this.stringList }],
            };
            this.liststop=list
            request({
              method: "put",
              url: "broad/remoteStart",
              data: list,
            })
              .then((res) => {
                this.taskID = res.data.data;
                this.come = true;
                this.taskstart();
            //     this.timer1 = setInterval(() => {
            //   this.taskstart();
            //   console.log("任务id");
            // }, 1000);
                console.log(
                  "获得的任务id：",
                  res,
                  this.stringList,
                  this.taskID
                );
                // console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            // this.timer1 = setInterval(() => {
            //   this.taskstart();
            //   console.log("任务id");
            // }, 1000);

            console.log(this.active, 77777, this.gather, d);
          }
        }
        // if (this.active < 0) {
        //   this.allSelect = true;
        //   var all = [];
        //   this.allZones.forEach((item) => {
        //     all.push(item.id);
        //   });

        //   console.log("66666", all, this.gather);
        //   if (this.gather.length < all.length) {
        //     this.gather.push(...all);
        //   } else {
        //     this.gather.splice(0);
        //     this.allSelect = false;
        //   }
        // }
      }
      if (this.comData == "DoubleClick") {
        this.dialogVisible = true;

      }
      if (this.comData == "Long") {
        
        this.gather=[]
       // this.taskstop()
        request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: this.liststop,
            })
              .then((res) => {
                // alert(123)
                this.dialogVisible = false;
                // console.log("删除任务id", res);
                console.log("紧急广播已关闭");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
              
      }
      if (this.comData == "Left") {
        this.active++;
        if (this.active > this.length) {
          this.active = -1;
        }
      }
      if (this.comData == "Right") {
        this.active--;
        if (this.active < 0) {
          this.active =-1;
        }
      }
      if (this.comData == "Short" && this.active == -1) {
        console.log(123);
      }
      // if(this.allZones.length==this.gather.length){
      //   this.allSelect = true;
      //   console.log()
      // }
      // console.log(22222, this.comData, this.active, this.all);
      console.log('com发来的指令',this.comData);
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },
    a(id) {
      // this.miVol=50
      let d = id;
          // this.gather.push(d)
          let subscript = this.gather.indexOf(d); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
          if (subscript > -1) {
            this.gather.splice(subscript, 1); //splice:用于添加或删除数组中的元素
            request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: list,
            })
              .then((res) => {
                this.taskstop();
                this.taskID='-1'
                console.log("删除任务id", res);
                console.log("接口成功",this.taskID);
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            // this.taskstop();
          } else {
            if(this.taskID!=''){
              this.taskstop();
            request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: list,
            })
              .then((res) => {
                console.log("删除任务id",this.taskID, res);
                console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            }
            this.gather=[]
            this.gather.push(d); //通过push方法将选中id添加到数组中
            // 获取任务id
            this.stringList = this.gather.join(",");
            var list = {
              priority: 7,
              collectID:-1,
              taskVol:100,
              miVol:this.miVol,
              name:'紧急广播',
              regions: [{ id: this.stringList }],
            };
            this.liststop=list
            request({
              method: "put",
              url: "broad/remoteStart",
              data: list,
            })
              .then((res) => {
                this.taskID = res.data.data;
                this.come = true;
                this.taskstart();
            //     this.timer1 = setInterval(() => {
            //   this.taskstart();
            //   console.log("任务id");
            // }, 1000);
                console.log(
                  "获得的任务id：",
                  res,
                  this.stringList,
                  this.taskID,
                  this.miVol
                );
                // console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            // this.timer1 = setInterval(() => {
            //   this.taskstart();
            //   console.log("任务id");
            // }, 1000);

            console.log(this.active, 77777, this.gather, d);}
      console.log(id);
    },
    beforeunloadHandler() {
            this.beforeUnload_time = new Date().getTime();
        },
        unloadHandler() {
            this._gap_time = new Date().getTime() - this.beforeUnload_time;
            console.log(this._gap_time)
            //判断是窗口关闭还是刷新
            if (this._gap_time <= 5) {
              this.gather=[]
       // this.taskstop()
        request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: this.liststop,
            })
              .then((res) => {
                // alert(123)
                // this.dialogVisible = false;
                // console.log("删除任务id", res);
                console.log("紧急广播已关闭");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
                console.log('执行关闭方法')
            }
            if (this._gap_time >= 5) {
              this.gather=[]
       // this.taskstop()
        request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: this.liststop,
            })
              .then((res) => {
                // alert(123)
                // this.dialogVisible = false;
                // console.log("删除任务id", res);
                console.log("紧急广播已关闭");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
                console.log('执行关闭方法')
            }
        },
    // 调用接口得到所有分区++++++++++++++++++++++++++++++++++++++++++++++++
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          console.log("紧急广播的分区：");
          this.allZones = res.data.data;
          console.log(res.data.data,8864);
          this.length = this.allZones.length;
          this.length = this.length - 1;
          console.log("get:”/aregion”接口成功",678);
          clearInterval(this.timer2)
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
  },
    // 离开路由之后断开websocket连接
    destroyed() {
      // this.websock.close();
      this.taskstop()
      request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: this.liststop,
            })
              .then((res) => {
                // alert(123)
                // console.log("删除任务id", res);
                console.log("紧急广播已关闭");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
      //  clearInterval(this.timer)
      window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.removeEventListener('unload', e => this.unloadHandler(e))
    },
    
};
</script>


<style>
@import url("../assets/css/urgent.css");
</style>
