<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/wulian" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" @click="addLine()" round
          >添加</el-button
        >
      </span>
      <el-dialog
        v-dialogDrag
        :title="add==true?'添加页面':'编辑页面'"
        :visible.sync="is_show"
        center
        :append-to-body="true"
        :lock-scroll="false"
        width="600px"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
      <template>
  <div>
    <!-- <h1>{{ msg }}</h1> -->
      <div>
        <el-button :disabled="stop" :style="isActive==0?'color: #409EFF':''" class="tabs" @click="isActive1()">透传</el-button>
      <el-button :disabled="stop" :style="isActive==1?'color: #409EFF':''" class="tabs" @click="isActive2()">触发</el-button>
      <div style="width: 100%;background-color: #CACDD1;height: 1px;"></div>
    </div>
 
      <div>
        <div class="bodys" v-if="isActive==0">
          <div style="margin-top: 30px;" class="z1">
        <span class="z2">接收指令：</span>
        <el-input
        class="z3"
          placeholder="请输入接收指令"
          v-model="command1"
          clearable
        >
        </el-input>
      </div>
      <div class="z1">
        <span class="z2">发送指令：</span>
        <el-input
        class="z3"
          placeholder="请输入发送指令"
          v-model="outcommand"
          clearable
        >
        </el-input>
      </div>
      <div class="z1">
        <span class="z2">目标IP：</span>
        <el-input
        class="z3"
          placeholder="请输入IP"
          v-model="ip"
          clearable
        >
        </el-input>
      </div>
      <div class="z1">
        <span class="z2">目标端口：</span>
        <el-input
        class="z3"
          placeholder="请输入端口"
          v-model="port"
          clearable
        >
        </el-input>
      </div>
        </div>
      <div class="bodys" v-else-if="isActive==1">
        <div style="margin-top: 10px;margin-left: 10px;" class="z1">
        <span class="z2">指令：</span>
        <el-input
        class="z3"
        style="width: 70%;height:30px;"
          placeholder="请输入指令"
          v-model="command2"
          clearable
        >
        </el-input>
      </div>
        <div class="z1" style="line-height: 20px;margin-left: 10px;">
          <span class="z2">任务分区：</span>
          <el-select
              v-model="currentSelectZone"
              multiple
              placeholder="请选择分区"
              class="z3"
              style="height: 50px;width: 70%;"
              size="mini"
            >
              <el-option
                v-for="zone in allZones"
                :key="zone.id"
                :label="zone.name"
                :value="zone.id"
              >
              </el-option>
            </el-select>
        </div>
        <!-- <div style="margin-top: -12px;margin-left: 10px;" class="z1">
        <span class="z2">触发次数：</span>
        <el-input
        class="z3"
        style="width: 70%;"
          placeholder="请输入触发次数"
          v-model="num"
          clearable
        >
        </el-input>
      </div> -->
        <div class="z1" style="line-height: 20px;margin-top: -3px;;margin-left: 10px;">
          <span class="z2">触发次数：</span>
          <el-select
            v-model="num"
            size="mini"
            placeholder="请选择次数"
            class="z3"
            style="height: 50px;width: 70%;"
            >
            <el-option label="0" value="0"></el-option>
            <el-option label="1" value="1"></el-option>
            <el-option label="2" value="2"></el-option>
            <el-option label="3" value="3"></el-option>
            <el-option label="4" value="4"></el-option>
            <el-option label="5" value="5"></el-option>
        </el-select>
        </div>
        <div style="text-align: left;margin-left: -31px;margin-top: 10px;">
          <el-transfer
          filterable
          filter-placeholder="请输入搜索的歌曲"
          v-model="selectSongs"
          :data="allSongsData"
          :titles="['可选歌曲', '已选歌曲']"
          target-order="push"
        >
        </el-transfer>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button class="a6" @click="cancelButton" size="mini" round>
          取消
        </el-button>
      <el-button
          class="a6"
          @click="saveAddTask"
          size="mini"
          type="primary"
          round
        >
          保存
        </el-button>
    </div>
  </div>
</template>
      </el-dialog>
    </div>
    <div>
      <template>
        <el-table
          :data="tableData"
          style="width: 100%; line-height: 10px"
          height="78vh"
        >
          <el-table-column width="25"></el-table-column>
          <el-table-column label="序号" align="center" type="index" width="50">
          </el-table-column>
          <el-table-column label="接收指令" align="center" prop="command">
          </el-table-column>
          <el-table-column label="发送指令" align="center" prop="outcommand">
          </el-table-column>
          <el-table-column label="标识符" align="center" prop="passType">
             <template slot-scope="scope">
                <span>{{
                  scope.row.passType == "0"? '透传' : "触发"
                }}</span>
              </template>
          </el-table-column>
          <!-- <el-table-column label="歌曲/IP" align="center" prop="targetIp">
            <template slot-scope="scope">
              <span v-if="scope.row.passType == '0'">{{ scope.row.targetIp }}</span>
              <span v-else v-for="(item, index) in scope.row.aTimetask.songs" :key="index">{{ item.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="分区/端口" align="center" prop="targetPort">
            <template slot-scope="scope">
              <span v-if="scope.row.passType == '0'">{{
                scope.row.targetPort
              }}</span>
              <span v-else v-for="(item, index) in scope.row.aTimetask.regions" :key="index">{{ item.name }}</span>
            </template>
          </el-table-column> -->
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                size="mini"
                @click="handleEdit(scope.row)"
                type="primary"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      tableData:[],
      command1:'',
      command2:'',
      ip:'',
      port:'',
      is_show:false,
      command:'',
      isActive: 0,
      outcommand:'',
      allZones:[],
      allSongs:[],
      currentSelectZone:[],
      selectSongs:[],
      allSongsData: [],
      add:'',
      stop:false,
      num:'',
      currentTask:{
        aTimetask:{
          
        }
      }
    };
  },
  mounted() {
    this.getAllZones()
    this.getAllSongs()
    this.getApassthrough()
  },
  methods: {
    getApassthrough(){
      request({
        url: "/apassthrough",
      })
        .then((res) => {
          this.tableData=res.data.data
          console.log(res.data.data);
          console.log("查询串口透传数据");
          // const dataArray = res.data.data;

          // if (dataArray.length > 0) {
          //   //添加编辑事件
          //   for (const index in dataArray) {
          //     //axios实例已经解决精度损失，这里还要转换成字符串
          //     dataArray[index].id = dataArray[index].id.toString();

          //     dataArray[index]["edit"] = false;
          //     this.tableData.push(dataArray[index]);
          //   }
          //   if (Object.keys(this.new_date_json).length === 0) {
          //     //新增时，初始化数据结构
          //     this.initAddDataJson(dataArray[0]);
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addLine() {
      this.isActive=0
      this.command1='',
      this.outcommand=''
      this.command2='',
      this.ip='',
      this.port='',
      this.is_show=true
      this.add=true
      this.currentSelectZone=[],
      this.selectSongs=[],
      this.stop=false
      this.num=''
      console.log(123);
    },

    handleEdit(row) {
      this.currentTask=row
      if(row.passType==0){
      this.isActive=0
      this.command1=row.command
      this.outcommand=row.outcommand
      this.ip=row.targetIp
      this.isActive=row.passType
      this.port=row.targetPort
      this.stop=true
      }else{
        this.isActive=1
      //   let d = [];
      // row.aTimetask.regions.forEach((region) => {
      //   d.push(region.id);
      // });
        this.command2=row.command
        this.currentSelectZone = this.zoneIdArray();
        this.selectSongs = this.songIdArray();
        this.stop=true
        this.num=row.aTimetask.repeatTime
        // this.currentSelectZone = this.zoneIdArray();
        // this.selectSongs = d
      }
      this.is_show=true
      this.add=false
      
      console.log("我要修改",row,row.aTimetask.repeatTime);
    },
    handleDelete(index, id) {
      console.log(index, id);
      request({
        method: "delete",
        url: "/apassthrough/" + id,
        // data: "", //+++++++++++++++++++++++++++++++++++++++++++++++++++++
        // data: rSpeakers,
      })
        .then((res) => {
          console.log(res.data.resultCode, 2222222222222);
          // 删除一行数据
          if (res.data.resultCode == 200) {
            this.tableData.splice(index, 1);
            this.$message.success("数据删除成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    isActive1(){
      this.isActive=0
      console.log(this.isActive)
    },
    isActive2(){
      this.isActive=1
      console.log(this.isActive)
    },
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          this.allZones = res.data.data;
          console.log("串口透传全部分区",res);
          // console.log(res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
    getAllSongs() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          this.allSongs = res.data.data;
          this.allSongsData=[]
          const Songs = this.allSongs;
          Songs.forEach((allSongName) => {
          this.allSongsData.push({
          label: allSongName.name,
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: allSongName.id.toString(),
          // sindex: index,
        });
      });
          console.log("串口透传全部歌曲",res );
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
    cancelButton(){
      this.is_show=false
    },
    getTaskVal(){
      let zoneObj = this.getZoneObj();
    },
    saveAddTask(){
      // this.is_show=false
      if (this.add == true) {
        if(this.isActive==0){
          this.currentTask.command=this.command1
          this.currentTask.outcommand=this.outcommand
          this.currentTask.targetIp=this.ip
          this.currentTask.passType=this.isActive
          this.currentTask.targetPort=this.port
        }else{
          this.currentTask.command=this.command2
          let zoneObj = this.getZoneObj();
          let songObj = this.getSongObj();
          this.currentTask.passType=this.isActive
          this.currentTask.aTimetask.regions = zoneObj;
          this.currentTask.aTimetask.songs = songObj;
          this.currentTask.aTimetask.repeatTime=this.num
          console.log( this.currentTask,zoneObj,668)
        }
        request({
          method: "post",
          url: "/apassthrough",
          data: this.currentTask,
        }).then((res) => {
          // this.currentTask={}
          console.log("添加成功");
          console.log(res);
          this.tableData.push(res.data.data);
          if (res.data.resultCode == 200) {
            this.currentTask={
              aTimetask:{}
            }
            this.outcommand=''
            this.$message({
              message: "添加成功！",
              type: "success",
            });
          }
        });
        // this.tableData.push(this.currentTask);
        this.is_show = false;
      // console.log(list)
    }
    if(this.add==false){
      if(this.isActive==0){
          this.currentTask.command=this.command1
          this.currentTask.outcommand=this.outcommand
          this.currentTask.targetIp=this.ip
          this.currentTask.passType=this.isActive
          this.currentTask.targetPort=this.port
        }else{
          this.currentTask.command=this.command2
          let zoneObj = this.getZoneObj();
          let songObj = this.getSongObj();
          this.currentTask.passType=this.isActive
          this.currentTask.aTimetask.regions = zoneObj;
          this.currentTask.aTimetask.songs = songObj;
          this.currentTask.aTimetask.repeatTime=this.num
          console.log( this.currentTask,668)
        }
        request({
          method: "put",
          url: "/apassthrough",
          data: this.currentTask,
        }).then((res) => {
          // this.currentTask={}
          console.log("修改成功",this.currentTask);
          console.log(res);
          // this.tableData.push(res.data.data);
          if (res.data.resultCode == 200) {
            this.currentTask={
              aTimetask:{}
            }
            this.outcommand=''
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        });
        // this.tableData.push(this.currentTask);
        this.is_show = false
    }
  },
    getZoneObj() {
      let data = [];
      for (let i = 0; i < this.allZones.length; i++) {
        for (let j = 0; j < this.currentSelectZone.length; j++) {
          if (this.allZones[i].id == this.currentSelectZone[j]) {
            data.push(this.allZones[i]);
          }
        }
      }
      return data;
    },
    getSongObj() {
      let data = [];
      for (let i = 0; i < this.selectSongs.length; i++) {
        for (let j = 0; j < this.allSongs.length; j++) {
          if (this.allSongs[j].id == this.selectSongs[i]) {
            this.allSongs[j].index = i + 1;
            data.push(this.allSongs[j]);
          }
        }
      }
      return data;
    },
    zoneIdArray() {
      let d = [];
      this.currentTask.aTimetask.regions.forEach((region) => {
        d.push(region.id);
      });
      return d;
    },
    songIdArray() {
      let d = [];
      // this.currentTask.timetasks[0].songs.forEach((song) => {
      //   d.push(song.id);
      // });
      for (let i = 0; i < this.currentTask.aTimetask.songs.length; i++) {
        d.push(this.currentTask.aTimetask.songs[i].id);
      }
      // console.log(d,66998877)
      return d.map((v) => v.toString());
    },
  },
};
</script>
<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
::v-deep .el-dialog__body {
  padding-bottom: 10px;
}
.a1 {
  line-height: 30px;
  width: 400px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
.a3 {
  text-align: center;
  width: 100%;
  /* background-color: peachpuff; */
  /* line-height: 80px; */
  margin-bottom: 20px;
}
.a4 {
  width: 300px;
}
.a5 {
  margin: 20px;
  /* background-color: rgb(164, 231, 226); */
}
::v-deep .el-dialog__header{
  padding: 20px 20px 0px;
}
::v-deep .el-dialog--center .el-dialog__body{
  padding: 0px 20px;
}
::v-deep .el-dialog__body{
  height: 540px;
}
.tabs{
     /* background-color: rgb(141, 228, 250); */
     width:70px;
     /* line-height:30px; */
     margin-left: 0px !important;
     border: 0px;
   }
.tabs:hover{
  background-color: #ffffff;
  color: #606266;
}
.tabs:focus {
  background-color: #ffffff;
}
.bodys{
     /* background-color: rgb(141, 228, 250); */
     width:480px;
     line-height:30px;
     margin: auto;
   }
.z1 {
  display: flex;
  line-height: 60px;
  margin-left: 40px;
  margin-top: 10px;
}
.z2 {
  /* flex: 1;
  text-align-last: justify; */
  width: 100px;
  text-align: right;
  margin-right: 20px;
}
.z3 {
  width: 50%;
}
.btn{
  position: absolute;
  right: 50px;
  bottom: 10px;
}
::v-deep .el-select__tags{
  top: 28%;
}
.el-transfer {
  font-size: 14px;
  height: 270px;
  width: 590px;
  /* 搜索框的位置 */
  line-height: 0px;
  margin: auto;
}
::v-deep .el-transfer__buttons{
  padding: 0 10px;
}
::v-deep .el-transfer__buttons {
  padding: 0 10px;
}
::v-deep .el-transfer {
  height: 250px !important;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
::v-deep .el-input__inner{
    height: 28px;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
.el-transfer >>> .el-transfer-panel__filter {
  margin: 5px;
}
/* 穿梭框中的搜索框高度 */
.el-transfer >>> .el-transfer-panel__filter .el-input__inner {
  height: 24px;
}
/* 穿梭框中的头部 */
.el-transfer >>> .el-transfer-panel .el-transfer-panel__header {
  height: 30px;
  line-height: 30px;
}
</style>