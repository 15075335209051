// 终端配置按钮界面
<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <!-- <el-button size="mini" icon="el-icon-plus" round @click="addLine()"
          >添加</el-button
        > -->
        <el-button size="mini" icon="el-icon-plus" @click="addMonitor()" round
          >监听音箱</el-button
        >
        <el-button size="mini" icon="el-icon-close" @click="stopMonitor()" round
          >清空监听</el-button
        >
      </span>
      <span class="a2">
        <!-- <el-button size="mini" icon="el-icon-close" round @click="allDelete()"
          >全部删除</el-button -->
      </span>
    </div>
    <div>
      <template>
        <el-table
          stripe
          :data="tableData"
          @selection-change="handleSelectionChange"
          style="width: 100%; line-height: 10px"
          height="78vh"
          border
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <!-- <el-table-column width="55"> </el-table-column> -->
          <el-table-column label="选择" align="center" width="65">
            <template scope="scope">
              <el-radio
                :label="scope.$index"
                v-model="radio"
                @change.native="getRow(scope.row, scope.$index)"
              >
                <i></i>
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" type="index">
          </el-table-column>
          <el-table-column prop="ip" align="center" label="IP">
            <template slot-scope="scope">
              <!-- v-if="scope.row.add == true && scope.row.edit" -->
              <!-- :placeholder="'请输入' + label" -->
              <el-input
                v-if="scope.row.edit"
                size="small"
                v-model="scope.row.ip"
                :placeholder="'请输入'"
              ></el-input>
              <span v-if="!scope.row.edit">{{ scope.row.ip }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id" label="ID">
          </el-table-column> -->
          <el-table-column prop="devAddr" show-overflow-tooltip align="center"  label="位置">
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.edit"
                size="small"
                v-model="scope.row.devAddr"
                :placeholder="'请输入'"
              ></el-input>
              <span v-if="!scope.row.edit">{{ scope.row.devAddr }}</span>
            </template>
          </el-table-column>
           <el-table-column prop="callnum" align="center" label="寻呼号码">
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.edit"
                size="small"
                v-model="scope.row.callnum"
                :placeholder="'请输入'"
              ></el-input>
              <span v-if="!scope.row.edit">{{ scope.row.callnum }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="speakerModel" align="center"  label="设备类型">
            <template slot-scope="scope">
              <el-input
                v-if="scope.row.edit"
                size="small"
                v-model="scope.row.speakerModel"
                :placeholder="'请输入'"
              ></el-input>
              <span v-if="!scope.row.edit">{{ scope.row.speakerModel }}</span>
            </template>
          </el-table-column>
          <!-- IP 地址//// -->

          <el-table-column align="center"  label="操作">
            <template slot-scope="scope">
              <!-- 全局控制的编辑 -->
              <div
                v-if="is_edit && scope.row.add == undefined"
                style="display: inline-block"
              >
                <!-- 编辑 -->
                <el-button
                  size="mini"
                  v-if="!scope.row.edit"
                  @click="handleEdit(scope.$index, scope.row)"
                  type="primary"
                  style="margin-right: 5px"
                  >编辑</el-button
                >
                <!-- 保存 -->
                <el-button
                  size="mini"
                  type="success"
                  :plain="true"
                  v-if="scope.row.edit"
                  @click="handleSave(scope.$index, scope.row)"
                  >保存</el-button
                >
              </div>

              <!-- 添加控制 -->
              <div
                v-if="scope.row.add != undefined && scope.row.add"
                style="display: inline-block"
              >
                <!-- 保存 -->
                <el-button
                  size="mini"
                  type="success"
                  :plain="true"
                  v-if="scope.row.edit"
                  @click="handleSave(scope.$index, scope.row)"
                  >保存</el-button
                >
              </div>

              <!-- 全局控制删除 -->
              <!-- :plain="true" -->
              <el-button
                size="mini"
                v-if="is_delete && scope.row.add == undefined"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
          <!-- <el-table-column label="监听音箱">
            <template>
           <el-button
                size="mini"
                type="success"
                >设为监听音箱</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </template>
    </div>
  </div>
</template>

<script type="text/javascript">
// import JSONbig from "json-bigint";

import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      // 勾选的复选框对应的行的数据
      lineListSelections: [],
      // 表的数据集
      tableData: [],

      //数据结构
      new_date_json: {},

      //是否可编辑
      is_edit: true,
      //是否可删除
      is_delete: true,
      radio: "",
      speakerID: "",
    };
  },

  components: {},
  created() {},

  mounted() {
    this.initEditAttribute();
    this.radio = JSON.parse(sessionStorage.getItem("radio"));
  },
  methods: {
    stopMonitor(){
      this.radio=''
        request({
          method: "post",
          url: "/aspeaker/listen/"+'-1'
        })
          .then((res) => {
            if (res.data.resultCode == 200) {
              sessionStorage.removeItem("radio");
              this.$message({
                message: "清空监听音箱成功！",
                type: "success",
              });
            }
            console.log("监听音箱id:", res, 8868);
          })
          .catch((err) => {
            console.log(err);
          });
      console.log(this.speakerID, 888);
    },
    addMonitor() {
      if (this.speakerID != "") {
        request({
          method: "post",
          url: "/aspeaker/listen/"+this.speakerID
        })
          .then((res) => {
            if (res.data.resultCode == 200) {
              this.$message({
                message: "添加监听音箱成功！",
                type: "success",
              });
            }
            console.log("监听音箱id:", res, 8868);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      console.log(this.speakerID, 888);
    },
    getRow(row, index) {
      this.radio = index;
      this.speakerID = row.id;
      sessionStorage.setItem("radio", index);
      console.log(row.id, index, 8868, this.radio);
    },
    // 编辑按钮-----------------------------------------
    handleEdit(index, row) {
      console.log(index, row);

      // 编辑行只能有一行
      for (let i = 0; i < this.tableData.length; i++) {
        
        if (this.tableData[i].edit) {
          this.$message.error("错了哦，先保存其它分区再编辑");
          return;
        }
      }

      // 设置是否可编辑（即显示"保存"按钮）
      row.callnum =  row.callnum.slice(1)
      row.edit = true;
    },

    //判断IP是否合法------------------------------------
    isIp(index, ip) {
      // 在保存之前首先判断输入框内容（IP）是否合法
      const regexIP =
        /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/;
      if (regexIP.test(ip)) {
        // alert("IP地址正确");
        return true;
      } else if (ip != "") {
        alert("IP地址有误，请重新输入！");
      } else {
        alert("IP地址不能为空！！！");
        // 删除一行数据
        this.tableData.splice(index, 1);
      }
    },

    //保存按钮-----------------------------------------
    handleSave(index, row) {
      // 调用函数 判断IP是否合法
      if (this.isIp(index, row.ip)) {
        row.edit = false;
        // 当前行的数据
        const list = this.tableData[index];
        console.log(
          "可判断保存按钮前是“编辑”还是“新增”，“编辑是undefined”，“新增是true”" +
            index,
          row,
          row.add,
          list
        );

        if (row.add == true) {
          //（2）调用添加接口
          request({
            method: "post",
            url: "/aspeaker",
            data: list,
          })
            .then((res) => {
              console.log("post:”/speaker”接口成功");
              console.log(res, 9999);
              // 新添加的音箱没有id，若马上删除会报错，所以新添加音箱成功后，马上从数据库获得
              this.tableData[index].id = res.data.data.id.toString();
            })
            .catch((err) => {
              console.log("post:”/speaker”接口失败！");
              console.log(err);
            });
        } else if (row.add == undefined) {
          var callnum=list.callnum
          //（2）调用更改接口
          if(list.callnum!=''){
            list.callnum=0+list.callnum
          }
          request({
            method: "put",
            url: "/aspeaker",
            data: list,
          })
            .then((res) => {
              console.log(res, 66666);
              // alert(res.data.resultMessage);
              this.$message({
                message: res.data.resultMessage,
                type: "success",
              });
            })
            .catch(() => {
              console.log("put:”/speaker”接口失败！");
            });
        } else {
          alert("既不是新增用户也不是编辑用户！");
        }

        // 调用接口需要用到“table_data[index].add”的值做判断，所以该行代码必须在调用接口之后
        delete this.tableData[index].add;
      }
    },
    // 删除按钮----------------------------------------
    handleDelete(index, row) {
      console.log(index, row);

      // 音箱ID
      const speakerId = row.id;

      request({
        method: "delete",
        url: "/aspeaker/" + speakerId,
        // params: { id: speakerId }, // 请求参数拼接在url上
        // data: {}, // 请求参数放在请求体
      })
        .then((res) => {
          console.log(res);
          //判断音箱是否删除不成功
          if (res.data.resultCode == 50001) {
            // alert("删除不成功，因为所选终端已被分配到分区！");
            this.$message({
              message: "删除失败，所选终端已被分配到分区！",
              type: "error",
            });
          } else {
            // 删除一行数据
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.tableData.splice(index, 1);
          }
        })
        .catch(() => {
          console.log("delete:”/aspeaker/{id}”接口失败！");
        });
    },

    // 全部删除按钮-----------------------------------------
    allDelete() {
      // console.log("全部删除如下：" + this.lineListSelections);
    },

    // 获得勾选的复选框对应的行-----------------------------
    handleSelectionChange(val) {
      this.lineListSelections = val;
      console.log("不能打印'val'的值，否则报错！！！！");
    },

    // 添加终端-------------------------------------------
    addLine() {
      const addDataJson = {};
      console.log(this.new_date_json);
      for (const key in this.new_date_json) {
        console.log(key);
        if (key === "edit") {
          delete addDataJson[key];
        } else if (key === "add") {
          delete addDataJson[key];
        } else {
          addDataJson[key] = "";
        }
      }

      addDataJson.edit = true;
      addDataJson.add = true;
      this.tableData.push(addDataJson);

      console.log(this.tableData);
    },

    //初始化编辑属性--------------------------------------
    initEditAttribute() {
      //查询数据库的音箱
      request({
        url: "/aspeaker",
      }).then((res) => {
        console.log("获得数据库的所有音箱列表:");
        console.log(res.data.data);
        const dataArray = res.data.data;

        if (dataArray.length > 0) {
          //添加编辑事件
          for (const index in dataArray) {
            // axios实例已经解决精度损失，这里还要转换成字符串
            dataArray[index].id = dataArray[index].id.toString();

            dataArray[index]["edit"] = false;
            this.tableData.push(dataArray[index]);
          }

          if (Object.keys(this.new_date_json).length === 0) {
            //新增时，初始化数据结构
            this.initAddDataJson(dataArray[0]);
            // console.log(Object.keys(that.new_date_json).length);
          }
        }
      });
    },

    // 新增时，初始化数据结构-------------------------------------------
    initAddDataJson(dataArray) {
      //新增时，初始化数据结构
      const dataJson = dataArray;
      const newDateJson = {};
      for (const key in dataJson) {
        if (key === "edit") {
          newDateJson[key] = "true";
        } else {
          newDateJson[key] = "";
        }
      }
      newDateJson["add"] = true;
      this.new_date_json = newDateJson;
    },
  },
};
</script>

<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}
.a1 {
  line-height: 30px;
  width: 300px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
</style>
