// 定时子任务界面
<template>
  <div id="app">
    <urgent></urgent>
    <div>
      <el-container>
        <el-header height="40px">
          <el-row>
            <!-- 返回按钮 -->
            <el-col class="a1" :span="1">
              <router-link to="/plan" replace>
                <el-button
                  round
                  type="primary"
                  icon="el-icon-back"
                  size="mini"
                ></el-button>
              </router-link>
            </el-col>
            <!-- 麦克风按钮 -->
            <!-- <el-col class="a3" :span="2">
              <div id="a3">
                <el-button round icon="el-icon-mic" size="mini"></el-button>
              </div>
            </el-col> -->
            <!-- 麦克风录制进度 -->
            <!-- <el-col class="a3" :span="2"><div id="a3">00:00:00</div></el-col> -->
            <!-- 任务编辑按钮 -->
            <!-- <el-col class="a3" :span="2">
              <div id="a3">
                <el-button
                  round
                  icon="el-icon-edit"
                  size="mini"
                  @click="editTask"
                ></el-button>
              </div>
            </el-col> -->
            <!-- 任务名称 -->
            <el-col class="a2" :span="10">
              <div style="color: #fff;">
                {{ task.name }}
                <span style="margin: 20px">
                  <!-- 表格当前行ID：{{ currentRowId }} -->
                </span>
              </div>
            </el-col>
          </el-row>
        </el-header>

        <el-container>
          <el-main>
            <el-container>
              <el-footer height="40px" style="color: #fff;">
                <div class="a1">权限：</div>
                <div class="a1">
                  <el-rate
                    class="a7"
                    v-model="permissionValue"
                    disabled
                    :max="3"
                  ></el-rate>
                </div>
                <div class="a1">模式：</div>
                <div class="a1">
                  <el-button
                    size="mini"
                    round
                    @click="single"
                    :class="[currentRepeatMode == 2 ? 'bGcolor' : 'none']"
                  >
                    单曲循环
                  </el-button>
                  <el-button
                    size="mini"
                    round
                    @click="list"
                    :class="[currentRepeatMode == 1 ? 'bGcolor' : 'none']"
                  >
                    列表循环
                  </el-button>
                  <el-button
                    size="mini"
                    round
                    @click="random"
                    :class="[currentRepeatMode == 4 ? 'bGcolor' : 'none']"
                  >
                    随机播放
                  </el-button>
                  <el-button
                    size="mini"
                    round
                    @click="order"
                    :class="[currentRepeatMode == 3 ? 'bGcolor' : 'none']"
                  >
                    列表播放
                  </el-button>
                  <el-button
                    icon="el-icon-service"
                    round
                    size="mini"
                    style="margin-left: 80px;"
                    @click="monitorButton(task.id)"
                    :style="listenState == true ? 'background-color: aqua;' : 'background-color: #ffffff;'"
                  >
                  </el-button>
                </div>
                <div class="a3">
                <el-button
                  round
                  icon="el-icon-edit"
                  size="mini"
                  @click="editTask"
                >编辑</el-button>
              </div>
                <div class="a8">
                  <el-button
                  style="margin-right: 5px;"
                    size="mini"
                    icon="el-icon-plus"
                    @click="addLine"
                    round
                    >添加</el-button
                  >
                </div>
              </el-footer>

              <el-main :style="defaultHeight">
                <div>
                  <el-table
                    class="a9"
                    ref="subTaskTable"
                    :data="timeTasks"
                    style="width: 100%"
                    height="85vh"
                    highlight-current-row
                    @row-click="rowClick"
                    border
                  >
                  <!-- <el-table-column
                      width="30"
                    ></el-table-column> -->
                    <el-table-column
                      prop="name"
                      align="center"
                      label="任务名称"
                      width="180"
                    ></el-table-column>

                    <el-table-column
                      prop="regions[0].name"
                      label="任务分区"
                      show-overflow-tooltip
                      align="center"
                      width="180"
                      :formatter="formatRegions"
                    >
                    </el-table-column>

                    <!--  prop="timing.repeatType" 001-->
                    <el-table-column
                      prop="planTime.repeatType"
                      :formatter="formatTaskPeriod"
                      label="任务周期"
                      width="100"
                      align="center"
                    >
                    </el-table-column>

                    <!-- prop="timing.daysOfWeek" 001-->
                    <el-table-column
                      prop="planTime.daysOfWeek"
                      label="星期"
                      width="140"
                      align="center"
                    >
                    </el-table-column>

                    <!-- prop="startDate" 001-->
                    <!-- <el-table-column
                      prop="planTime.startDate"
                      label="开始日期"
                      width="95"
                    >
                    </el-table-column> -->

                    <!-- prop="timing.startTimeOfDay" 001-->
                    <el-table-column
                      prop="planTime.startTimeOfDay"
                      label="开始时间"
                      width="130"
                      align="center"
                    ></el-table-column>

                    <!-- prop="timing.endTimeOfDay" -->
                    <el-table-column
                      prop="planTime.endTimeOfDay"
                      label="结束时间"
                      width="130"
                      align="center"
                    ></el-table-column>

                    <el-table-column
                      prop="songs"
                      :formatter="formatSongs"
                      label="所选歌曲"
                      width="242"
                      show-overflow-tooltip
                      align="center"
                    >
                    </el-table-column>

                    <el-table-column label="操作" width="150" align="center">
                      <template slot-scope="scope">
                        <!-- v-preventReClick -->
                        <el-button
                          :class="{ active: currentRowIndex == scope.$index }"
                          size="mini"
                           :disabled="scope.row.id==stateID"
                          @click="handleEdit(scope.$index, scope.row)"
                        >
                          编辑
                        </el-button>

                        <el-button
                          size="mini"
                          type="danger"
                          :disabled="scope.row.id==stateID"
                          @click="handleDelete(scope.$index, scope.row)"
                        >
                          删除
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-main>
            </el-container>
          </el-main>

          <!-- 音量条调整 -->
          <el-aside width="180px" style="height: 560px">
            <div>
              <template>
                <table>
                  <tr>
                    <!-- 音乐音量条 -->
                    <td>
                      <el-slider
                        vertical
                        height="300px"
                        v-model="musicVol"
                        class="a4"
                        :step="1"
                        @change="changeMusicVol"
                      >
                      </el-slider>
                    </td>
                    <!-- 监听音量 -->
                    <td>
                      <el-slider
                        vertical
                        height="300px"
                        v-model="headsetVol"
                        class="a4"
                        :step="1"
                        @change="changeHeadsetVol"
                      >
                      </el-slider>
                    </td>
                  </tr>

                  <!-- 音量值部分 -->
                  <tr class="a5">
                    <td>{{ musicVol }}</td>
                    <td>{{ headsetVol }}</td>
                  </tr>
                  <!-- 音量按钮部分 -->
                  <tr class="a5">
                    <td>
                      <el-button @click="button1" circle   :class="this.musicVol_switch == true ? 'button1' : 'button1-1'"></el-button>
                    </td>
                    <td>
                      <el-button @click="button2" circle :class="this. headsetVol_switch == true ? 'button2' : 'button2-2'"></el-button>
                    </td>
                  </tr>
                </table>
                <table style="margin: auto;margin-top: 40px;">
                  <!-- 日期时间部分 -->
                  <tr>
                    <td class="a6">
                      <!-- <keep-alive>
                        <get-time style="color: white;"></get-time>
                      </keep-alive> -->
                      <div class="serverDateTime">{{ dateFormat2(serverDateTime) }}</div>
                      <div class="serverDateTime">{{ dateFormat(serverDateTime) }}</div>
                    </td>
                  </tr>
                </table>
              </template>
            </div>
          </el-aside>
        </el-container>
      </el-container>
    </div>
    <!-- 编辑定时任务界面（弹出） -->
    <div>
      <el-dialog
        v-dialogDrag
        :visible.sync="is_show_edit"
        :destroy-on-close="true"
        :before-close="handleClose"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <add-task-p
          :createNewTask="task"
          :MyIsEdit="MyIsEdit"
          @closeDialog="isShowAddTaskDialog"
          @newTask="newTask"
        >
        </add-task-p>
      </el-dialog>
    </div>
    <!-- 添加或编辑定时子任务界面（弹出） -->
    <!--@open="openDialog"
        @close="closeDialog"
        @opened="openedDialog"
        @closed="closedDialog" -->
    <div>
      <el-dialog
        v-dialogDrag
        :visible.sync="is_show_add"
        :destroy-on-close="true"
        :before-close="handleClose"
        :append-to-body="true"
        :close-on-click-modal="false"
      >
        <add-sub-task-p
          :initTimetask="initTimetask"
          :regions="regions"
          :songs="songs"
          :startAndEndDate="startAndEndDate"
          @currentTimeTask="currentTimeTask"
          @closeDialog="isShowAddSubTaskDialog"
        >
        </add-sub-task-p>
      </el-dialog>
    </div>
  </div>
</template>

<script type="text/javascript">
import GetTime from "../public/getTime";
import { request } from "../../network/request";
import AddSubTaskP from "../public/addSubTaskP";
import AddTaskP from "../public/addTaskP";
export default {
  name: "app",
  data() {
    return {
      defaultHeight: {     
			height: ""    
			},
      taskState:'',
      add:false,
      musicVol_switch: true,
      headsetVol_switch: true,
      timer:null,
      // 编辑任务界面是否可视
      is_show_addTask: false,

      // 音乐音量
      musicVol: 0,

      //监听音量
      headsetVol: 0,

      // 权限
      permissionValue: 0,

      //当前的大任务
      task: {},
      // 当前的大任务下的所有子任务
      timeTasks: [],

      // 是否显示添加界面
      is_show_add: false,
      // 是否显示编辑界面
      is_show_edit: false,

      // 初始化定时子任务
      initTimetask: {
        headsetVol: 50,
        id: null, //修改时候要
        itemIndex: 0,
        miVol: 50,
        musicVol: 50,
        name: "000",
        planTime: {
          dailyCount: 1,
          daysOfWeek: "1,2,3,4,5,6,7",
          endTimeOfDay: "00:00:01",
          // endTimeOfDay: new Date().getTime() + 60000,
          executerID: 1293,
          id: null, //修改时候要
          isWork: 1,
          repeatType: 1,
          startDate: "2021-01-01",
          startTimeOfDay: "00:00:00",
          // startTimeOfDay: new Date().getTime() + 120000,
        },
        priority: 0,
        regions: [{ id: 307 }],
        repeatMode: 3,
        // repeatType: 1,
        songs: [],
        startDate: null,
        startTimeOfDay: "13:21",
        taskID: 1104,
        taskVol: 50,
      },

      // initTimetask: {
      //   itemIndex: 0,
      //   // 任务名称
      //   name: "",
      //   // 任务权限
      //   priority: 0,
      //   // 任务分区
      //   regions: [],
      //   // 端口号
      //   selectPort: 0,
      //   // 歌曲集合
      //   songs: [],
      //   // 开始日期
      //   startDate: "",
      //   // 大任务ID
      //   taskID: "",
      //   timing: {
      //     //重复类型（一次，每天，周一到周五，自定义）
      //     repeatType: "",
      //     // 具体周几（星期几集合）
      //     daysOfWeek: "",
      //     // 开始时间
      //     startTimeOfDay: "",
      //     // 结束时间
      //     endTimeOfDay: "",
      //   },
      // },

      // 所有的分区
      regions: "",
      // 所有的歌曲
      songs: "",

      //当前编辑的行的索引
      currentRowIndex: null,
      // 单击当前行的id（表格）
      currentRowId: null,

      // 当前行的播放模式
      currentRepeatMode: null,

      // 表示从编辑界面进去的（为了区分“新建”按钮还是“编辑”按钮进去的界面）
      MyIsEdit: true,

      // 当前定时大任务的开始和结束日期
      startAndEndDate: [],
      stateID:'',
      selectID:'',
      listenState:false,
      serverDateTime:''
    };
  },
  components: {
    GetTime,
    AddSubTaskP,
    AddTaskP,
  },
  created () {
    this.initWebSocket();
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件  
		window.addEventListener("resize", this.getHeight);  
		this.getHeight();
  },
  mounted() {
    this.getTask();
    this.getStars();
    this.getRegions();
    this.getSongs();
    this.getCheck();
    console.log(this.task,66336,this.task.id,this.stateID)
    if (JSON.parse(sessionStorage.getItem("listenState4")) != null) {
      var listenState = JSON.parse(sessionStorage.getItem("listenState4"));
      if (
        listenState.id == this.task.id &&
        this.listenState == false
      ) {
        this.listenState = listenState.listenState;
      }
    }
  },
  watch: {
    timeTasks() {
      // 保证该大任务下是否有子任务才默认选中表格第一行
      if (this.timeTasks[0]) {
        this.$nextTick(() => {
          this.$refs.subTaskTable.setCurrentRow(this.timeTasks[0]);
          // 获得表格当前行的id
          this.currentRowId = this.timeTasks[0].id;
          // 获得表格当前的音量
          this.getPlanSubTask(this.timeTasks[0].id, false);
        });
      }
    },
  },
  methods: {
    dateFormat: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "/" + month + "/" + day;
    },
    dateFormat2: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return hours + ":" + minutes + ":" + seconds;
    },
    button1(){
      if(this.currentRowId==this.stateID){
      this.musicVol_switch = !this.musicVol_switch;
      console.log(this.selectID,987)
      if (this.musicVol_switch == true) {
          request({
            method: "put",
            url: "/broad/start/volume/2",
            data: [this.selectID],
          })
            .then((res) => {
              // this.musicVol_switch = false
              console.log(res);
              console.log("音乐开始接口成功", this.musicVol_switch);
            })
            .catch((err) => {
              console.log(err);
              console.log("失败");
            });
        }
        if (this.musicVol_switch == false) {
          request({
            method: "put",
            url: "/broad/stop/volume/2",
            data: [this.selectID],
          })
            .then((res) => {
              // this.musicVol_switch = true;
              sessionStorage.setItem("taskstate", false);
              console.log(res);
              console.log("音乐关闭接口成功");
            })
            .catch((err) => {
              console.log(err);
              console.log("失败");
            });
        }
    }else{
    this.musicVol_switch =true
    }
    console.log(this.stateID,'============')
  }
    ,
    button2(){
      this.headsetVol_switch = !this.headsetVol_switch;
      console.log(987)
    },
    getHeight() {    
		this.defaultHeight.height = window.innerHeight - 90 + "px";  
		},
    // 单击表格的行
    rowClick(row) {
      this.currentRowId = row.id;
      // 获得音量值
      if(this.currentRowId!=this.stateID){
        this.musicVol_switch =true
      }
      if(this.currentRowId==this.stateID){
        // this.musicVol_switch=!this.taskState
        if(JSON.parse(sessionStorage.getItem("taskstate"))!=null){
        this.musicVol_switch=sessionStorage.getItem("taskstate");
      }
    }
      this.getPlanSubTask(row.id, false);
      console.log(123,this.taskState)
    },
    monitorButton(id){
      this.listenState = !this.listenState;
      console.log(this.task.id,123)
      if (this.listenState == true) {
        request({
          method: "put",
          url: "/atask/start/listen/" + this.task.id,
        })
          .then((res) => {
            console.log("监听任务”接口成功", res);
            let list = {
              listenState: this.listenState,
              id: id,
            };
            let jsonlist = JSON.stringify(list);
            sessionStorage.setItem("listenState4", jsonlist);
            if (res.status == 200) {
              this.$message({
                message: "开始监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务”接口失败");
          });
        console.log(this.task.id, 123);
      }
      if (this.listenState == false) {
        request({
          method: "put",
          url: "/atask/stop/listen/" + this.task.id,
        })
          .then((res) => {
            sessionStorage.removeItem("listenState4");
            console.log("监听任务停止”接口成功", res);
            if (res.status == 200) {
              this.$message({
                message: "结束监听任务",
                type: "success",
              });
            }
            // console.log("获得的任务：");
            // console.log(res);
            // this.aTask = res.data.data;
            // console.log("get:“/atask/{id}”接口成功");
          })
          .catch((err) => {
            console.log(err);
            console.log("监听任务停止”接口失败");
          });
        console.log(this.task.id, 123);
      }
    },
    open() {
      alert("33");
    },
    //任务编辑按钮
    editTask() {
      this.is_show_edit = true;
    },
    // 编辑任务的界面关闭之前
    handleClose() {
      this.is_show_add=false;
      this.is_show_edit=false
    },

    // 关闭界面清空输入框的内容（大任务）
    isShowAddTaskDialog(res) {
      this.is_show_edit = res;

      // // 关闭界面之前清空输入框的值
      // // 名称
      // this.task.name = "";
      // // 权限
      // this.task.priority = null;
      // // 类型
      // this.task.type = null;
    },

    // 关闭界面清空输入框的内容（子任务）
    isShowAddSubTaskDialog(res) {
      this.is_show_add = res;
      // 更改编辑按钮的颜色
      this.currentRowIndex = null;

      // 关闭界面之前清空输入框的值
      // this.initTimetask.id = null;
      // this.initTimetask.taskID = "";
      // this.initTimetask.planTime.id = "";
      // this.initTimetask.planTime.executerID = "";
    },

    // 将任务保存更新到数据库
    newTask(res) {
      this.newTaskObj = res;
      this.updateTask();
    },

    // 1 列表循环; 2 单曲循环;3 列表播放; 4 随机循环播
    // 单曲循环按钮
    single() {
      if (this.currentRowId != null) {
        this.putPlayMode(2);
      } else {
        this.$message.error("错了哦！请先选择定时任务");
      }
    },
    // 列表循环按钮
    list() {
      if (this.currentRowId != null) {
        this.putPlayMode(1);
      } else {
        this.$message.error("错了哦！请先选择定时任务");
      }
    },
    // 随机播放按钮
    random() {
      if (this.currentRowId != null) {
        this.putPlayMode(4);
      } else {
        this.$message.error("错了哦！请先选择定时任务");
      }
    },
    // 顺序播放按钮
    order() {
      if (this.currentRowId != null) {
        this.putPlayMode(3);
      } else {
        this.$message.error("错了哦！请先选择定时任务");
      }
    },

    //更改音乐音量
    changeMusicVol(vol) {
      // alert(vol);
      this.putVolume(2, this.currentRowId, vol);
    },
    //更改监听音量
    changeHeadsetVol(vol) {
      // alert(vol);
      this.putVolume(4, this.currentRowId, vol);
    },

    // 添加子任务按钮
    addLine() {
      this.is_show_add = true;
      this.add=true
    },

    // 表格中每一行的编辑按钮
    handleEdit(index, row) {
      console.log(index, row,6694);
      this.currentRowIndex = index;
      // 弹出编辑界面
      this.is_show_add = true;
      // 获得当前行的子任务
      this.getPlanSubTask(row.id, true);
    },

    // 表格中每一行的删除按钮
    handleDelete(index, row) {
      console.log(index, row);

      console.log(row.id);
      // 调用接口删除定时子任务
      this.deletePlanSubTask(row.id);
    },

    // 当前任务该显示的星星颗数
    getStars() {
      // this.permissionValue = this.task.priority / 2;

      if (this.task.priority == 0) {
        this.permissionValue = 1;
      } else if (this.task.priority == 2) {
        this.permissionValue = 2;
      } else if (this.task.priority == 4) {
        this.permissionValue = 3;
      } else {
        // this.$message({
        //   message: "获取不到权限，拍照联系前端开发查明原因！",
        //   type: "warning",
        // });
      }
    },

    // //打开弹框的事件
    // openDialog() {
    //   // alert("开前");
    // },
    // //关闭弹框的事件
    // closeDialog() {
    //   // this.xxx = ""; //清空数据
    //   // alert("关前");
    // },
    // //打开弹框的事件
    // openedDialog() {
    //   // alert("开后");
    // },
    // //关闭弹框的事件
    // closedDialog() {
    //   // this.xxx = ""; //清空数据
    //   // alert("关后");
    // },

    // 子定时任务界面的保存按钮
    currentTimeTask(v) {
      // 给对象的taskID的属性赋值
      v.taskID = this.task.id;
      // 首先检查给不给用户新建或更新
      // this.checkEligible(v);

      request({
        method: "put",
        url: "/atimetask/check1",
        data: v,
      })
        .then((res) => {
          console.log(res,11222333,v)
          if (JSON.stringify(res.data.data) == "{}") {
            // 调用接口新增或更新子定时任务
            // alert("添加成功" + JSON.stringify(res.data.data));
            v.taskID = this.task.id; //特殊，对象的属性值到这里为空了
            if(this.add == true){
              v.id=null
              // alert(123)
            }
            this.ifSave(v);
            console.log(v,'++++++++++++++++++++++++++++++++++++++++++++++')
          } else {
            // alert("添加失败" + JSON.stringify(res.data.data));
            console.log(v,'========')
            this.$message({
              message: '任务冲突，无法创建',
              type: "error",
            });
            // this.ifSave(v);
        //     this.$notify({
        //   title: '提示',
        //   message: '这是一条警告的提示消息',
        //   type: 'warning',
        //   duration: 0
        // });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // // 判断是添加还是更改
      // if (v.id == null || v.id == undefined) {
      //   // 任务音量（界面没有该值,也不能调节，但是默认要给数据库写入100）
      //   v.taskVol = 100;
      //   // 调用增加接口，提交到数据库
      //   this.addPlanSubTask(v);
      // } else {
      //   // 调用更改接口，提交到数据库
      //   this.updatePlanSubTask(v);
      // }

      // // 关闭界面之前清空输入框的值
      // this.initTimetask.id = null;
      // this.initTimetask.taskID = "";
      // this.initTimetask.planTime.id = "";
      // this.initTimetask.planTime.executerID = "";
    },
    getCheck() {
      let id={
        id:this.task.id
      }
      request({
        method: "put",
        url: "/atimetask/checkTask",
        data:id
      })
        .then((res) => {
          let all = [];
          let all1 = [];
          res.data.data.forEach((item) => {
            all.push(item.name);
            all1.push(item.task);
          });
          let list=Array.from(new Set(all))
          let list1=Array.from(new Set(all1))
          if(all!=false){
          this.$notify({
          title: '提示',
          message: `“${list1}”与【${list}】的小任务冲突`,
          type: 'warning',
          // duration: 0
        });
      }
          console.log(res.data.data,"/////////***********////////",all);
        })
        .catch((err) => {
          console.log(err,'----------------============');
        });
    },
    // 调用接口新增或更新子定时任务
    ifSave(v) {
      // 判断是添加还是更改
      if (v.id == null || v.id == undefined) {
        // 任务音量（界面没有该值,也不能调节，但是默认要给数据库写入100）
        v.taskVol = 100;
        // 调用增加接口，提交到数据库
        console.log(v,'[[[[[[[[[[[[[[[[[[[[[')
        this.addPlanSubTask(v);
      } else {
        // 调用更改接口，提交到数据库
        v.repeatMode=this.currentRepeatMode
        v.musicVol=this.musicVol
        console.log(v,']]]]]]]]]]]]]]]]]]]]')
        this.updatePlanSubTask(v);
      }

      // 关闭界面之前清空输入框的值
      // this.initTimetask.id = null;
      // this.initTimetask.taskID = "";
      // this.initTimetask.planTime.id = "";
      // this.initTimetask.planTime.executerID = "";
    },

    // 获得当前任务（防止刷新出现的bug）
    getTask() {
      if (typeof this.$route.query.task == "string") {
        //正常路由跳转过来后就把数据塞到 localStorage
        // console.log(this.$route.fullPath);
        sessionStorage.setItem("currentPlanTask", this.$route.query.task); //数据存storage，防止刷新丢失
        this.task = JSON.parse(this.$route.query.task);
      } else {
        //刷新时走这
        // 去localStorage取;
        this.task = JSON.parse(sessionStorage.getItem("currentPlanTask"));
      }

      this.getATimeTasks();
    },

    // 格式化分区
    formatRegions(row) {
      // console.log(row);
      let rowRegions = (row && row.regions) || [];
      let arr = [];
      rowRegions.forEach((item) => {
        arr.push(item.name);
      });
      return arr.join(",");
    },

    // 格式化歌曲
    formatSongs(row) {
      let rowSongs = (row && row.songs) || [];
      let arr = [];
      rowSongs.forEach((item) => {
        // console.log(item);
        arr.push(item.name);
      });
      return arr.join(",");
    },

    // 格式化任务周期
    //重复类型（一次,每天，周一到周五，自定义）
    formatTaskPeriod(row) {
      // let p = row.timing.repeatType;2021-07-19
      let p = row.repeatType;
      if (p == "0") {
        p = "一次";
      } else if (p == "1") {
        p = "每天";
      } else if (p == "2") {
        p = "周一到周五";
      } else if (p == "3") {
        p = "自定义";
      }
      // else {
      //   row.timing.repeatType = "其它";
      // }
      return p;
    },

    //通过大任务的id去数据库取该任务的所有数据（因为“/atask”接口查询全部任务，得不到大任务下的子任务）++++++++++++++++++++
    getATimeTasks() {
      request({
        method: "get",
        url: "/atask/" + this.task.id,
      })
        .then((res) => {
          // console.log(res.data.data.timetasks);
          this.task = res.data.data;
          console.log(this.task,8877);
          this.timeTasks = res.data.data.timetasks;

          this.getStars();
          // console.log("get:“/atask/{id}”接口成功");

          // 获得大任务的“开始日期”和“结束日期”
          let sDate = res.data.data.startDate;
          let eDate = res.data.data.endDate;
          this.startAndEndDate = [sDate, eDate];
        })
        .catch((err) => {
          console.log(err);
          console.log("get:“/atask/{id}”接口失败");
        });
    },

    // 编辑（大）定时任务++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    updateTask() {
      // this.task.collectState的值是从内存中得到的，并非数据库，所以不能保存进数据库
      delete this.task.collectState;

      request({
        method: "put",
        url: "/atask",
        data: this.task,
      })
        .then((res) => {
          // 判断是否数据库有重复数据
          if (res.data.data == null) {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          }

          // console.log(res);
          // console.log("put:“/atask”接口成功");
          this.getATimeTasks();
        })
        .catch((err) => {
          console.log(err);
          console.log("put:“/atask”接口失败");
        });
    },

    // 获得所有的分区
    getRegions() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          // console.log(res.data.data);
          this.regions = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获得所有的歌曲
    getSongs() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          // console.log(res.data.data);
          this.songs = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 新建或更改子定时任务之前首先判断它跟别的子定时任务时间是否有冲突
    checkEligible(obj) {
      request({
        method: "put",
        url: "/atimetask/check1",
        data: obj,
      })
        .then((res) => {
          if (JSON.stringify(res.data.data) == "{}") {
            // 调用接口新增或更新子定时任务
            alert("可以添加");
            // this.ifSave(obj);
            resolve(JSON.stringify(res.data.data));
          } else {
            alert("不许添加");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 添加定时子任务
    addPlanSubTask(obj) {
      request({
        method: "post",
        url: "/atimetask",
        data: obj,
      })
        .then((res) => {
          if(res.data.resultCode==200){
            this.add = false
               this.$message({
              message: '添加成功',
              type: "success",
            });
            this.getCheck();
          }
          console.log(res,obj,6666);
          // 更新界面
          this.getATimeTasks();

          // 判断是否数据库有重复数据
          if (res.data.resultCode==50000) {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 更改定时子任务
    updatePlanSubTask(obj) {
      request({
        method: "put",
        url: "/atimetask",
        data: obj,
      })
        .then((res) => {
          console.log(res,obj,this.currentRepeatMode,'9966---------------');
          if(res.status==200){
               this.$message({
              message: '修改成功',
              type: "success",
            });
            this.getCheck();
          }
          this.getATimeTasks();
          
          // 判断是否数据库有重复数据
          if (res.data.data == null) {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err,obj);
        });
    },

    // 删除定时子任务
    deletePlanSubTask(id) {
      request({
        method: "delete",
        url: "/atimetask/" + id,
      })
        .then((res) => {
          if(res.status==200){
               this.$message({
              message: '删除成功',
              type: "success",
            });
          }
          console.log(res,'删除接口');
          this.getATimeTasks();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查询定时子任务
    getPlanSubTask(id, i) {
      request({
        method: "get",
        url: "/atimetask/" + id,
      })
        .then((res) => {
          console.log(res.data.data,99999999666,i,this.currentRepeatMode);
          // 单击编辑按钮
          if (i == true) {
            this.initTimetask = res.data.data;
          }
          // 单击行非编辑按钮以外的地方
          else {
            // 音乐音量
            this.musicVol = res.data.data.musicVol;
            // 监听音量
            this.headsetVol = res.data.data.headsetVol;

            // 当前选择的行（播放模式）
            this.currentRepeatMode = res.data.data.repeatMode;
            this.selectID=res.data.data.id
            console.log("我的id:" + res.data.data.id);
            console.log("模式：" + res.data.data.repeatMode);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 更改音量接口("/broad/volume{volumeType}/{timeTaskID}/{volume}")
    // timeTaskID - 任务id
    // volumeType - 音量类型 1 麦音量，2 音乐音量，3 任务音量 ，4 监听音量
    // volume - 音量值
    putVolume(volumeType, timeTaskID, volume) {
      request({
        method: "put",
        url: "/broad/volume/" + volumeType + "/" + volume,
        data: [timeTaskID],
      })
        .then((res) => {
            this.$message({
              message: '修改音量成功',
              type: "success",
            });
          console.log(res);
          console.log("put:“/broad/volume/{volumeType}/{volume}”接口成功");

        })
        .catch((err) => {
          console.log(err);
          console.log("put:“/broad/volume/{volumeType}/{volume}”接口失败");
        });
    },

    // 更改歌曲的播放模式（“/broad/repeatMode/{repeatMode}”）
    putPlayMode(repeatMode) {
      request({
        method: "put",
        url: "/broad/repeatMode/" + repeatMode,
        data: [this.currentRowId],
      })
        .then((res) => {
          console.log(res);
          this.getPlanSubTask(this.currentRowId, false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
     initWebSocket() {
      // let token = JSON.parse(sessionStorage.getItem("token"));
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
        //  let wsuri = "ws://106.15.40.26:9004/chat";
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //连接建立之后执行send方法发送数据
    websocketonopen() {
      // var token = JSON.parse(sessionStorage.getItem("token"));
      // let actions = "timetaskid:" + this.task.timetasks[0].id; 旧
      let actions = {
        // token: "222",
        // token: token,
        dataType: "timetasks:",
        ts: [this.task.id],
      };
      this.websocketsend(JSON.stringify(actions));
      console.log(actions);
    },

    //数据发送
    websocketsend(Data) {
      this.timer = setInterval(() => {
        this.websock.send(Data);
        console.log("定时");
      }, 1000);
      // this.websock.send(Data);
    },

    //数据接收
    websocketonmessage(e) {
      this.serverDateTime=JSON.parse(e.data).serverDateTime
      this.stateID=JSON.parse(e.data).ts[0].id
      this.taskState=JSON.parse(e.data).ts[0].taskState
      if(JSON.parse(e.data).ts[0].id==undefined){
        sessionStorage.removeItem("taskstate")
        this.musicVol_switch=true
      }
      //console.log(JSON.parse(e.data).ts[0].id,JSON.parse(e.data).ts[0].taskState,JSON.parse(e.data),'返回的值');
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },
  },

  //离开路由之后断开websocket连接
  destroyed() {
    this.websock.close();
    clearInterval(this.timer);
    // this.close()
    // this.miVol_switch=false
  },
};
</script>

<style  scoped>
.bGcolor {
  background-color: #409eff;
  color: #d7ebff;
}
.el-header {
  /* background-color: #b3c0d1; */
  background: linear-gradient(to top, #353757, #fff);
  color: #333;
  text-align: center;
  line-height: 40px;
  border-bottom-style: groove;
  border-bottom-color: #333;
  border-bottom-width: 1px;
}
.el-footer {
  /* background-color: #b3c0d1; */
   background-color: #353757;
  color: #333;
  text-align: center;
  line-height: 40px;
}

.el-aside {
  /* background-color: #d3dce6; */
   background-color: #353757;
  color: #fff;
  text-align: center;
  line-height: 200px;
  height: 93vh !important;
}

/* 更改表头的样式 */
.el-main {
  background-color: #e9eef3;
  padding: 10px 2px 0px 2px;
  height: calc(104vh - 70px);
}

.a1 {
  float: left;
}
.a2 {
  /* float: right; */
  /* display: flex;
   justify-content:center */
   margin-left: 300px;
   color: black;
}
.a3 {
  float: right;
}
.a4 {
  margin-top: 50%;
  padding-left: 26%;
  /* background-color: blanchedalmond; */
  width: 76px;
  margin-bottom: 10%;
}

.a5 {
  line-height: 50px;
}
.a6 {
  line-height: 40px;
  /* background-color: blanchedalmond; */
}
.a7 {
  margin-top: 15%;
}
.a8 {
  float: right;
}
.a9 {
  color: #000;
  width: 100%;
  height: 640px;
}
.active {
  background-color: rgb(161, 187, 218);
}

.button1 {
  background-image: url("../../assets/image/volume/musicVol_on.png");
  background-repeat: no-repeat;
  background-size: 60%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button1-1 {
  background-image: url("../../assets/image/volume/musicVol_off.png");
  background-repeat: no-repeat;
  background-size: 60%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button2 {
  background-image: url("../../assets/image/volume/headsetVol_on.png");
  background-repeat: no-repeat;
  background-size: 68%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
.button2-2 {
  background-image: url("../../assets/image/volume/headsetVol_off.png");
  background-repeat: no-repeat;
  background-size: 68%;
  width: 36px;
  height: 36px;
  background-position-x: center;
  background-position-y: center;
  border: 1px solid;
}
::v-deep .el-dialog{
  width: 720px;
}
.serverDateTime{
  /* color: #fff; */
  font-size: 17px;
}
::v-deep .el-notification__content{
  text-align: end;
}
::v-deep .el-table__body tr.current-row>td{
  background-color: #6390c3;
}

/* ::v-deep .el-table tbody tr:hover>td { 
    background-color:#6390c3 !important;
} */
</style>
