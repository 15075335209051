<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" @click="addLine()" round
          >添加</el-button
        >
      </span>
      <el-dialog
        v-dialogDrag
        :title="add==true?'添加页面':'编辑页面'"
        :visible.sync="is_show"
        center
        :append-to-body="true"
        :lock-scroll="false"
        width="600px"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
      <template>
  <div>
    <!-- <h1>{{ msg }}</h1> -->
      <div>
        <el-button  :style="isActive==0?'color: #409EFF':''" class="tabs" @click="isActive1()">音箱</el-button>
      <el-button :style="isActive==1?'color: #409EFF':''" class="tabs" @click="isActive2()">播控单元</el-button>
      <div style="width: 100%;background-color: #CACDD1;height: 1px;"></div>
    </div>
 
      <div>
        <div class="bodys" v-if="isActive==0">
          <div style="margin-top: 20px;margin-left: 0px;" class="z1">
        <span class="z2">对讲集群名称：</span>
        <el-input
        class="z3"
        style="width: 70%;"
          placeholder="请输入对讲集群名称"
          v-model="groupname"
          clearable
        >
        </el-input>
      </div>
          <div style="text-align: left;margin-left: -31px;margin-top: 30px;">
          <el-transfer
          filterable
          filter-placeholder="请输入搜索的音箱"
          v-model="selectSpeakers"
          :data="allSpeakersData"
          :titles="['可选音箱', '已选音箱']"
          target-order="push"
        >
        </el-transfer>
        </div>
        </div>
      <div class="bodys" v-else-if="isActive==1">
        <div style="text-align: left;margin-left: -31px;margin-top: 80px;">
          <el-transfer
          filterable
          filter-placeholder="请输入搜索的播控单元"
          v-model="selectMics"
          :data="allMicsData"
          :titles="['可选播控单元', '已选播控单元']"
          target-order="push"
        >
        </el-transfer>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button class="a6" @click="cancelButton" size="mini" round>
          取消
        </el-button>
      <el-button
          class="a6"
          @click="saveAddTask"
          size="mini"
          type="primary"
          round
        >
          保存
        </el-button>
    </div>
  </div>
</template>
      </el-dialog>
    </div>
    <div>
      <template>
        <el-table
          :data="tableData"
          style="width: 100%; line-height: 10px"
          height="78vh"
          
        >
          <el-table-column width="25"></el-table-column>
          <el-table-column label="序号" align="center" type="index" width="50">
          </el-table-column>
          <el-table-column label="对讲集群名称" align="center" prop="regionName">
          </el-table-column>
          <el-table-column label="音箱" align="center" prop="sip">
            <template slot-scope="scope">
              <el-dropdown>
                <el-button size="mini" type="success">
                    所选音箱IP<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                   <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in scope.row.sdevAddr">{{item.ip}}</el-dropdown-item>
                   </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column label="播控单元" align="center" prop="mip">
            <template slot-scope="scope">
              <el-dropdown>
                <el-button size="mini" type="success">
                  所选播控单元IP<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                   <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in scope.row.mdevAddr">{{item.ip}}</el-dropdown-item>
                   </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                size="mini"
                @click="handleEdit(scope.row)"
                type="primary"
              >
                编辑
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row.regionId)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
import { json } from "body-parser";
import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      is_show:false,
      isActive:0,
      allSpeakers:[],
      allSpeakersData:[],
      allMics:[],
      selectSpeakers:[],
      selectMics:[],
      add:'',
      tableData:[],
      groupname:'',
      currentTask:{
          speakerMicList:{}
      }
    };
  },
  mounted() {
    this.getAllSpeakers()
    this.getAllMics()
    this. aspeakerMic()
  },
  methods: {
    aspeakerMic(){
      request({
        url: "/aspeakerMic/list",
      })
        .then((res) => {
          this.tableData=res.data.data
          console.log(res.data.data);
          console.log("查询全部音箱");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addLine() {
      this.isActive=0
      this.is_show=true
      this.groupname=''
      this.selectSpeakers=[]
      this.selectMics=[]
      this.add=true
    },

    handleEdit(row) {
      this.isActive=0
      this.currentTask=row
      this.selectSpeakers = this.speakersArray();
      this.selectMics=this.micsArray()
      this.is_show=true
      this.add=false
      this.groupname=row.regionName
      this.regionId=row.regionId
      console.log("我要修改",row,this.selectSpeakers);
    },
    handleDelete(index, id) {
      console.log(index, id);
      this.$confirm('是否确认删除该任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
      request({
        method: "delete",
        url: "/aspeakerMic/deletet/" + id,
        // data: "", //+++++++++++++++++++++++++++++++++++++++++++++++++++++
        // data: rSpeakers,
      })
        .then((res) => {
          console.log(res, 2222222222222);
          // 删除一行数据
          if (res.data.resultCode == 200) {
            this.tableData.splice(index, 1);
            this.$message.success("数据删除成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err,9998);
        });
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          });          
        });
      },
    isActive1(){
      this.isActive=0
      console.log(this.isActive)
    },
    isActive2(){
      this.isActive=1
      console.log(this.isActive)
    },
    cancelButton(){
      this.is_show=false
    },

    saveAddTask(){
        if(this.add==true){
          var list={
            "regionName":this.groupname,
            "micId":this.selectMics,
            "speakerId":this.selectSpeakers
          }
          if(this.groupname!=''&&this.selectMics!=''&&this.selectSpeakers!=''){
            request({
        method: "put",
        url: "/aspeakerMic/insert",
        data: JSON.stringify(list)
      })
        .then((res) => {
          this.is_show = false;
          console.log("添加成功",res,);
          if (res.data.resultCode == 200) {
            this.aspeakerMic()
            this.$message.success("数据添加成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
          }else{
            this.$message.warning("还有选项未选择，请继续选择！");
          }
        }
        if(this.add==false){
          var list={
            "regionName":this.groupname,
            "micId":this.selectMics,
            "speakerId":this.selectSpeakers,
            "regionId":this.regionId
          }
          if(this.groupname!=''&&this.selectMics!=''&&this.selectSpeakers!=''){
            request({
        method: "put",
        url: "/aspeakerMic/updated",
        data: list
      })
        .then((res) => {
          this.is_show = false;
          console.log("修改成功",res,);
          if (res.data.resultCode == 200) {
            this.aspeakerMic()
            this.$message.success("数据修改成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
          }else{
            this.$message.warning("还有选项未选择，请继续选择！");
          }
        }
   },
   getAllSpeakers() {
      request({
        method: "get",
        url: "/aspeaker/dialogSpeakers",
      })
        .then((res) => {
          this.allSpeakers = res.data.data;
          this.allSpeakersData=[]
          const Speakers = this.allSpeakers;
          Speakers.forEach((allSpeakersip) => {
          this.allSpeakersData.push({
          label: allSpeakersip.devAddr+"("+allSpeakersip.ip+")",
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: allSpeakersip.id,
          // sindex: index,
        });
      });
          console.log("全部音箱",res,this.allSpeakers);
          // console.log(res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("全部音箱接口失败");
        });
    },
    getAllMics() {
      request({
        method: "get",
        url: "/aspeaker/dialogMis",
      })
        .then((res) => {
          this.allMics = res.data.data;
          this.allMicsData=[]
          const Mics = this.allMics;
          Mics.forEach((allMicsip) => {
          this.allMicsData.push({
          label: allMicsip.devAddr+"("+allMicsip.ip+")",
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: allMicsip.id,
          // sindex: index,
        });
      });
          console.log("全部麦克风",res,this.allMics.ip);
          // console.log(res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
    getSpeakers() {
      let data = [];
      for (let i = 0; i < this.selectSpeakers.length; i++) {
        for (let j = 0; j < this.allSpeakers.length; j++) {
          if (this.allSpeakers[j].id == this.selectSpeakers[i]) {
            this.allSpeakers[j].index = i + 1;
            data.push(this.allSpeakers[j]);
          }
        }
      }
      return data;
    },
    getMics() {
      let data = [];
      for (let i = 0; i < this.selectMics.length; i++) {
        for (let j = 0; j < this.allMics.length; j++) {
          if (this.allMics[j].id == this.selectMics[i]) {
            this.allMics[j].index = i + 1;
            data.push(this.allMics[j]);
          }
        }
      }
      return data;
    },
    speakersArray() {
      let d = [];
      // this.currentTask.timetasks[0].songs.forEach((song) => {
      //   d.push(song.id);
      // });
      for (let i = 0; i < this.currentTask.sdevAddr.length; i++) {
        d.push(this.currentTask.sdevAddr[i].id);
      }
      console.log(d,66998877)
      return d
    },
    micsArray(){
      let d = [];
      for (let i = 0; i < this.currentTask.mdevAddr.length; i++) {
        d.push(this.currentTask.mdevAddr[i].id);
      }
      console.log(d,66998877)
      return d
    },
  },
};
</script>
<style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
::v-deep .el-dialog__body {
  padding-bottom: 10px;
}
.a1 {
  line-height: 30px;
  width: 400px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
.a3 {
  text-align: center;
  width: 100%;
  /* background-color: peachpuff; */
  /* line-height: 80px; */
  margin-bottom: 20px;
}
.a4 {
  width: 300px;
}
.a5 {
  margin: 20px;
  /* background-color: rgb(164, 231, 226); */
}
::v-deep .el-dialog__header{
  padding: 20px 20px 0px;
}
::v-deep .el-dialog--center .el-dialog__body{
  padding: 0px 20px;
}
::v-deep .el-dialog__body{
  height: 560px;
}
.tabs{
     /* background-color: rgb(141, 228, 250); */
     width:70px;
     /* line-height:30px; */
     margin-left: 0px !important;
     border: 0px;
   }
.tabs:hover{
  background-color: #ffffff;
  color: #606266;
}
.tabs:focus {
  background-color: #ffffff;
}
.bodys{
     /* background-color: rgb(141, 228, 250); */
     width:480px;
     line-height:30px;
     margin: auto;
     margin-top: 30px;
   }
.z1 {
  display: flex;
  line-height: 60px;
  margin-left: 40px;
  margin-top: 10px;
}
.z2 {
  /* flex: 1;
  text-align-last: justify; */
  width: 100px;
  text-align: right;
  margin-right: 20px;
}
.z3 {
  width: 50%;
}
.btn{
  position: absolute;
  right: 50px;
  bottom: 30px;
}
::v-deep .el-select__tags{
  top: 28%;
}
.el-transfer {
  font-size: 14px;
  height: 200px;
  width: 590px;
  /* 搜索框的位置 */
  line-height: 0px;
  margin: auto;
}
::v-deep .el-transfer__buttons{
  padding: 0 10px;
}
::v-deep .el-transfer{
  height:290px !important;
}
.el-dropdown {
    vertical-align: top;
  }
  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  ::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
</style>