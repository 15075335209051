import { render, staticRenderFns } from "./subtaskD.vue?vue&type=template&id=8d46b854&scoped=true&"
import script from "./subtaskD.vue?vue&type=script&lang=js&"
export * from "./subtaskD.vue?vue&type=script&lang=js&"
import style0 from "./subtaskD.vue?vue&type=style&index=0&id=8d46b854&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d46b854",
  null
  
)

export default component.exports