<template>
  <div id="app">
    <!-- <div>{{ regionSpeakersId }}</div> -->
    <!-- <div>{{ regionSpeakers }}</div> -->
    <urgent></urgent>
    <el-header style="text-align: left; font-size: 12px">
      <el-button round size="mini" icon="el-icon-view" @click="check">
        检测
      </el-button>
      <!-- <el-button round size="mini"  @click="check1">
        图表
      </el-button>
      <el-button round size="mini"  @click="check2">
        视图
      </el-button> -->
    </el-header>
    <el-container style="height: 580px; border: 1px solid #c0c4cc" v-show="view==false">
      <el-aside width="200px" style="background-color: #ffffff">
        <!-- :default-openeds="['2']" -->
        <el-menu
          default-active="1"
          @select="handleSelect"
          @close="handleClose"
          @open="handleOpen"
          active-text-color="#409EFF"
        >
          <el-menu-item index="1">
            <i class="el-icon-s-grid"></i>
            所有音箱
          </el-menu-item>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-menu"></i>
              选择分区
            </template>
            <el-menu-item
              v-for="item in allZones"
              :key="item.id"
              :index="item.id.toString()"
            >
              {{ item.name }}
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>

      <el-container>
        <el-main :style="defaultHeight">
          <el-table
            style="background-color: #ffffff"
            :data="regionSpeakers"
            @selection-change="handleSelectionChange"
            stripe
            :row-key="regionSpeakers=>regionSpeakers.id"
            border
            height="78vh"
          >
            <!-- 复选框 -->
            <el-table-column
              :resizable="false"
              width="50"
              type="selection"
              :reserve-selection="true"
            ></el-table-column>
            <!-- 序号 -->
            <!-- <el-table-column prop="" label="序号" width="50"> </el-table-column> -->
            <!-- IP地址 -->
            <el-table-column label="序号" align="center" type="index" width="60">
          </el-table-column>
            <el-table-column
              :resizable="false"
              prop="ip"
              label="IP地址"
              align="center"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <!-- 设备位置 -->
            <el-table-column
              :resizable="false"
              show-overflow-tooltip
              prop="devAddr"
              label="设备位置"
              align="center"
              width="100"
            ></el-table-column>
            <el-table-column
              :resizable="false"
              prop="localVol"
              label="音量"
              width="60"
              align="center"
            ></el-table-column>
            <!-- 设备类型 -->
            <el-table-column
              :resizable="false"
              prop="speakerModel"
              show-overflow-tooltip
              label="设备类型"
              align="center"
              width="100"
            >
            </el-table-column>
            <!-- 网络状态 -->
            <el-table-column
              :resizable="false"
              prop="speakerFlag"
              label="音响状态"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
               <span v-if="scope.row.speakerType == 9">--</span>
               <span v-else-if="scope.row.speakerFlag == 1">正常</span>
               <span v-else>异常</span>
               <!-- <span v-if=" scope.row.speakerFlag == 1&&scope.row.speakerType != 9">正常</span>
               <span v-if=" scope.row.speakerFlag == 0&&scope.row.speakerType != 9">异常</span> -->
              </template>
            </el-table-column>
            <!-- 音响状态 -->
            <el-table-column
              :resizable="false"
              prop="lastOnlineTime"
              label="网络状态"
              align="center"
              width="80"
            >
              <template slot-scope="scope">
                <span
                  :style="
                    scope.row.lastOnlineTime > '2' ? 'color:red' : 'color:#6baa4c'
                  "
                  >{{ scope.row.lastOnlineTime > "2" ? "离线" : "在线" }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="power"
              label="电源"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="sig"
              label="信号"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="vHundred"
              label="定压&定阻"
              align="center"
              width="90"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="prot"
              label="自保护"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="clip"
              label="过载"
              align="center"
              width="65"
            >
              <template slot-scope="scope">
                <span>{{
                  scope.row.clip == "5"||scope.row.clip == "9"? '失真' : scope.row.clip == "-1"?"--":"未失真"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="temperature"
              label="温度"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="softVer"
              label="固件版本"
              align="center"
              width="100"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="mac"
              label="物理地址"
              align="center"
              width="120"
            >
            </el-table-column>
            <el-table-column
              :resizable="false"
              prop="timeTaskName"
              label="执行任务"
              show-overflow-tooltip
              align="center"
              width="80"
            >
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
    <div  v-show="view==true">
    <div ref="chart" style="width:800px;height:376px"></div>
    <div ref="chart1" style="width:800px;height:376px"></div>
  </div>
  </div>
</template>

<script>
import { request } from "../../network/request";
import JSONBig from "json-bigint";

export default {
  name: "app",
  data() {
    return {
      offline:[],
      online:[],
      view:false,
      defaultHeight: {     
			height: ""    
			},
      // 存放已选的IP
      multipleSelection: [],
      // 存放已勾选选的IP音箱对应的id
      speakersId: [],
      // 存放已选分区下的 音箱ID集合
      regionSpeakersId: [],

      // 所有可选的分区
      allZones: [],
      // 当前点击选中的分区ID
      regionId: "",
      // 某一个分区下的所有音箱
      regionSpeakers: [],
      // 所有的音箱
      allSpeakers: [],
      timer: null,
      select:false,
      on:[],
      off:[]
    };
  },
  created() {
    this.getAllZones();
    this.getAllSpeakers();
    this.getAllspeaker()
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件  
		// window.addEventListener("resize", this.getHeight);  
		// this.getHeight();
  },
  beforeMount() {
    this.initWebSocket();
  },
  mounted() {
    
    this.getEchartData1()
    // this.initWebSocket();
    setInterval(() => {
      this.getEchartData()
      this.on=this.online
      this.off=this.offline
      }, 1000);
      console.log(this.online,this.offline,this.regionSpeakers,'\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\')
   
  },
  components: {},
  methods: {
    getEchartData() {
        const chart = this.$refs.chart
        if (chart) {
          const myChart = this.$echarts.init(chart)
          const option = {
            tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '5%',
    left: 'center'
  },
  color:['#95D278','#EE6666'],
  series: [
    {
      name: '设备数量',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2
      },
      label: {
        show: false,
        position: 'center'
      },
      emphasis: {
        label: {
          show: true,
          fontSize: 40,
          fontWeight: 'bold'
        }
      },
      labelLine: {
        show: false
      },
      data: [
        { value: this.on, name: '在线' },
        { value: this.off, name: '离线' },
      ]
    }
  ]
           }
          myChart.setOption(option)
          window.addEventListener("resize", function() {
            myChart.resize()
          })
        }
        this.$on('hook:destroyed',()=>{
          window.removeEventListener("resize", function() {
            myChart.resize();
          });
        })
      },
      getEchartData1() {
        const chart1 = this.$refs.chart1
        if (chart1) {
          const myChart = this.$echarts.init(chart1)
          const option = {
            title: {
              text: 'Stacked Line'
            },
            tooltip: {
              trigger: 'axis'
            },
            legend: {
              data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月','八月','九月','十月','十一月','十二月']
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: 'Email',
                type: 'line',
                stack: 'Total',
                data: [120, 132, 101, 134, 90, 230, 210,120, 132, 101, 134, 90, 230]
              },
              {
                name: 'Union Ads',
                type: 'line',
                stack: 'Total',
                data: [220, 182, 191, 234, 290, 330, 310,220, 182, 191, 234, 290, 330]
              },
              {
                name: 'Video Ads',
                type: 'line',
                stack: 'Total',
                data: [150, 232, 201, 154, 190, 330, 410,150, 232, 201, 154, 190, 330]
              },
              {
                name: 'Direct',
                type: 'line',
                stack: 'Total',
                data: [320, 332, 301, 334, 390, 330, 320,320, 332, 301, 334, 390, 330]
              },
              {
                name: 'Search Engine',
                type: 'line',
                stack: 'Total',
                data: [820, 932, 901, 934, 1290, 1330, 1320,820, 932, 901, 934, 1290, 1330]
              }
            ]

          }
          myChart.setOption(option)
          window.addEventListener("resize", function() {
            myChart.resize()
          })
        }
        this.$on('hook:destroyed',()=>{
          window.removeEventListener("resize", function() {
            myChart.resize();
          });
        })
      },
    check1(){
      this.view=false
    },
    check2(){
      this.view=true
    },
    getAllspeaker(){
      request({
        method: "get",
        url: "/aspeaker/state",
      })
        .then((res) => {
          //  获得的分区
          // this.allZones = res.data.data;
          console.log(res.data.data);
          console.log("攻放初始化成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("攻放初始化失败");
        });
    },
    // 调用接口得到所有分区+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          //  获得的分区
          this.allZones = res.data.data;
          console.log(res.data.data);
          console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },

    // 调用接口得到某一个分区下的所有音箱+++++++++++++++++++++++++++++++++++++++++++++++++++++
    getAllZone() {
      request({
        method: "get",
        url: "/aregion/" + this.regionId,
      })
        .then((res) => {
          this.regionSpeakers = res.data.data.speakers;
          // console.log("get:”/aregion/id”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion/id”接口失败");
        });
    },

    // new Promise((resolve,reject)=>{    })

    // 获取所选分区下的所有音箱ID
    getRegionSpeakersId() {
      this.regionSpeakersId = [];
      // this.regionSpeakers.forEach((e) => {
      //   this.regionSpeakersId.push(e.id.toString());
      // });
    },

    // 调用接口得到数据库所有的音箱+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    getAllSpeakers() {
      request({
        method: "get",
        url: "/aspeaker",
      })
        .then((res) => {
          console.log(res.data.data);
          // this.allSpeakers = res.data.data;

          this.allSpeakers = this.changeIdType(res.data.data);

          // 默认显示所有的音箱
          this.regionSpeakers = this.allSpeakers;
          console.log("get:”/aspeaker”接口成功", this.speakersId);
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aspeaker”接口失败");
        });
    },

    // 将数据库的所有音箱的id全部转换成string类型
    changeIdType(arrays) {
      let newArray = [];
      arrays.forEach((e) => {
        e.id = e.id.toString();
        newArray.push(e);
      });
      return newArray;
    },

    // 检测按钮++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    check() {
      request({
        method: "put",
        url: "/aspeaker/check",
        data: this.speakersId,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 选择折叠选项，让音箱在表格中显示----------------------------------------------------
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
      this.regionId = key;
      // 判断选择的是“所有音箱”还是“选择分区”
      if (key == "1") {
        //选择“所有音箱”
        this.select=false
        this.getAllSpeakers();
        // 选择分区时，刷新表格内容
        this.regionSpeakers = this.webSocketValue;
      } else if (key == "2") {
        alert("既没有选择‘所有分区’，也没有选择‘选择分区’");
      } else {
        //选择“所有分区”
        // this.getAllZone();
        // 处理异步带来的问题
        new Promise((resolve, reject) => {
          this.select=true
          this.getAllZone();
        }).then(() => {
          // 选择分区时，刷新表格内容
          this.regionSpeakers = this.webSocketValue;
        });
      }
      // 获取所选分区下的所有音箱ID
      this.getRegionSpeakersId();
      // 选择分区时，刷新表格内容
      // this.regionSpeakers = this.webSocketValue; //##############
    },
    // 关闭折叠---------------------------------------------------------------------------
    handleClose(key, keyPath) {
      console.log(key, keyPath);
      // this.regionSpeakers = this.allSpeakers;
    },
    // 展开折叠--------------------------------------------------------------------------
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      // this.regionSpeakers = this.allSpeakers;
    },
    // 表格中勾选的IP----------------------------------------------------------------------
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
      this.getSpeakersId();
    },
    // 表格中勾选的IP音箱对应的id
    getSpeakersId() {
      this.speakersId = [];
      // 获得所有音箱的id
      this.multipleSelection.forEach((e) => {
        this.speakersId.push(e.id.toString());
      });
      // console.log(this.speakersId);
    },

    // https://www.cnblogs.com/showcase/p/14000199.html
    //初始化websocket
    initWebSocket() {
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //连接建立之后执行send方法发送数据
    websocketonopen() {
      console.log("连接成功............");
      let token = JSON.parse(sessionStorage.getItem("token"));
      let actions = {
        // token: "222",
        token: token,
        dataType: "speaker:",
        ts: this.regionSpeakersId,
      };
      this.websocketsend(JSON.stringify(actions));
      // console.log("我正在建立连接：");
    },

    //数据发送
    websocketsend(Data) {
      this.timer = setInterval(() => {
        this.websock.send(Data);
        console.log("检测");
      }, 1000);
      // this.websock.send(Data);
    },

    //数据接收
    websocketonmessage(e) {
      this.lasttime=JSON.parse(e.data).ts;
      // 离线 offline
      var all1 = [];
      // 在线 online
      var all2 = [];
      this.lasttime.forEach((item) => {
        if(item.lastOnlineTime>2){
          all1.push(item.lastOnlineTime);
        }else
        all2.push(item.lastOnlineTime);
          });
      this.offline=all1.length
      this.online=all2.length
      // console.log(JSON.parse(e.data),this.offline,this.online);
      // this.webSocketValue = JSON.parse(e.data).ts;
      // setTimeout(() => {
      // try {
      //   // 如果转换成功则返回转换的数据结果
      //   // JSONBig.parse()用来处理大数
      //   let i = JSONBig.parse(e); // 解决精度损失
      //   console.log(JSON.parse(i.data).ts);
      //   this.webSocketValue = JSON.parse(i.data).ts;
      //   return this.webSocketValue;
      // } catch (err) {
      //   // 如果转换失败，则包装为统一数据格式并返回
      //   return {
      //     e,
      //   };
      // }
      // this.webSocketValue = JSONBig.parse(e.data).ts;
      // }, 1000);
      if(this.select==false){
        this.regionSpeakers=JSONBig.parse(e.data).ts
      }
      this.webSocketValue = JSONBig.parse(e.data).ts;
      // console.log(e.data)
      // console.log(this.webSocketValue,6666666666666666666666666666)
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },
  },
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.el-header {
  /* background-color: #b3c0d1; */
  color: #333;
  line-height: 60px;
  padding: 0px;
}

.el-aside {
  color: #333;
  height: 80vh !important;
}

/* 更改表头的高度 */
.el-main {
  line-height: 40px;
  padding: 0px 0px 0px 10px;
  height: calc(90vh - 70px);
}
::v-deep .el-checkbox__inner {
  border: 1px solid #c0c4cc;
}
</style>
