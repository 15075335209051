// 添加定时任务界面（弹框界面）
<template>
  <div id="app">
    <div>
      <!-- 任务名称 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务名称：" :label-width="labelWidth">
            <el-input
              id="name"
              v-model="createNewTask.name"
              size="mini"
              @blur="legalTaskName"
              maxlength="9"
              show-word-limit
              placeholder="任务名称由“中文、英文、数字”组成"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 任务类型 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务类型：" :label-width="labelWidth">
            <el-select
              v-model="currentSelectTaskType"
              placeholder="请选择"
              class="a2"
              size="mini"
            >
              <el-option
                v-for="taskType in taskTypes"
                :key="taskType.id"
                :label="taskType.label"
                :value="taskType.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 开始日期 -->
      <el-row class="a4" v-show="currentSelectTaskType==4">
        <el-form>
          <el-form-item label="开始日期：" :label-width="labelWidth">
            <el-date-picker
              v-model="startDate"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              size="mini"
              class="a5"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 结束日期 -->
      <el-row class="a4" v-show="currentSelectTaskType==4">
        <el-form>
          <el-form-item label="结束日期：" :label-width="labelWidth">
            <el-date-picker
              v-model="endDate"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              size="mini"
              class="a5"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 权限 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="权限：" :label-width="labelWidth">
            <el-rate
              class="a3"
              v-model="permissionValue"
              :max="3"
              show-text
              :texts="text"
            ></el-rate>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 保存按钮 -->
      <el-row class="a5">
        <el-button
          class="a6"
          @click="saveAddTask"
          size="mini"
          type="primary"
          round
        >
          保存
        </el-button>
        <el-button class="a6" @click="cancelButton" size="mini" round>
          取消
        </el-button>
      </el-row>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    return {
      // 标签的宽度--
      labelWidth: "120px",

      // 权限
      permissionValue: 1,
      // 辅助文字数组
      text: ["一颗星", "二颗星", "三颗星"],

      // 任务类型
      // taskTypeLabel: [
      //   { value: "1", label: "固定直播" },
      //   { value: "2", label: "临时直播" },
      //   { value: "3", label: "固定定时" },
      //   { value: "4", label: "临时定时" },
      //   { value: "5", label: "考试模式" },
      // ],

      // 当前选择的任务类型
      currentSelectTaskType: "3",
      // 可选任务类型
      taskTypes: [
        { value: "3", label: "固定定时" },
        // { value: "4", label: "临时定时" },
      ],

      // 当前编辑的任务
      currentTask: {},
      // 开始日期
      startDate: "",
      // 结束日期
      endDate: "",
    };
  },

  props: ["createNewTask", "MyIsEdit"], //当前任务
  components: {},
  mounted() {
    this.ifEdit();
    this.ifPriority();
    this.ifStartDate();
    this.ifEndDate();
  },
  created() {},
  beforeDestroy() {},

  methods: {
    // 当点击编辑按钮所弹出的框，界面任务类型要显示数据库的任务类型
    ifEdit() {
      if (this.createNewTask.type != null) {
        this.currentSelectTaskType = this.createNewTask.type + "";
      }
    },
    // 当点击编辑按钮所弹出的框，界面任务类型要显示数据库的权限（星星颗数）
    ifPriority() {
      if (this.createNewTask.priority != null) {
        console.log(this.createNewTask.priority);
        this.formatPriority();
      }
    },

    // 开始日期
    ifStartDate() {
      if (this.createNewTask.startDate != null) {
        // console.log(this.createNewTask.startDate);
        this.startDate = this.createNewTask.startDate;
      }
    },

    // 结束日期
    ifEndDate() {
      if (this.createNewTask.endDate != null) {
        this.endDate = this.createNewTask.endDate;
      }
    },

    // 从数据库获得权限值转换为星星颗数
    formatPriority() {
      if (this.createNewTask.priority == 0) {
        this.permissionValue = 1;
      } else if (this.createNewTask.priority == 2) {
        this.permissionValue = 2;
      } else if (this.createNewTask.priority == 4) {
        this.permissionValue = 3;
      } else {
        this.$message({
          message: "获取不到权限，拍照联系前端开发查明原因！",
          type: "warning",
        });
      }
    },

    // 判断任务名称是否合法
    legalTaskName() {
      var r = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (!r.test(document.getElementById("name").value)) {
        this.$message({
          message: '用户名称由"中文、英文、数字"组成',
          type: "warning",
        });
        document.getElementById("name").value = "";
      }
    },

    // 保存按钮
    saveAddTask() {
      this.createNewTask.type = this.currentSelectTaskType;
       console.log(this.createNewTask,123456)
      //permissionValue的1颗星、2颗星、3颗星、在定时任务里面的权限对应0、2、5
      if (this.permissionValue == 1) {
        this.createNewTask.priority = 0;
      } else if (this.permissionValue == 2) {
        this.createNewTask.priority = 2;
      } else if (this.permissionValue == 3) {
        this.createNewTask.priority = 4;
      } else {
        this.$message({
          message: "获取不到权限，拍照联系前端开发查明原因！",
          type: "warning",
        });
      }

      // 判断任务名称不能为空
      if (this.createNewTask.name != "") {
        // console.log(this.createNewTask);

        // 判断开始日期和结束日期是否已经输入
        // if (
        //   this.startDate != null &&
        //   this.startDate != "" &&
        //   this.endDate != ""
        // ) {
        //   // 判断结束日期要大于或等于开始日期
        //   let t1 = new Date(this.startDate).getTime();
        //   let t2 = new Date(this.endDate).getTime();
        //   if (t1 > t2) {
        //     this.$message({
        //       message: "开始日期不能大于结束日期",
        //       type: "warning",
        //     });
        //   }
        //    else {
            this.createNewTask.startDate = this.startDate;
            this.createNewTask.endDate = this.endDate;
            this.$emit("newTask", this.createNewTask);

            // 关闭窗体
            this.$emit("closeDialog", false);

            // 判断是否编辑界面
            if (this.MyIsEdit != true) {
              // 非编辑界面则清空日期输入框
              this.clearDate();
            }
          // }
        // }
        //  else {
        //   this.$message({
        //     message: "开始日期或结束日期不能为空！（错）",
        //     type: "warning",
        //   });
        // }
      } else {
        this.$message({
          message: "任务名称不能为空！",
          type: "warning",
        });
      }
    },

    //取消按钮
    cancelButton() {
      this.$emit("closeDialog", false);
    },

    // 清空“开始日期”和“结束日期”的输入框
    clearDate() {
      this.createNewTask.startDate = null;
      this.createNewTask.endDate = null;
    },
  },
};
</script>

<style scoped>
.a2 {
  width: 100%;
}
.a3 {
  float: left;
  margin-top: 12px;
}
.a4 {
  height: 40px;
}
.a5 {
  width: 100%;
}
.a6 {
  float: right;
  margin: 0px 10px;
}
::v-deep .el-date-editor.el-input{
  width: 100%;
}
/* .b1 >>> .el-form-item__label {
  text-align: justify;
} */
</style>
