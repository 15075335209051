<template>
  <div id="app">
    <el-container>
      <!-- 头部 -->
      <el-header height="80px">
        <div class="logo"></div>
        <div>
          <el-row>
            <!-- 直播 -->
            <el-col :span="12">
              <router-link
                to="/direct"
                tag="button"
                replace
                :class="this.isDirect==true? 'directButtom1':'directButtom'"
                @click.native="direct"
              >
              </router-link>
            </el-col>

            <!-- 定时 -->
            <el-col :span="12">
              <router-link
                to="/plan"
                tag="button"
                replace
                @click.native="plan"
                :class="this.isPlan==true? 'planButtom1':'planButtom'"
              >
              </router-link>
            </el-col>
          </el-row>
        </div>
      </el-header>

      <el-container style="height: 520px">
        <!-- 左侧 -->
        <!-- <el-aside width="250px">
          <div class="liftLink">
            <div>
              <el-link href="https://www.baidu.com/?tn=88093251_95_hao_pg"
                >百度</el-link
              >
            </div>

            <div><router-link to="/system" replace>系统配置</router-link></div>
            <div><router-link to="/check" replace>设备检查</router-link></div>
            <div><router-link to="/music" replace>歌曲管理</router-link></div>
            <div>录制制作</div>
            <div>考试模式</div>
            <div>一键接管</div>
            <div>升级</div>
            <div>关于</div>
            <div><router-link to="/test">test</router-link></div>
            <div><router-link to="/test001">test001</router-link></div>
          </div>
        </el-aside> -->

        <!-- 左侧 -->
        <el-aside width="200px">
          <el-menu
            background-color="#333555"
            active-text-color="#1787FB"
            style="border-right:0px"
            router
            :default-active="path"
          >
            <template v-for="(item, index) in nav_menu_data">
              <el-menu-item
                @click="logout(index)"
                :index="item.path"
                :key="index"
              >
                {{ item.title }}
              </el-menu-item>
            </template>
            <!-- <keep-alive>
              <get-time class="time"></get-time>
            </keep-alive> -->
            <div class="serverDateTime">{{ dateFormat(serverDateTime) }}</div>
            <!-- <div style="color: #ffffff;height: 30px;">版本：1.0.1.73</div>
            <div style="color: #ffffff;height: 30px;">型号：ADS6601  V01</div> -->
          </el-menu>
          <!-- <div class="a1"><el-button circle class="a2"></el-button></div> -->
        </el-aside>

        <!-- 主体 -->
        <!-- <el-main :style="defaultHeight"> -->
        <el-main :style="defaultHeight">
          <div>
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
import GetTime from "../public/getTime";
export default {
  name: "app",
  data() {
    return {
      defaultHeight: {     
			height: ""    
			},
      path: "/system",
      nav_menu_data: [
        {
          title: "系统配置",
          path: "/system",
        },
        {
          title: "设备检查",
          path: "/check",
        },
        {
          title: "歌曲管理",
          path: "/music",
        },
        // {
        //   title: "录制制作",
        //   path: "/transcribe",
        // },
        // {
        //   title: "考试模式",
        //   path: "/",
        // },
        // {
        //   title: "一键接管",
        //   path: "/",
        // },
        // {
        //   title: "升级",
        //   path: "/",
        // },
        // {
        //   title: "关于",
        //   path: "/",
        // },
        {
          title: "退出登录",
          // path: "/Login",
        },
        // {
        //   title: "测试",
        //   path: "/test001",
        // },
      ],
      isDirect:false,
      isPlan:false,
      serverDateTime:''
    };
  },
  created() {  
		//页面创建时执行一次getHeight进行赋值，顺道绑定resize事件  
		window.addEventListener("resize", this.getHeight);  
		this.getHeight();
		},
  mounted(){
    this.initWebSocket()
    },
  methods: {
    dateFormat: function (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "/" + month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
    },
    getHeight() {    
		this.defaultHeight.height = window.innerHeight - 90 + "px";  
		},
    direct() {
      this.isDirect=true
      this.isPlan=false
      console.log(123);
    },
    plan() {
      this.isPlan=true
      this.isDirect=false
      console.log(234);
    },
     logOut(){
       request({
          method: "post",
          url: "/logout2",
        })
          .then((res) => {
            console.log(res.data);

            if (res.data.resultCode == 200) {
              window.sessionStorage.clear();
              this.$router.push({ path: "/login" });
              this.$message({
            type: 'success',
            message: '退出登录成功!'
          });
            } else {
              this.$message({
                message: res.data.resultMessage,
                type: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    logout(index) {
      console.log(index, 666669999999999);
      if (index == 3) {
        
       this.$confirm('是否确认退出登录？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.logOut()
          // window.sessionStorage.clear();
          // this.$router.push('/Login')
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消退出登录'
          });          
        });
      }
      if (index != "" || index == 0) {
        this.isDirect = false;
        this.isPlan = false;
      }
      // if(index==0){
      //   this.path='/system'
      // }
    },
    initWebSocket() {
      // let token = JSON.parse(sessionStorage.getItem("token"));
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
        //  let wsuri = "ws://106.15.40.26:9004/chat";
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //连接建立之后执行send方法发送数据
    websocketonopen() {
      // var token = JSON.parse(sessionStorage.getItem("token"));
      // let actions = "timetaskid:" + this.task.timetasks[0].id; 旧
      let actions = {
        // token: "222",
        // token: token,
        dataType: "timetasks:",
        // ts: [this.task.id],
      };
      this.websocketsend(JSON.stringify(actions));
      console.log(actions);
    },

    //数据发送
    websocketsend(Data) {
      this.timer = setInterval(() => {
        this.websock.send(Data);
        // console.log("页面时间");
      }, 1000);
      // this.websock.send(Data);
    },

    //数据接收
    websocketonmessage(e) {
      // console.log(e)
      // console.log(JSON.parse(e.data).serverDateTime, "返回的值");
      this.serverDateTime=JSON.parse(e.data).serverDateTime
      // this.stateID=JSON.parse(e.data).ts[0].id
      // if(JSON.parse(e.data).ts[0].id!=undefined){
      // }
      // console.log(JSON.parse(e.data).ts[0].id,JSON.parse(e.data),'返回的值');
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },
  },

  //离开路由之后断开websocket连接
  destroyed() {
    this.websock.close();
    clearInterval(this.timer);
    // this.close()
    // this.miVol_switch=false
  },
  components: {
    GetTime,
  },
};
</script>

<style scoped>
.el-header,
.el-footer {
  /* background-color: #b3c0d1; */
  /* background-color: #94abc9; */
  background: linear-gradient(to top, #353757, #fff);
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  /* background-color: #d3dce6; */
  /* background-color: #b3c0d1; */
  background-color: #333555;
  color: #333;
  text-align: center;
  line-height: 200px;
   height: 100vh;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
  /* height: 680px; */
  height: calc(100vh - 70px);
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

/* 直播 */
.directButtom {
  background-image: url("../../assets/image/Broadcast_ico_click.png");
  background-size: 80px;
  width: 65px;
  height: 65px;
  background-position: center;
  border-radius: 50px;
  outline: none;
}
.directButtom1 {
  background-image: url("../../assets/image/Broadcast_ico_click.png");
  background-color: rgb(181, 213, 255);
  background-size: 80px;
  width: 65px;
  height: 65px;
  background-position: center;
  border-radius: 50px;
  outline: none;
}
/* 定时*/
.planButtom {
  background-image: url("../../assets/image/Timing_ico_click.png");
  background-size: 80px;
  width: 65px;
  height: 65px;
  background-position: center;
  border-radius: 50px;
  outline: none;
}
.planButtom1 {
  background-image: url("../../assets/image/Timing_ico_click.png");
  background-color: rgb(181, 213, 255);
  background-size: 80px;
  width: 65px;
  height: 65px;
  background-position: center;
  border-radius: 50px;
  outline: none;
}
.logo{
  background-image: url("../../assets/image/logo.png");
  position: absolute;
  left: 25px;
  height: 50px;
  width: 150px;
  background-size: 150px;
  background-position: center;
  outline: none;
  bottom: 8%;
}
/* 链接的样式 */
/* .liftLink {
  line-height: 50px;
} */

/* 更改菜单字体 */
.el-menu-item {
  color: #fff;
  font-size: 16px;
}

.a1 {
  line-height: 72px;
}
.a2 {
  width: 64px;
  height: 64px;
  background-image: url("../../assets/image/other/exit.png");
  background-position: center;
  vertical-align: middle;
}
/* 两个按钮的位置 */
.el-col-12 {
  margin: 10px auto;
}
.time{
  color: white !important;
  /* background-color: #388adb; */
  margin-top: 40%;
  height: 100px;
}
.serverDateTime{
  color: #fff;
  font-size: 15px;
  /* margin-left: 10%; */
}
</style>

