<template>
  <div id="app">
    <h1>我是测试组件</h1>
    <h1>{{ message }}</h1>

    <router-link to="/home" replace>
      <el-button
        round
        type="primary"
        icon="el-icon-back"
        size="mini"
      ></el-button>
    </router-link>

    <div><b>vue-router中的router-link的active-class</b></div>
    <!-- 保留羁押动态上一页数据 -->
    <!-- <keep-alive :include="['basicInfo', 'infoWarning' ,'dynamic']"> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive">
      <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
    </router-view>

    <!-- vuex测试 -->
    <div>
      <h2>{{ $store.state.aaa }}</h2>
      <button @click="test">测试vuex按钮</button>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    return {
      message: "001",
    };
  },
  components: {},
  props: {},

  beforeRouteLeave(to, from, next) {
    console.log(to);
    console.log(from);
    if (to.path == "/home") {
      //缓存
      from.meta.keepAlive = true;
      alert("1");
    } else {
      //不缓存
      from.meta.keepAlive = false;
      alert("2");
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    console.log("路由进入之前");
    next((vm) => {
      // 通过 `vm` 访问组件实例
      console.log(vm);
      // vm.currentIndex = sessionStorage.getItem("currentIndex");
      // vm.pause = sessionStorage.getItem("pause");
    });
  },

  methods: {
    test() {
      let bbb = "110";
      // 提交风格一：
      this.$store.commit("test", bbb);
      // 提交风格二：
      // this.$store.commit({
      //   type: "test",
      //   bbb,
      // });
    },
  },
};
</script>

<style scoped></style>
