// 系统配置按钮组件
<template>
  <div id="app">
    <urgent></urgent>
    <!-- 按钮部分 -->
    <div class="a1">
      <router-link to="/system" replace>
        <el-button size="mini" icon="el-icon-back" round>返回</el-button>
      </router-link>
    </div>
    <!-- <div class="unit">
      <div>单位信息</div>
      <div>云服务器IP</div>
      <div>106.15.40.26</div>
      <div>单位名称</div>
      <div>无</div>
      <div>单位地址</div>
      <div>无</div>
      <div>下载地址</div>
      <div>无</div>
    </div> -->
    <table>
      <td>
      <tr><div>单位信息</div></tr>
      <tr><div>云服务器IP</div></tr>
      <tr><div>106.15.40.26</div></tr>
      <tr><div>单位名称</div></tr>
      <tr><div>无</div></tr>
      <tr><div>单位地址</div></tr>
      <tr><div>无</div></tr>
      <tr><div>下载地址</div></tr>
      <tr><div>无</div></tr>
      </td>
      <td>
      <tr><div>界面信息</div></tr>
      <tr><div>单元格高度</div></tr>
      <tr><div>200</div></tr>
      <tr><div>单元格宽度</div></tr>
      <tr><div>340</div></tr>
      <tr><div>网格高度</div></tr>
      <tr><div>503</div></tr>
      <tr><div>网格宽度</div></tr>
      <tr><div>1700</div></tr>
      </td>
    </table>
    <table style="margin-top: 10px;">
<td
      >
      <tr><div>播放信息</div></tr>
      <tr><div>广播地址</div></tr>
      <tr><div>192.168.1.242</div></tr>
      <tr><div>软件类型</div></tr>
      <tr><div>广播员工作站</div></tr>
      <tr><div>监听通道</div></tr>
      <tr><div>2</div></tr>
      <tr><div>逻辑通道</div></tr>
      <tr><div>扬声器（Realtek Hight Definition Audio）</div></tr>
      </td>
      <td>
      <tr><div>键盘信息</div></tr>
      <tr><div>键盘下移</div></tr>
      <tr><div>600</div></tr>
      <tr><div>键盘右移</div></tr>
      <tr><div>190</div></tr>
      <tr><div>键盘高度</div></tr>
      <tr><div>503</div></tr>
      <tr><div>键盘宽度</div></tr>
      <tr><div>1650</div></tr>
      </td>
    </table>
    <table style="margin-top: 10px;">
      <td>
        <tr>
          <div>扩容配置</div>
        </tr>
      </td>
       <td>
        <tr>
          <div>云端配置</div>
        </tr>
      </td>
    </table>
    <!-- <div class="a2">
      <div class="a3">
        <span
          >麦克风地址:
          <el-input
            class="input"
            v-model="input"
            placeholder="请输入麦克风地址"
          ></el-input>
        </span>
      </div>
      <div class="a3">
        <span
          >监听通道:
          <el-select style="margin-left: 10px;" v-model="value" placeholder="请选择监听通道">
            <el-option
              v-for="item,index in monitor"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </span>
      </div>
    </div> -->
    <!-- 主要部分 -->

    <!-- <el-table
      :data="tableData"
      style="width: 100%"
      border
      :row-class-name="rowClassName"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @cell-click="cellClick"
    >
      <el-table-column :resizable="false" prop="a1" label="单位信息">
      </el-table-column>
      <el-table-column :resizable="false" prop="a2" label="播放信息">
      </el-table-column>
      <el-table-column :resizable="false" prop="a3" label="界面信息">
      </el-table-column>
      <el-table-column :resizable="false" prop="a4" label="键盘信息">
      </el-table-column>
    </el-table> -->

    <!-- <div>
      <el-row>
        <el-form>
          <el-form-item label="单位信息：" :label-width="labelWidth">
            <el-input
              size="mini"
              v-model="taskName"
              show-word-limit
              minlength="1"
              maxlength="10"
              placeholder="任务名称由“中文、英文、数字”组成"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
    </div> -->
    <!-- <div class="config">
      <div @click="scope">
        <system-config-botton-item>
          <img
            slot="item-icon"
            src="../../assets/image/system/ExpansionConfig.png"
            alt=""
          />
          <div slot="item-title-text">扩容配置</div>
          <div slot="item-details-text">注册、修改、删除</div>
        </system-config-botton-item>
      </div>
      <div @click="cloud">
        <system-config-botton-item>
          <img
            slot="item-icon"
            src="../../assets/image/system/CloudUpload.png"
            alt=""
          />
          <div slot="item-title-text">云端管理</div>
          <div slot="item-details-text">注册、修改、删除</div>
        </system-config-botton-item>
      </div>
    </div> -->
  </div>
</template>

<script type="text/javascript">
import { request } from "../../network/request";
import SystemConfigBottonItem from "./SystemConfigBottonItem.vue";
export default {
  name: "app",
  data() {
    return {
      input: "192.168.1.242",
      //标签的宽度
      labelWidth: "120px",

      taskName: "",

      tableData: [
        { a1: "云服务IP", a2: "麦克风地址", a3: "单元格高度", a4: "键盘下移" },
        { a1: "", a2: "", a3: "", a4: "" },

        { a1: "单位名称", a2: "软件类型", a3: "单元格宽度", a4: "键盘右移" },
        { a1: "", a2: "", a3: "", a4: "" },

        { a1: "单位地址", a2: "监听通道", a3: "网格高度", a4: "键盘高度" },
        { a1: "", a2: "", a3: "", a4: "" },

        { a1: "下载地址", a2: "网格宽度", a3: "逻辑通道", a4: "键盘宽度" },
        { a1: "", a2: "", a3: "", a4: "" },
      ],
      // options: [
      //   {
      //     value: "选项1",
      //     label: "黄金糕",
      //   },
      //   {
      //     value: "选项2",
      //     label: "双皮奶",
      //   },
      //   {
      //     value: "选项3",
      //     label: "蚵仔煎",
      //   },
      //   {
      //     value: "选项4",
      //     label: "龙须面",
      //   },
      //   {
      //     value: "选项5",
      //     label: "北京烤鸭",
      //   },
      // ],
      value: "",
      monitor: [],
    };
  },
  mounted() {
    this.listen();
    console.log(this.monitor, 88688);
  },
  components: {
    SystemConfigBottonItem,
  },
  methods: {
    // 行样式
    rowClassName({ row, rowIndex }) {
      // if (rowIndex % 2 === 0) {
      //   console.log(row, rowIndex);
      //   return "warning-row";
      // }
      // return "success-row";
    },

    // 当单元格 hover 进入时会触发该事件
    cellMouseEnter(row, column, cell, event) {
      // console.log(row, column, cell, event);
    },
    // 当单元格 hover 退出时会触发该事件
    cellMouseLeave(row, column, cell, event) {},

    // 当某个单元格被点击时会触发该事件
    cellClick(row, column, cell, event) {
      console.log(row, column, cell, event, 123456);
    },
    listen() {
      request({
        method: "get",
        url: "/config/listen",
      })
        .then((res) => {
          this.monitor = res.data.data;
          console.log("监听通道：", res.data.data);
          console.log("监听通道接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("监听通道接口失败");
        });
    },
    scope() {
      this.$alert('该功能暂未开放', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          // callback: action => {
          //   this.$message({
          //     type: 'info',
          //     message: `action: ${ action }`
          //   });
          // }
      });
      console.log(123);
    },
    cloud() {
      this.$alert('该功能暂未开放', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          // callback: action => {
          //   this.$message({
          //     type: 'info',
          //     message: `action: ${ action }`
          //   });
          // }
      });
      console.log(456);
    },
  },
};
</script>

<style scoped>
/* 表的行高 */
.el-table >>> thead {
  line-height: 30px;
}
body {
  text-align: center;
}

.a1 {
  margin: 0px;
  line-height: 30px;
  width: 75px;
}

.el-table >>> .warning-row {
  background: rgb(223, 218, 210);
}

.el-table >>> .success-row {
  background: #d7e0d2;
}
.a2 {
  border-radius: 3px;
  line-height: 60px;
  /* text-align: center; */
  background-color: #fff;
  color: #606266;
  margin-top: 20px;
  padding: 3px;
  display: flex;
  justify-content: space-between;
}
.input {
  /* display: block; */
  width: 217px;
  margin-left: 10px;
}
.a3 {
  display: inline-block;
  width: 50%;
}
.config {
  margin-top: 8%;
  cursor: pointer;
}
.unit{
  height: 100px;
}
table{
  margin: auto;
}
table td{
  width: 400px;
  /* background-color: aqua; */
}
table td tr:nth-child(1){
  font-size: 15px;
  color: #5095F0;
}
table td tr{
  line-height: 25px;
  text-align: left;
}
table td tr:nth-child(3),
table td tr:nth-child(5),
table td tr:nth-child(7),
table td tr:nth-child(9){
  font-size: 12px;
}
</style>
