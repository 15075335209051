import axios from 'axios';
import { Message } from 'element-ui';
import JSONBig, { parse } from 'json-bigint';
import nprogress from 'nprogress';
import router from '../router/index';
import "nprogress/nprogress.css";
                
export function request(config){
    //创建axios实例
    const axiosInstance=axios.create({
        baseURL:'/api',
        timeout:60000,
    
        // transformResponse发生在axios 的响应拦截器之前。
        // transformResponse 响应预处理函数
        transformResponse: [function (data) {
            try {
                // 如果转换成功则返回转换的数据结果
                // JSONBig.parse()用来处理大数            
                return JSONBig.parse(data) // 解决精度损失                           
            } catch (err) {
                // 如果转换失败，则包装为统一数据格式并返回
                return {
                    data
                }
            }
        }]
    })

    // 通过axios.interce拦截器将请求拦截在头部加入token，并拦截返回数据判断状态码
    // request interceptor
    axiosInstance.interceptors.request.use(config => {
        
    // let uid = sessionStorage.getItem('token');
    // if (uid) {
    //     config.headers['token'] = "uid"; // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    // }

    let uid = JSON.parse(sessionStorage.getItem('token'));
    if (uid) {
        config.headers['Content-Type'] = 'application/json';
        config.headers['token'] = uid;
    }
    nprogress.start();
    return config;
    }, error => {
    Promise.reject(error);
    });

   
    axiosInstance.interceptors.response.use(response => {
        nprogress.done()
        if(response.data.resultCode == 450) {
            Message.error("登录超时！请重新登陆！");
            router.push('/login')
            sessionStorage.removeItem("token");
        }
        if(response.data.resultCode == 1012) {
            Message.error("服务器未找到加密锁,请插入加密锁后，再进行操作。");
        }
        return response; 
    },error => {
       return error
    });

    // 真正发送网络请求
    return axiosInstance(config)
}