<template>
  <div id="app" class="bh">
    <!-- 路由匹配到的组件将显示在这里 -->
    <!-- <el-button type="text" @click="dialogVisible = true"
      >点击打开 Dialog</el-button
    > -->
    <el-dialog
      title="紧急广播"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="80%"
      style="margin-top: -8vh"
    >
      <div class="b1" style="margin-top: -20px">
        <!-- #c27efa; -->
        <!-- @click="allselect(1)" -->
        <div
          class="b2"
          :class="active == -1 ? 'b3' : 'b2'"
          :id="allSelect == true ? 'b4' : ''"
          v-for="item in this.allzone"
          :key="item.id"
        >
          {{ item.name }}
        </div>
        <div
          class="b2"
          :class="index == active ? 'b3' : 'b2'"
          :id="gather.indexOf(item.id) > -1 ? 'b4' : ''"
          v-for="(item, index) in this.allZones"
          :key="index"
          @click.stop="a(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
    </el-dialog>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { request } from "./network/request";
export default {
  name: "app",
  provide() { // 注册一个方法
return {
reload: this.reload
}
},
  data() {
    return {
      isRouterAlive: true,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      dialogVisible: false,
      active: "-1",
      allZones: [],
      length: "",
      scrollTop: "",
      timer: null,
      index: "",
      isColor: false,
      index: "",
      isOpen: "-1",
      gather: [], //选中集合数组
      stringList: null, //选中集合数组转换成字符串
      allSelect: false,
      right: "",
      left: "",
      comData: "",
      all: "",
      clickId: "",
      taskID: "",
      come: false,
      timer: "",
      timer1:'',
      timer2:'',
      timer3:'',
      timer4:'',
      allzone: [
        {
          name: "全部分区",
          id: -1,
        },
      ],
    };
  },
  components: {},
  mounted() {
    document.getElementById("app").addEventListener("click", () => {
      // document.documentElement.webkitRequestFullScreen();
      //  console.log(window.outerHeight);
      // console.log(window.outerWidth);
      // 全屏
      // this.full();
    });
    // document.onkeydown = (e) => {
    //   if (e.keyCode == 39) {
    //     this.active++;
    //     if (this.active > this.length) {
    //       this.active = -1;
    //     }
    //   }
    //   if (e.keyCode == 37) {
    //     this.active--;
    //     if (this.active < 0) {
    //       this.active = -1;
    //     }
    //   }
    //   // console.log(e.keyCode);
    // };
    console.log(111111111111111111111111111111111111111);
    const that = this
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight
        window.fullWidth = document.documentElement.clientWidth
        that.windowHeight = window.fullHeight // 高
        that.windowWidth = window.fullWidth // 宽
      })()
    }
    console.log(that.windowHeight,'页面高度')
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.addEventListener('unload', e => this.unloadHandler(e))
        // console.log(this.allZones,789101112)
    // this.getAllZones();
    // this.timer2=setInterval(()=>{
    //   // this.websock.send(Data);
    //   this.getAllZones();
    //   // console.log('紧急广播')
    //   },1000)
    //   this.timer3=setInterval(()=>{
    //   // this.websock.send(Data);
    //   this.initWebSocket();
    //   // console.log('紧急广播')
    //   },1000)
      
  },
  created() {
  //  this.timer4=setInterval(()=>{
  //     // this.websock.send(Data);
  //     this.initWebSocket();
  //     // console.log('紧急广播')
  //     },1000)
      
  //   this.getAllZones();

  
    
    // this.collect();
    // this.listenerFunction();
  },
  //   beforeDestroy () {
  //     document.removeEventListener("scroll", this.listenerFunction);
  // },
  methods: { 
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
      this.isRouterAlive = true
    })
    },
    full() {
      //全屏
      var docElm = document.documentElement;
      //W3C
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      }

      //FireFox
      else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }

      //Chrome等
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }

      //IE11
      else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
    },
    initWebSocket() {
      var id=sessionStorage.getItem("webket")
      const wsuri = `wss://hcjk.sgbjxt.com/broadChat?userId=${id}`;
      this.websock = new WebSocket(wsuri);
      this.websock.onopen = this.websocketonopen;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    //连接建立之后执行send方法发送数据
    websocketonopen() {
      // let token = JSON.parse(sessionStorage.getItem("token"));
      // let actions = "timetaskid:" + this.task.timetasks[0].id; 旧
      let actions = {
        // token: "222",
        // token: token,
        dataType: "left:",
        ts: [],
      };
      this.websocketsend(JSON.stringify(actions));
      
      console.log('紧急广播连接成功');
      clearInterval(this.timer3)
      clearInterval(this.timer4)
    },

    //数据发送
    websocketsend(Data) {
      // this.timer=setInterval(()=>{
      // this.websock.send(Data);
      // // console.log('紧急广播')
      // },1000)
      // this.websock.send(Data);
    },
    // 播放任务接口
    // taskId() {
    //   this.stringList = this.gather.join(",");
    //   var list = {
    //     regions: [{ id: this.stringList }],
    //   };
    //   request({
    //     method: "put",
    //     url: "broad/remoteStart",
    //     data: list,
    //   })
    //     .then((res) => {
    //       console.log("获得的任务id：", res);
    //       console.log("接口成功");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       console.log("接口失败");
    //     });
    // },
    // collect() {
    //   //查询采集配置
    //   request({
    //     url: "/acollect",
    //   })
    //     .then((res) => {
    //       console.log("获得数据库的所有分区列表:");
    //       console.log(res.data.data, 999);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    taskstart() {
      console.log(this.taskID, 9966);
      clearInterval(this.timer1);
      request({
        method: "put",
        url: "broad/start/volume/1",
        data: [this.taskID],
      })
        .then((res) => {
          // this.musicVol_switch = false

          console.log(res);
          console.log("麦克风开始接口成功", this.taskID);
        })
        .catch((err) => {
          console.log(err);
          console.log("失败");
        });
    },
    taskstop() {
      request({
        method: "put",
        url: "/broad/stop/volume/1",
        data: [this.taskID],
      })
        .then((res) => {
          // this.musicVol_switch = true;
          console.log(res);
          console.log("麦克风关闭接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("失败");
        });
    },
    //数据接收
    websocketonmessage(e) {
      // console.log(e)
      let d = JSON.parse(e.data).dataType;
      this.comData = d;
      if (this.comData == "Short" && this.dialogVisible == true) {
        // this.a(this.clickId)
        if (this.active >= 0) {
          let d = this.allZones[this.active].id;
          // this.gather.push(d)
          let subscript = this.gather.indexOf(d); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
          if (subscript > -1) {
            this.gather.splice(subscript, 1); //splice:用于添加或删除数组中的元素
            request({
              method: "put",
              url: `broad/remoteStop/${this.taskID}`,
              data: list,
            })
              .then((res) => {
                console.log("删除任务id", res);
                console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            this.taskstop();
          } else {
            this.gather.push(d); //通过push方法将选中id添加到数组中
            // 获取任务id
            this.stringList = this.gather.join(",");
            var list = {
              priority: 7,
              collectID:-1,
              regions: [{ id: this.stringList }],
            };
            request({
              method: "put",
              url: "broad/remoteStart",
              data: list,
            })
              .then((res) => {
                this.taskID = res.data.data;
                this.come = true;
                console.log(
                  "获得的任务id：",
                  res,
                  this.stringList,
                  this.taskID
                );
                // console.log("接口成功");
              })
              .catch((err) => {
                console.log(err);
                console.log("接口失败");
              });
            this.timer1 = setInterval(() => {
              this.taskstart();
              console.log("任务id");
            }, 1000);

            console.log(this.active, 77777, this.gather, d);
          }
        }
        if (this.active < 0) {
          this.allSelect = true;
          var all = [];
          this.allZones.forEach((item) => {
            all.push(item.id);
          });

          console.log("66666", all, this.gather);
          if (this.gather.length < all.length) {
            this.gather.push(...all);
          } else {
            this.gather.splice(0);
            this.allSelect = false;
          }
        }
      }
      if (this.comData == "DoubleClick") {
        this.dialogVisible = true;

      }
      if (this.comData == "Long") {
        this.dialogVisible = false;
      }
      if (this.comData == "Left") {
        this.active++;
        if (this.active > this.length) {
          this.active = -1;
        }
      }
      if (this.comData == "Right") {
        this.active--;
        if (this.active < 0) {
          this.active = -1;
        }
      }
      if (this.comData == "Short" && this.active == -1) {
        console.log(123);
      }
      // if(this.allZones.length==this.gather.length){
      //   this.allSelect = true;
      //   console.log()
      // }
      // console.log(22222, this.comData, this.active, this.all);
      console.log('com发来的指令',this.comData);
    },

    //连接建立失败重连
    websocketonerror() {
      this.initWebSocket();
    },

    //关闭
    websocketclose(e) {
      console.log("断开连接", e);
    },

    //离开路由之后断开websocket连接
    // destroyed() {
    //   this.websock.close();
    //   //  clearInterval(this.timer)
    // },
    a(id) {
      // if (index != this.isOpen && index >= 0) {
      // 	this.isOpen = index;
      // 	this.index = index;
      // 	console.log(this.isOpen, '传' + index, this.index);
      // } else {
      // 	this.isOpen = -1;
      // }
      // this.clickId = id;
      // let subscript = this.gather.indexOf(id); //indexOf:返回某个指定的字符串值在字符串中首次出现的位置
      // if (subscript > -1) {
      //   this.gather.splice(subscript, 1); //splice:用于添加或删除数组中的元素
      // } else {
      //   this.gather.push(id); //通过push方法将选中id添加到数组中
      // }
      // this.stringList = this.gather.join(","); //转换成字符串并以逗号隔开
      // console.log(this.stringList, "选中集合");
      // console.log(id);
    },
    // allselect(index) {
    //   if ((index = 1)) {
    //     console.log("dui");
    //     this.allSelect = !this.allSelect;
    //   }
    // },
    // 调用接口得到所有分区++++++++++++++++++++++++++++++++++++++++++++++++
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {

          console.log("获得的分区：");
          this.allZones = res.data.data;
          console.log(res.data.data,8864);
          this.length = this.allZones.length;
          this.length = this.length - 1;
          console.log("get:”/aregion”接口成功",678);
          clearInterval(this.timer2)
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
    beforeunloadHandler() {
            this.beforeUnload_time = new Date().getTime();
        },
        unloadHandler() {
            this._gap_time = new Date().getTime() - this.beforeUnload_time;
            console.log(this._gap_time)
            //判断是窗口关闭还是刷新
            //<=5是窗口关闭     >=5是刷新
            if (this._gap_time <= 5) {
              request({
          method: "post",
          url: "/logout2",
        })
          .then((res) => {
            if (res.data.resultCode == 200) {
              window.sessionStorage.clear();
              this.$router.push({ path: "/login" });
            }
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
            }
        }
    //   listenerFunction(e) {
    //     document.addEventListener('scroll', this.handleScroll, true);
    // },
    // handleScroll (event) {
    //     console.log("滚动条高度为：",event.target.scrollTop)
    //     this.scrollTop=event.target.scrollTop
    // },
  },
  destroyed() {
        window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
        window.removeEventListener('unload', e => this.unloadHandler(e))
    },
};
</script>


<style>
@import url("./assets/css/urgent.css");
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  /* height: 100%;
  width: 100%; */
  /* height: 100%; */
}
.bh {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: -10;
  zoom: 1;
  background-color: #E9EEF3;
  /* background-color: rgba(rgb(180, 156, 156), green, blue, 0.5); */
  /* background: url(../../assets/image/login/background.png) no-repeat; */
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  /* 透明度 */
  /* opacity: 0.9; */
}
</style>
