// 直播任务界面
<template>
  <div id="app">
    <urgent></urgent>
    <div>
      <el-container>
        <el-header>
          <div class="a1">直播</div>
          <div class="a2">
            <el-button size="mini" @click="addTaskButton" round>添加</el-button>
            <el-button size="mini" @click="editTaskButton" round
              >编辑</el-button
            >
          </div>
        </el-header>
        <!-- <el-main style="height: 390px;"> -->
        <el-main style="height: 550px;">
          <div>
            <el-row :gutter="5">
              <!-- class="grid-content bg-purple" -->
              <!-- :class="{ a3: task.id == test[0] }" -->
              <!--  -->
              <el-col
                :span="4"
                v-for="(task, key, index) in directTasks"
                :key="key"
              >
                <task
                  :taskIndex="index"
                  :task="task"
                  :is_show_deleteButton="is_show_deleteButton"
                  :activityTaskIds="activityTaskIds"
                  :broadState="broadState"
                  @currentTaskId="deleteCurrentTask"
                ></task>
                <!-- <div>{{ task.id }}</div> -->
                <!-- <div>{{ key }}</div> -->
                <!-- <div>{{ index }}</div> -->
              </el-col>
            </el-row>
          </div>
        </el-main>
        <!-- <el-header>
          <div class="a1">临时直播</div>
        </el-header> -->
        <!-- <el-main style="height: 190px;">
          <div>
            <el-row :gutter="5">
              <el-col
                :span="4"
                class="grid-content bg-purple"
                v-for="(task, key, index) in directTasksTemp"
                :key="key"
              >
                <task
                  :taskIndex="index"
                  :task="task"
                  :is_show_deleteButton="is_show_deleteButton"
                  @currentTaskId="deleteCurrentTask"
                ></task>
              </el-col>
            </el-row>
          </div>
        </el-main> -->
      </el-container>
    </div>

    <!-- 添加任务弹框 -->
    <!-- :top="true" -->
    <!-- title="新建直播任务" -->
    <el-dialog
      v-dialogDrag
      :visible.sync="is_show_addTask"
      :destroy-on-close="true"
      :before-close="handleClose"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <add-task-d
        :createNewTask="createNewTask"
        :allZones="allZones"
        :allSongs="allSongs"
        :tableData="tableData"
        @newTask="newTask"
        @closeDialog="isShowAddTaskDialog"
      >
      </add-task-d>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import task from "../public/task";
import { request } from "../../network/request";
import addTaskD from "../public/addTaskD";

import {
  sendData,
  closewebsocket,
  initWebSocket,
} from "../../webSocket/websocket";

export default {
  name: "app",
  data() {
    return {
      // 任务
      // tasks: [],

      // 新建任务(初始化)
      createNewTask: null,

      // 所有可选的分区
      allZones: [],
      // 所有可选的歌曲
      allSongs: [],

      // 添加任务界面是否可视
      is_show_addTask: false,

      // 新创建任务
      newTaskObj: {},
      // 是否显示任务右上角的删除按钮
      is_show_deleteButton: true,

      // 直播任务
      directTasks: [],
      // 临时直播任务
      directTasksTemp: [],

      // 播放或暂停的大任务ID
      activityTaskIds: [],
      // 存放websocket返回的数据
      websocketData: [],
      tableData:[],
      broadState:[]
    };
  },
  components: {
    task,
    addTaskD,
  },
  watch: {
    websocketData(newVal, oldVal) {
      let d = [];
      let a= []
       newVal.forEach((e) => {
        a.push(e.broadState);
      });
      // 取出websocket推送的id
      newVal.forEach((e) => {
        d.push(e.id);
      });
      return (this.activityTaskIds = d,this.broadState=a);
    },
  },
  created() {
    this.openWebsocket();
  },
  mounted() {
    // this.getAllTask();
    this.getAllZones();
    this.getAllSongs();
    this.collect();
    this.getDirectTask();
    this.getDirectTaskTemp();
    this.send();
    console.log(this.activityTaskIds,6668,this.broadState)
    // this.timer = setInterval(() => {
    //     this.send();
    //     console.log("大任务websocket，正在请求......");
    //   }, 1000);
    this.getweb()
  },
  methods: {
     collect() {
      //查询采集配置
      request({
        url: "/acollect",
      })
        .then((res) => {
          console.log("获得数据库的所有分区列表:");
          console.log(res.data.data,999);
          this.tableData=res.data.data
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 连接websocket-------------------------------------------------------
    openWebsocket() {
      initWebSocket();
      console.log('大任务连接成功.................')
    },

    // 用来接收 websocket 推送的数据-----------------------------------------
    getWebsocketData(e) {
      // console.log(JSON.parse(e.data).ts,88888887);
      this.websocketData = JSON.parse(e.data).ts;
    },
    getweb(){
       this.timer = setInterval(() => {
        this.send();
        // console.log("大任务websocket,正在请求......");
      }, 1000);
    },
    // 发送数据-------------------------------------------------------------
    send() {
      let token = JSON.parse(sessionStorage.getItem("token"));
      let actions = {
        // token: "222",
        token: token,
        dataType: "tasks:",
        ts: [],
      };
      sendData(actions, this.getWebsocketData);
      // console.log(actions,998877)
    },

    // 添加按钮--------------------------------------------------------------
    addTaskButton() {
      this.is_show_addTask = true;
    },

    // 编辑按钮--------------------------------------------------------------
    editTaskButton() {
      this.is_show_deleteButton = !this.is_show_deleteButton;
    },

    // 添加任务的界面关闭之前------------------------------------------------
    handleClose() {
      this.is_show_addTask = false;
      // 关闭之前首先判断子组件是否有保存提交过来数据
      // console.log(this.newTask);
      // this.addTask();
    },

    // 关闭界面清空输入框的内容------------------------------------------------
    isShowAddTaskDialog(res) {
      this.is_show_addTask = res;
    },

    // 将新任务保存到数据库-----------------------------------------------------
    newTask(res) {
      this.newTaskObj = res;
      console.log(res,9876543210)
      this.addTask();
    },
    // 删除任务按钮-------------------------------------------------------------
    deleteCurrentTask(taskId) {
      this.delectTask(taskId);
    },

    // 调用接口得到所有的任务++++++++++++++++++++++++++++++++++++++++++++++++
    // getAllTask() {
    //   request({
    //     method: "get",
    //     url: "/atask",
    //   })
    //     .then((res) => {
    //       console.log("获得的任务：");
    //       // this.tasks = res.data.data;
    //       console.log(res.data.data);
    //       console.log("get:”/atask”接口成功");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       console.log("get:”/atask”接口失败");
    //     });
    // },

    // 调用接口得到所有分区++++++++++++++++++++++++++++++++++++++++++++++++
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          console.log("获得的分区：");
          this.allZones = res.data.data;
          console.log(res.data.data);
          console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },

    // 调用接口得到所有歌曲++++++++++++++++++++++++++++++++++++++++++++++++
    getAllSongs() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          console.log("获得的歌曲：");
          this.allSongs = res.data.data;
          console.log("get:”/asong");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/asong");
        });
    },

    // 调用接口创建新任务++++++++++++++++++++++++++++++++++++++++++++++++
    addTask() {
      request({
        method: "post",
        url: "/atask",
        data: this.newTaskObj,
      })
        .then((res) => {
          // 判断是否数据库有重复数据
          if (res.data.data == null) {
            this.$message({
              message: res.data.resultMessage,
              type: "error",
            });
          } else {
            console.log(res.data.data);
            // this.getAllTask();
            this.getDirectTask();
            this.getDirectTaskTemp();
            // console.log("get:”/atask”接口成功");
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask”接口失败");
          this.$message({
            message: "新建直播任务失败！",
            type: "error",
          });
        });
    },

    // 删除任务接口++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    delectTask(taskId) {
      request({
        method: "delete",
        url: "/atask/" + taskId,
      })
        .then((res) => {
          console.log(res);
          console.log("delete:”/atask/{id}”接口成功");
           if(res.data.resultCode==200){
             this.$message({
              message: '删除成功',
              type: "success",
            });
          }else{
            this.$message({
              message: res.data.resultMessage,
              type: "error",
          })
          }
          // this.getAllTask();
          this.getDirectTask();
          this.getDirectTaskTemp();
        })
        .catch((err) => {
          console.log(err);
          console.log("delete:”/atask/{id}”接口失败");
        });
    },

    // 调用接口得到所有的(直播)任务++++++++++++++++++++++++++++++++++++++++++++++++
    getDirectTask() {
      request({
        method: "get",
        url: "/atask/type/1",
      })
        .then((res) => {
          this.directTasks = res.data.data;
          console.log(res.data.data,8868);
          // console.log("get:”/atask/type/类型”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask/type/类型”接口失败");
        });
    },
    // 调用接口得到所有的(临时直播)任务++++++++++++++++++++++++++++++++++++++++++++++++
    getDirectTaskTemp() {
      request({
        method: "get",
        url: "/atask/type/2",
      })
        .then((res) => {
          this.directTasksTemp = res.data.data;
          // console.log(res.data.data);
          // console.log("get:”/atask/type/类型”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask/type/类型”接口失败");
        });
    },
  },

  // 关闭websoket--------------------------------------------------------------------
  destroyed() {
    closewebsocket();
  },
};
</script>

<style scoped>
.el-header {
  /* background-color: #b3c0d1;
  color: #333; */
   background-color: #333555;
  color: #fff;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  height: 30px !important;
}
.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 160px;
  margin: 0px !important;
  padding: 0px;
  height: 290px;
}

.el-row {
  line-height: 180px;
  width: 100%;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  /* background: #d3dce6; */
  background: rgb(233 238 243);
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.a1 {
  float: left;
}
.a2 {
  float: right;
}
.a3 {
  background-color: brown;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
</style>
