<template>
  <div id="app">
    <urgent></urgent>
    <div class="a1">
      <span class="a2">
        <router-link to="/system" replace>
          <el-button size="mini" icon="el-icon-back" round>返回</el-button>
        </router-link>
      </span>
      <span class="a2">
        <el-button size="mini" icon="el-icon-plus" @click="addLine()" round
          >添加</el-button
        >
      </span>
      <span class="a2">
        <router-link to="/port" replace>
          <el-button size="mini" round>串口透传</el-button>
        </router-link>
      </span>
      <span class="a2">
        <router-link to="/tf" replace>
          <el-button size="mini" round>文件下发</el-button>
        </router-link>
      </span>
    </div>
    <div>
      <template>
        <el-table
          :data="task"
          style="width: 100%; line-height: 10px"
          height="78vh"
          border
        >

          <el-table-column label="任务名称" show-overflow-tooltip align="center" prop="name">
          </el-table-column>
          <el-table-column label="触发主机" align="center" prop="iotIp">
          </el-table-column>
          <el-table-column label="通道" align="center"  prop="iotChannel">
          </el-table-column>
          <!-- <el-table-column label="IO开关" align="center">
            <template slot-scope="scope">
              编辑
              <el-button
                size="mini"
                icon="el-icon-video-pause"
                @click="handleEdit(scope.row)"
                type="primary"
              >
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-video-play"
                @click="handleDelete(scope.$index, scope.row.regionId)"
              >
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column
            label="分区"
            align="center"
            :formatter="formatRegions"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="歌曲"
            align="center"
            :formatter="formatSongs"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="物联弹窗"
            align="center"
            prop="iotHint"
            show-overflow-tooltip
          >
          </el-table-column>
          <!-- <el-table-column label="试播" align="center">
            <template slot-scope="scope">
              编辑
              <el-button
                size="mini"
                icon="el-icon-video-pause"
                @click="handleEdit(scope.row)"
                type="primary"
              >
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-video-play"
                @click="handleDelete(scope.$index, scope.row.regionId)"
              >
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column label="音量" align="center" prop="musicVol">
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- 编辑 -->
              <el-button
                size="mini"
                icon="el-icon-edit"
                @click="handleEdit(scope.row)"
                type="primary"
              >
              </el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index,scope.row)"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-dialog
        v-dialogDrag
        :title="add==true?'添加页面':'编辑页面'"
        :visible.sync="is_show"
        center
        :append-to-body="true"
        :lock-scroll="false"
        width="600px"
        :destroy-on-close="true"
        style="margin-top: -5vh"
        :close-on-click-modal="false"
      >
      <template>
  <div>
      <div>
      <div>
        <div style="margin-left: 10px;" class="z1">
        <span class="z2">任务名称：</span>
        <el-input
        class="z3"
        style="width: 70%;height: 20px"
          placeholder="请输入任务名称"
          v-model="name"
          clearable
        >
        </el-input>
      </div>
        <div style="margin-left: 10px;" class="z1">
        <span class="z2">触发主机IP：</span>
        <el-input
        class="z3"
        style="width: 70%;height: 20px"
          placeholder="请输入触发主机IP"
          v-model="iotIp"
          clearable
        >
        </el-input>
      </div>
      <div class="z1" style=";margin-left: 10px;">
          <span class="z2">选择通道：</span>
          <el-select
              v-model="value"
              placeholder="请选择通道"
              class="z3"
              style="width: 70%;"
              size="mini"
            >
            <el-option
                v-for="(item, index) in 16"
                :key="index"
                :value="item"
            >
            </el-option>
            </el-select>
        </div>
        <div class="z1" style="margin-left: 10px;">
          <span class="z2">选择音量：</span>
        <el-select
            v-model="vol"
            size="mini"
            placeholder="请选择音量"
            class="z3"
            style="width: 70%;"
            >
            <el-option
                v-for="(item, index) in 100"
                :key="index"
                :value="item"
            >
            </el-option>
        </el-select>
        </div>
        <div class="z1" style="margin-left: 10px;">
          <span class="z2">任务分区：</span>
          <el-select
              v-model="currentSelectZone"
              multiple
              placeholder="请选择分区"
              class="z3"
              style="width: 70%;"
              size="mini"
            >
              <el-option
                v-for="zone in allZones"
                :key="zone.id"
                :label="zone.name"
                :value="zone.id"
              >
              </el-option>
            </el-select>
        </div>
        <div style="margin-left: 10px;" class="z1">
        <span class="z2">触发弹窗：</span>
        <el-input
        class="z3"
        style="width: 70%;height: 20px"
          placeholder="请输入触发弹窗"
          v-model="iotHint"
          clearable
        >
        </el-input>
      </div>
        <div style="text-align: left;margin-left: 8px;margin-top: 8px;">
          <el-transfer
          filterable
          filter-placeholder="请输入搜索的歌曲"
          v-model="selectSongs"
          :data="allSongsData"
          :titles="['可选歌曲', '已选歌曲']"
          target-order="push"
        >
        </el-transfer>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button class="a6" @click="cancelButton" size="mini" round>
          取消
        </el-button>
      <el-button
          class="a6"
          @click="saveAddTask"
          size="mini"
          type="primary"
          round
        >
          保存
        </el-button>
    </div>
  </div>
</template>
      </el-dialog>
  </div>
</template>
  <script type="text/javascript">
import { request } from "../../network/request";

export default {
  name: "app",
  data() {
    return {
      iotHint:'',
      is_show: false,
      add:"",
      task:[],
      value:'',
      vol:'',
      iotIp:"",
      name:"",
      currentSelectZone:[],
      allZones:[],
      selectSongs:[],
      allSongsData: [],
    };
  },
  mounted() {
    this.getPlanTaskTemp()
    this.getATimeTasks()
    this.getAllZones()
    this.getAllSongs()
  },
  methods: {
    getPlanTaskTemp() {
      request({
        method: "get",
        url: "/atask/type/9",
      })
        .then((res) => {
        //   this.panlTasksTemp = res.data.data;
          console.log(res.data.data,679);
          // console.log("get:”/atask/type/类型”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/atask/type/类型”接口失败");
        });
    },
    getATimeTasks() {
      request({
        method: "get",
        url: "/atimetask/type/9"
      })
        .then((res) => {
          // console.log(res.data.data.timetasks);
          this.task = res.data.data;
          console.log(this.task,8877);
        })
        .catch((err) => {
          console.log(err);
          console.log("get:“/atask/{id}”接口失败");
        });
    },
    addLine(){
        this.is_show=true
        this.add=true
        this.iotIp=''
        this.value=''
        this.vol=''
        this.name=''
        this.iotHint=''
        this.currentSelectZone=[]
        this.selectSongs=[]
      
    },
    cancelButton(){
      this.is_show=false
    },
    handleEdit(row){
      this.is_show=true
      this.add=false
      this.currentTask=row
      this.iotIp=row.iotIp
      this.value=row.iotChannel
      this.vol=row.musicVol
      this.name=row.name
      this.iotHint=row.iotHint
      this.currentSelectZone = this.zoneIdArray();
      this.selectSongs = this.songIdArray();
      this.id=row.id
      this.taskid=row.taskID
      console.log(row)
    },
    handleDelete(index,row){
      console.log(index,row)
      this.$confirm('是否确认删除该任务？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
      request({
        method: "delete",
        url: "/atimetask/" + row.id,
        // data: "", //+++++++++++++++++++++++++++++++++++++++++++++++++++++
        // data: rSpeakers,
      })
        .then((res) => {
          console.log(res, 2222222222222);
          // 删除一行数据
          if (res.data.resultCode == 200) {
            this.task.splice(index, 1);
            this.$message.success("数据删除成功");
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err,9998);
        });
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消删除'
          });          
        });
        console.log(row)
    },
    zoneIdArray() {
      let d = [];
      this.currentTask.regions.forEach((region) => {
        d.push(region.id);
      });
      return d;
    },
    songIdArray() {
      let d = [];
      // this.currentTask.timetasks[0].songs.forEach((song) => {
      //   d.push(song.id);
      // });
      for (let i = 0; i < this.currentTask.songs.length; i++) {
        d.push(this.currentTask.songs[i].id);
      }
      console.log(d,66998877)
      return d.map((v) => v.toString());
    },
    formatSongs(row) {
      let rowSongs = (row && row.songs) || [];
      let arr = [];
      rowSongs.forEach((item) => {
        // console.log(item);
        arr.push(item.name);
      });
      return arr.join(",");
    },
    formatRegions(row) {
      // console.log(row);
      let rowRegions = (row && row.regions) || [];
      let arr = [];
      rowRegions.forEach((item) => {
        arr.push(item.name);
      });
      return arr.join(",");
    },
    getZoneObj() {
      let data = [];
      for (let i = 0; i < this.allZones.length; i++) {
        for (let j = 0; j < this.currentSelectZone.length; j++) {
          if (this.allZones[i].id == this.currentSelectZone[j]) {
            data.push(this.allZones[i]);
          }
        }
      }
      return data;
    },
    getSongObj() {
      let data = [];
      for (let i = 0; i < this.selectSongs.length; i++) {
        for (let j = 0; j < this.allSongs.length; j++) {
          if (this.allSongs[j].id == this.selectSongs[i]) {
            this.allSongs[j].index = i + 1;
            data.push(this.allSongs[j]);
          }
        }
      }
      return data;
    },
    saveAddTask(){
      if (this.add == true) {
        let songObj = this.getSongObj();
        var list={
        "name":this.name,
        "iotIp":this.iotIp,
        "iotChannel":this.value,
        "musicVol":this.vol,
        "songs":songObj,
        "iotHint":this.iotHint,
        "regions":this.getZoneObj()
      }
        if(this.name!=''&&this.iotIp!=''&&this.value!=''&&this.vol!=''&&this.selectSongs.length!=0&&this.allZones.length!=0){
          request({
        method: "put",
        url: "/atimetask/updatet",
        data: list
      })
        .then((res) => {
          console.log("添加",res);
          if (res.data.resultCode == 200) {
            this.$message.success("数据添加成功");
            this.task.push(res.data.data);
            this.is_show = false;
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
        console.log(list)
        }else{
          this.$message.warning("还有选项未选择，请继续选择！");
        }
        
      }
      if(this.add==false){
        let songObj = this.getSongObj();
        var list={
        "taskID":this.taskid,
        "id":this.id,
        "name":this.name,
        "iotIp":this.iotIp,
        "iotChannel":this.value,
        "musicVol":this.vol,
        "songs":songObj,
        "iotHint":this.iotHint,
        "regions":this.getZoneObj()
      }
      if(this.name!=''&&this.iotIp!=''&&this.value!=''&&this.vol!=''&&this.selectSongs.length!=0&&this.allZones.length!=0){
      request({
        method: "put",
        url: "/atimetask/updatet",
        data: JSON.stringify(list)
      })
        .then((res) => {
          console.log("修改",res);
          if (res.data.resultCode == 200) {
            this.$message.success("数据修改成功");
            this.getATimeTasks()
            this.is_show = false;
          } else {
            this.$message.error(res.data.resultMessage);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
        console.log(list)
      }else{
          this.$message.warning("还有选项未选择，请继续选择！");
        }
      }
    },
    getAllZones() {
      request({
        method: "get",
        url: "/aregion",
      })
        .then((res) => {
          this.allZones = res.data.data;
          console.log("串口透传全部分区",res);
          // console.log(res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
    getAllSongs() {
      request({
        method: "get",
        url: "/asong",
      })
        .then((res) => {
          this.allSongs = res.data.data;
          this.allSongsData=[]
          const Songs = this.allSongs;
          Songs.forEach((allSongName) => {
          this.allSongsData.push({
          label: allSongName.name,
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: allSongName.id.toString(),
          // sindex: index,
        });
      });
          console.log("串口透传全部歌曲",res.data.data);
          // console.log("get:”/aregion”接口成功");
        })
        .catch((err) => {
          console.log(err);
          console.log("get:”/aregion”接口失败");
        });
    },
  },
};
</script>
  <style scoped>
body {
  text-align: center;
}
table {
  margin: auto;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
::v-deep .el-dialog__body {
  padding-bottom: 10px;
}
.a1 {
  line-height: 30px;
  width: 400px;
  margin-bottom: 10px;
  float: left;
}
.a2 {
  margin-right: 10px;
  float: left;
}
.a3 {
  text-align: center;
  width: 100%;
  /* background-color: peachpuff; */
  /* line-height: 80px; */
  margin-bottom: 20px;
}
.a4 {
  width: 300px;
}
.a5 {
  margin: 20px;
  /* background-color: rgb(164, 231, 226); */
}
::v-deep .el-dialog__header {
  padding: 20px 20px 0px;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0px 20px;
}
::v-deep .el-dialog__body {
  height: 590px;
}
/* ::v-deep .el-dialog__wrapper{
    margin-top: 8vh !important;
} */
.tabs {
  /* background-color: rgb(141, 228, 250); */
  width: 70px;
  /* line-height:30px; */
  margin-left: 0px !important;
  border: 0px;
}
.tabs:hover {
  background-color: #ffffff;
  color: #606266;
}
.tabs:focus {
  background-color: #ffffff;
}
.bodys {
  /* background-color: rgb(141, 228, 250); */
  width: 480px;
  line-height: 30px;
  margin: auto;
  margin-top: 30px;
}
.z1 {
  display: flex;
  line-height: 45px;
  margin-left: 40px;
  /* margin-top: 10px; */
}
.z2 {
  /* flex: 1;
    text-align-last: justify; */
  width: 100px;
  text-align: right;
  margin-right: 20px;
}
.z3 {
  width: 50%;
}
.btn {
  position: absolute;
  right: 50px;
  bottom: 20px;
}
/* ::v-deep .el-select__tags {
  top: 28%;
} */
.el-transfer {
  font-size: 14px;
  height: 200px;
  width: 590px;
  /* 搜索框的位置 */
  line-height: 0px;
  margin: auto;
}
::v-deep .el-transfer__buttons {
  padding: 0 10px;
}
::v-deep .el-transfer {
  height: 250px !important;
}
.el-dropdown {
  vertical-align: top;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
::v-deep .el-input__inner{
    height: 28px;
}
::v-deep .el-transfer-panel__item.el-checkbox .el-checkbox__label{
  overflow:visible
}
.el-transfer >>> .el-transfer-panel__filter {
  margin: 5px;
}
/* 穿梭框中的搜索框高度 */
.el-transfer >>> .el-transfer-panel__filter .el-input__inner {
  height: 24px;
}
/* 穿梭框中的头部 */
.el-transfer >>> .el-transfer-panel .el-transfer-panel__header {
  height: 30px;
  line-height: 30px;
}
/* 穿梭框中的可选内容高度 */
/* .el-transfer >>> .el-transfer-panel__list.is-filterable {
  height: 130px;
} */
</style>