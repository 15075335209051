// 添加直播任务界面（弹框界面）
<template>
  <div id="app">
    <div class="a1">
      <!-- 任务名称 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务名称：" :label-width="labelWidth">
            <el-input
              id="name"
              v-model="taskName"
              size="mini"
              @blur="taskNameBlur"
              maxlength="9"
              show-word-limit
              placeholder="任务名称由“中文、英文、数字”组成"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 任务类型 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务类型：" :label-width="labelWidth">
            <el-select
              v-model="currentSelectTaskType"
              placeholder="请选择"
              class="a2"
              size="mini"
            >
              <el-option
                v-for="taskType in taskTypes"
                :key="taskType.id"
                :label="taskType.label"
                :value="taskType.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 结束日期 -->
      <!-- <el-row class="a4">
        <el-form>
          <el-form-item label="结束日期：" :label-width="labelWidth">
            <el-date-picker
              v-model="endDate"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              size="mini"
              class="a2"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </el-row> -->

      <!-- 任务分区 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务分区：" :label-width="labelWidth">
            <el-select
              v-model="currentSelectZone"
              multiple
              placeholder="请选择"
              class="a2"
              size="mini"
            >
              <el-option
                v-for="zone in allZones"
                :key="zone.id"
                :label="zone.name"
                :value="zone.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 任务音源 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务音源：" :label-width="labelWidth">
           <el-select
            v-model="value"
            class="a2"
            size="mini"
            placeholder="请选择"
             @change="handleChange"
             style="width: 80%;"
          >
            <el-option v-for="(item,index) in tableData"
            :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button @click="innerVisible = true" :disabled="btnslow" style="margin-left: 20px;width: 15%;" size="mini">选择</el-button>
            <!-- <el-select
              v-model="taskSource"
              multiple
              placeholder="请选择"
              class="a2"
              size="mini"
              @change="handleChange"
            >
              <el-option
                v-for="zone in collectName"
                :key="zone.id"
                :label="zone.name"
                :value="zone.id"
              >
              </el-option>
            </el-select> -->
          <!-- :props="props" -->
            <!-- <el-cascader
              class="a2"
              v-model="taskSource"
              :options="soundSourceOptions"
              @change="handleChange"
              size="mini"
            ></el-cascader> -->
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 任务权限 -->
      <el-row class="a4">
        <el-form>
          <el-form-item label="任务权限：" :label-width="labelWidth">
           <el-rate
              class="a3"
              v-model="priority"
              :max="3"
              show-text
              :texts="text"
            ></el-rate>
            <!-- <el-radio-group v-model="priority" class="a3">
              <el-radio :label="1">一颗星</el-radio>
              <el-radio :label="3">二颗星</el-radio>
              <el-radio :label="6">三颗星</el-radio>
            </el-radio-group> -->
          </el-form-item>
        </el-form>
      </el-row>

      <!-- 选择歌曲 -->
      <!-- target-order="push" -->
      <!-- :render-content="renderFunc" -->
      <el-row class="a7" style="margin:auto;">
        <el-transfer
          filterable
          filter-placeholder="请输入搜索的歌曲"
          v-model="selectSongs"
          :data="allSongsData"
          :titles="['可选歌曲', '已选歌曲']"
          @change="changeSelectSongs"
          target-order="push"
        >
        </el-transfer>
      </el-row>

      <!-- 按钮 -->
      <el-row class="a5">
        <el-button
          class="a6"
          @click="saveAddTask"
          size="mini"
          type="primary"
          round
        >
          保存
        </el-button>
        <el-button class="a6" @click="cancelButton" size="mini" round>
          取消
        </el-button>
      </el-row>
    </div>
    <el-dialog
    v-dialogDrag
      width="18%"
      title="音源选择"
      :visible.sync="innerVisible"
      style="margin-top: 28vh"
      :close-on-click-modal="false"
      append-to-body>
      <!-- <div>音源选择</div> -->
      <div style="margin-top: -25px;">
        <el-row style="margin-bottom: 8px;">
          <el-radio v-model="radio" label="13002">Mic 1~2</el-radio>
          <el-radio v-model="radio" label="13000">Line 1~2</el-radio>
        </el-row>
        <el-row style="margin-bottom: 12px;">
          <el-radio v-model="radio" label="13003">Mic 3~4</el-radio>
          <el-radio v-model="radio" label="13001">Line 3~4</el-radio>
        </el-row>
        <el-row style="margin-bottom: 12px;">
          <el-radio v-model="radio" label="13006">音源混合</el-radio>
        </el-row>
        <el-row>
          <el-checkbox-group @change="check" v-model="checkList" :disabled="radio!=13006">
            <el-row style="margin-bottom: 8px;">
              <el-checkbox label="2">Mic 1~2</el-checkbox>
            <el-checkbox label="0">Line 1~2</el-checkbox>
            </el-row>
            <el-row style="margin-bottom: 8px;">
              <el-checkbox label="3">Mic 3~4</el-checkbox>
            <el-checkbox label="1">Line 3~4</el-checkbox>
            </el-row>
            <el-row>
              <el-checkbox label="4">PC</el-checkbox>
            </el-row>
            </el-checkbox-group>
        </el-row>
        <el-row>
          <el-button
          class="a6"
          size="mini"
          type="primary"
          @click="innerVisible=false"
        >
          确认
        </el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  data() {
    const getAllSongs = () => {
      const data = [];
      const Songs = this.allSongs;
      // Songs.forEach((allSongName) => {
      //   data.push({
      //     label: allSongName.name,
      //     //axios实例已经解决精度损失，这里还要转换成字符串
      //     key: allSongName.id.toString(),
      //     // other: allSongName.index,
      //   });
      // });
      Songs.forEach((allSongName) => {
        data.push({
          label: allSongName.name,
          //axios实例已经解决精度损失，这里还要转换成字符串
          key: allSongName.id.toString(),
          // sindex: index,
        });
      });
      return data;
    };

    return {
      renderFunc(h, option) {
        return (
          <span>
            {option.key} - {option.label}
          </span>
        );
      },
      // 当前编辑的任务
      currentTask: {},
      // 设置级联选择器--
      // props: {
      //   // 次级菜单的展开方式
      //   checkStrictly: true,
      //   // 是否严格的遵守父子节点不互相关联
      //   expandTrigger: "hover",
      //   // 多选
      //   // multiple: false,
      // },
      // 标签的宽度--
      labelWidth: "120px",
      innerVisible: false,
      btnslow:true,
      checkList:[],
      radio: '',
      text: ["一颗星", "二颗星", "三颗星"],
      //任务音源选择--
      soundSourceOptions: [
        {
          value: "",
          label: "",
        },
      ],
      // 当前选择的分区
      currentSelectZone: [],
      // 任务音源
      taskSource: "",
      // 任务权限（默认选中一颗星）
      priority: 0,
      // 所有歌曲
      allSongsData: getAllSongs(),
      // 选中的歌曲
      selectSongs: [],

      // 任务音源选项值
      // soundSourceValue: [],

      // 当前选择的任务类型
      currentSelectTaskType: "1",
      // 可选任务类型
      taskTypes: [
        { value: "1", label: "直播" },
        // { value: "2", label: "临时直播" },
      ],

      // 任务名称
      taskName: "",
      // 结束日期
      endDate: "",
      value:'',
      // 初始值
      initialNewTask: {
        // 任务ID
        id: "",
        // 任务名称
        name: "",
        // 任务音源
        taskSource: ["1"],
        // 混合音源
        sourceMix: "",
        // 权限
        priority: 1,
        // 子任务
        timetasks: [
          {
            // 权限
            priority: 0,
            // 分区
            regions: [],
            // 歌曲
            songs: [],
            collectID:'',
            collectPort:'',
            collectCommand:''
          },
        ],
        // 任务类型
        type: 1,
      },
    };
  },
  props: ["createNewTask", "allZones", "allSongs","tableData"], //当前任务,所有可选的分区,所有可选的歌曲--
  components: {},
  watch: {},
  created() {},
  mounted() {
    this.initial();
    // console.log(this.currentTask,123456789,this.tableData,this.value)
    console.log(this.allSongsData,12345678,this.allSongs,this.currentTask)
  },
  methods: {
    // 初始化----------------------------------------------------------------------------------------
    initial() {
      // 将数据保存到另一个变量
      this.currentTask = this.createNewTask;

      if (this.createNewTask == undefined) {
        this.currentTask = this.initialNewTask;
      } else {
        this.currentTask = this.createNewTask;
      }

      // 任务名称
      this.taskName = this.currentTask.name;
      // 任务类型
      this.currentSelectTaskType = this.currentTask.type + "";
      if(this.currentTask.timetasks[0].collectType==4){
        this.btnslow=false
      }
      this.radio=this.currentTask.timetasks[0].collectPort+""
      // let b=this.currentTask.timetasks[0].collectCommand.slice(2)
      this.checkList=this.currentTask.timetasks[0].collectCommand.split(",")
      // 结束日期
      this.endDate = this.currentTask.endDate;
      // 分区
      this.currentSelectZone = this.zoneIdArray();
      // 任务音源
      // this.taskSource = this.currentTask.taskSource;
      //  this.collectID=this.clooect()
      // this.collectID
      this.value=this.currentTask.timetasks[0].collectID
      // 任务权限
      // this.priority = this.currentTask.priority;
      if (this.currentTask.priority == 1) {
        this.priority = 1;
      } else if (this.currentTask.priority == 3) {
        this.priority = 2;
      } else if (this.currentTask.priority == 5) {
        this.priority = 3;
      } else {
        this.$message({
          message: "获取不到权限",
          type: "warning",
        });
      }
      // 已选的歌曲
      this.selectSongs = this.songIdArray();
    },
    // clooect(){
    //    let d = [];
    //   // this.currentTask.timetasks[0].songs.forEach((song) => {
    //   //   d.push(song.id);
    //   // });
    //   for (let i = 0; i < this.currentTask.timetasks[0].collectID.length; i++) {
    //     d.push(this.currentTask.timetasks[0].collectID[i].id);
    //   }
    //   console.log(d,77777777777)
    //   return d.map((v) => v.toString());
    // },
    // 获得数据库已选的歌曲ID数组-----------------------------------------------------------------
    songIdArray() {
      let d = [];
      // this.currentTask.timetasks[0].songs.forEach((song) => {
      //   d.push(song.id);
      // });
      for (let i = 0; i < this.currentTask.timetasks[0].songs.length; i++) {
        d.push(this.currentTask.timetasks[0].songs[i].id);
      }
      console.log(d,66998877)
      return d.map((v) => v.toString());
    },

    // 获得数据库已选的分区ID数组---------------------------------------------------------------------
    zoneIdArray() {
      let d = [];
      this.currentTask.timetasks[0].regions.forEach((region) => {
        d.push(region.id);
      });
      return d;
    },
    // 选择任务音源
    handleChange(value) {
      // var b=[]
      this.state=true
      this.btnslow=true
      for(var i=0;i<this.tableData.length;i++){
        if(this.tableData[i].id==value){
          this.port=this.tableData[i].port
          this.ctype=this.tableData[i].cType
        }
        // return b
      }
      // console.log(value);
      console.log("----",this.port,this.ctype)
      if(this.port==-1&&this.ctype==4){
        // this.innerVisible = true
        this.btnslow=false
      }
    },
    check(value){
      console.log(value)
    },
    // 已经选中的歌曲------------------------------------------------------------------------------------
    changeSelectSongs() {
      // console.log(this.selectSongs);
    },

    // 判断任务名称的合法性
    taskNameBlur() {
      let r = /^[\u4E00-\u9FA5A-Za-z0-9_]+$/;
      if (!r.test(document.getElementById("name").value)) {
        this.$message({
          message: '用户名称由"中文、英文、数字"组成',
          type: "warning",
        });
        // 清空
        // document.getElementById("name").value = "";
        this.taskName = "";
      }
    },

    getTaskVal() {
      // 获得任务权限newTask
      // 获得任务名称
      this.currentTask.name = this.taskName;

      // 获得任务结束日期
      this.currentTask.endDate = this.endDate;

      // 获得任务分区
      let zoneObj = this.getZoneObj();
      this.currentTask.timetasks[0].regions = zoneObj;
      let musicVol=''
      this.currentTask.timetasks[0].musicVol=musicVol
      let miVol=''
      this.currentTask.timetasks[0].miVol=miVol
      let taskVol=''
      this.currentTask.timetasks[0].taskVol=taskVol
      let repeatMode=''
      this.currentTask.timetasks[0].repeatMode=repeatMode
      // 获得任务音源的值(判断是是否混合音源)
      // if (this.taskSource[0] == 6) {
      //   this.currentTask.taskSource = this.taskSource[0];
      //   this.currentTask.sourceMix = this.taskSource[1];
      // } else {
      //   this.currentTask.taskSource = this.taskSource[0];
      // }
      // let collectObj = this.getCollectObj()
      this.currentTask.timetasks[0].collectID=this.value 
      // 获得任务权限
      // this.currentTask.priority = this.priority;
      if (this.priority == 1) {
        this.currentTask.priority = 1;
      } else if (this.priority == 2) {
        this.currentTask.priority = 3;
      } else if (this.priority == 3) {
        this.currentTask.priority = 5;
      } else {
        this.$message({
          message: "获取不到权限，拍照联系前端开发查明原因！",
          type: "warning",
        });
      }

      // 获得选中的歌曲
      let songObj = this.getSongObj();
      this.currentTask.timetasks[0].songs = songObj;
      this.currentTask.songs=this.currentTask.timetasks[0].songs
      // console.log(this.currentTask.timetasks[0].songs,99686,songObj,this.currentTask.songs)
      // 获得任务类型
      this.currentTask.type = this.currentSelectTaskType;
        this.currentTask.timetasks[0].collectPort=this.radio
         if(this.radio=='13006'){
          this.checkList[0]=6
          this.currentTask.timetasks[0].collectCommand=this.checkList.join(",")
         }
         if(this.port!='-1'&&this.ctype!='4'&&this.state==true){
        this.currentTask.timetasks[0].collectPort=''
          this.currentTask.timetasks[0].collectCommand=''
          this.radio=''
      }
    },
    //保存按钮--------------------------------------------------------------------------------------------
    saveAddTask() {
      // 新建的任务数据
// this.currentTask.songs=this.currentTask.timetasks[0].songs
      // 在保存之前首先判断是否还有未选项
      if (
        this.taskName != "" &&
        this.taskName.length != 0 &&
        this.currentSelectZone.length != 0 &&
        this.selectSongs.length != 0
      ) {
        this.getTaskVal();
        this.$emit("newTask", this.currentTask);
        this.$emit("closeDialog", false);
        console.log(this.currentTask,88884444444444444444,this.checkList,
        this.radio);
      } else {
        this.$message({
          message: "还有选项未选，请继续选择!",
          type: "warning",
        });
      }
    },

    //取消按钮------------------------------------------------------------------------
    cancelButton() {
      this.$emit("closeDialog", false);
    },
    // getCollectObj(){
    //    let data = [];
    //   for (let i = 0; i < this.allZones.length; i++) {
    //     for (let j = 0; j < this.currentSelectZone.length; j++) {
    //       if (this.allZones[i].id == this.currentSelectZone[j]) {
    //         data.push(this.allZones[i]);
    //       }
    //     }
    //   }
    //   return data;
    // },
    //通过ID的值找到iD对应的对象，即分区集合------------------------------------------
    getZoneObj() {
      let data = [];
      for (let i = 0; i < this.allZones.length; i++) {
        for (let j = 0; j < this.currentSelectZone.length; j++) {
          if (this.allZones[i].id == this.currentSelectZone[j]) {
            data.push(this.allZones[i]);
          }
        }
      }
      return data;
    },

    //通过ID的值找到iD对应的对象，即歌曲集合(注意selectSongs数组的顺序不能乱)------------------------------------------
    getSongObj() {
      let data = [];
      for (let i = 0; i < this.selectSongs.length; i++) {
        for (let j = 0; j < this.allSongs.length; j++) {
          if (this.allSongs[j].id == this.selectSongs[i]) {
            this.allSongs[j].index = i + 1;
            data.push(this.allSongs[j]);
          }
        }
      }
      return data;
    },
  },
};
</script>

<style  scoped>
.a1 {
  /* width: 590px;
  height: 700px; */
  width: 90%;
  height: 100%;
}
.a2 {
  width: 100%;
  /* background-color: paleturquoise; */
}
.a3 {
  float: left;
  margin-top: 12px;
  /* background-color: palevioletred; */
}
.a4 {
  /* background-color: orangered; */
  height: 40px;
}
.a5 {
  line-height: 40px;
  margin: 10px 20px 0px 0px;
}
.a6 {
  float: right;
  margin: 0px 10px;
  line-height: 8px;
  font-size: 10px;
}
.a7 {
  /* 穿梭框内容左对齐 */
  text-align: left;
  margin-left: 40px;
}

.el-transfer {
  font-size: 14px;
  height: 200px;
  width: 590px;
  /* 搜索框的位置 */
  line-height: 0px;
  margin: auto;
}

/* 穿梭框中的搜索框边距 */
.el-transfer >>> .el-transfer-panel__filter {
  margin: 5px;
}
/* 穿梭框中的搜索框高度 */
.el-transfer >>> .el-transfer-panel__filter .el-input__inner {
  height: 24px;
}
/* 穿梭框中的头部 */
.el-transfer >>> .el-transfer-panel .el-transfer-panel__header {
  height: 30px;
  line-height: 30px;
}
/* 穿梭框中的可选内容高度 */
.el-transfer >>> .el-transfer-panel__list.is-filterable {
  height: 130px;
}

/* .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
} */
::v-deep .el-dialog__body{
  height: 150px;
}
</style>
